import React from 'react'

export default function flagCs(props) {
  return (
    <svg
      version="1.0"
      width="900"
      height="600"
      viewBox="0 0 900 600"
      {...props}
    >
      <rect
        width="900"
        height="600"
        fill="#d7141a"
      />
      <rect
        width="900"
        height="300"
        fill="#fff"
      />
      <path
        d="M 450,300 0,0 V 600 z"
        fill="#11457e"
      />
    </svg>
  )
}
