import { BASE_PATH, LANG } from '../App/constants'

export const REGISTER_STATE_KEY = 'register'
export const USERNAME_GENERATOR_REGISTER_STATE_KEY = `${REGISTER_STATE_KEY}_USERNAME_GENERATOR`
export const FORM_REGISTER_NAME = 'formRegister'
export const SEND_REGISTER_FORM_PATH = `${BASE_PATH}/${LANG}/register/user/CZ`

export const LOAD = 'Casino/Register/LOAD'
export const LOAD_SUCCESS = 'ino/Register/LOAD_SUCCESS'
export const LOAD_ERROR = 'Casino/Register/LOAD_ERROR'

export const FINISH_WITHOUT_CONFIRMING = 'Casino/Register/FINISH_WITHOUT_CONFIRMING'
export const RELOAD_REGISTER_FORM = 'Casino/Register/RELOAD_REGISTER_FORM'
export const RELOAD_REGISTER_FORM_SUCCESS = 'Casino/Register/RELOAD_REGISTER_FORM_SUCCESS'
export const RELOAD_REGISTER_FORM_ERROR = 'Casino/Register/RELOAD_REGISTER_FORM_ERROR'
export const DEDUPLICATION_IS_NEEDED = 'Casino/Register/DEDUPLICATION_IS_NEEDED'

export const BIRTH_DATE_FORMAT_MASK = 'yyyy-M-d'
