import React from 'react'

import { footerLogo } from '../../utils/style-utils'
import UrlLink from '../UrlLink'
import { LogoImg } from './styles/LogoImg'

export function Logo() {
  return (
    <UrlLink link="/">
      <LogoImg
        src={footerLogo}
        alt=""
      />
    </UrlLink>
  )
}
