import React from 'react'

import { DarkGlass } from 'components/DarkGlass'
import { useSelectUserType } from 'containers/MainLayout/selectors'

import { BottomAppBarBox } from './styles'
import { bottomBarComponentMap } from './utils'

export function BottomAppBar() {
  const userType = useSelectUserType()
  const BottomBarComponent = bottomBarComponentMap[userType] || bottomBarComponentMap.unknown

  return (
    <BottomAppBarBox>
      <BottomBarComponent />

      <DarkGlass brightness={0.2} />
    </BottomAppBarBox>
  )
}
