import React, { lazy, Suspense } from 'react'

import InlineCenterLoader from '../../../components/Loader/InlineCenterLoader'

const Component = lazy(() => import(/* webpackChunkName: "Jackpots" */ './index'))

export default function JackpotsLoadable() {
  return (
    <Suspense fallback={<InlineCenterLoader />}>
      <Component />
    </Suspense>
  )
}
