import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BASE_PATH, PLAYER_PROFILE_ADDRESS } from '../../containers/App/constants'
import { LOGOUT_ADDRESS } from '../../containers/LoginPage/constants'
import { bh, media, boxContentColor, boxContentBg, transition, fontWeightBold } from '../../utils/style-utils'
import Button from '../Btn'
import UrlLink from '../UrlLink'
import Balances from './Balances'
import Infos from './Infos'

const Wrapper = styled.div`
  width: 300px;
  color: ${boxContentColor};
  background: ${boxContentBg};
  position: absolute;
  ${transition('opacity', 0.2)};
  z-index: 2;
  right: ${bh * 1.5}px;
  padding: ${bh}px;
  top: ${bh * 3.2}px;

  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};

  ${media.md`
    right: ${bh * 1.2}px;
    top: ${bh * 3.6}px;
  `}

  ${media.xs`
    width: 88vw;
  `}

  &:before {
    content: '';
    position: absolute;
    right: ${bh}px;
    top: -${bh / 2}px;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 ${bh / 2}px ${bh / 2}px ${bh / 2}px;
    border-color: transparent transparent ${boxContentBg} transparent;

    ${media.md`
      right: ${bh - 2}px;
    `}
  }
`

const Name = styled.div`
  font-size: ${bh * 1.2}px;
  font-weight: ${fontWeightBold};
  margin-bottom: ${bh / 2}px;
`

const Username = styled.div`
  font-size: ${bh * 0.825}px;
  margin-bottom: ${bh / 2}px;
  word-wrap: break-word;
`

const ButtonsWrapper = styled.div`
  padding-top: ${bh / 2}px;
  display: flex;
  justify-content: space-between;
`

function UserDropdown({ open, user, toggleUserMenu }) {
  return (
    <Wrapper
      className="user-dropdown-menu"
      open={open}
      onClick={(e) => e.stopPropagation()}
    >
      <Name>{user.get('fullname')}</Name>
      <Username>{user.get('username')}</Username>

      {user.get('isStaff') ? (
        <AdminDropdownContent />
      ) : (
        <PlayerDropdownContent
          user={user}
          toggleUserMenu={toggleUserMenu}
        />
      )}
    </Wrapper>
  )
}

UserDropdown.propTypes = {
  open: PropTypes.bool,
  toggleUserMenu: PropTypes.func,
  user: ImmutablePropTypes.map,
}

function AdminDropdownContent() {
  return (
    <ButtonsWrapper>
      <Button
        link
        href={BASE_PATH + LOGOUT_ADDRESS}
        md
      >
        {Translator.trans('logout')}
      </Button>
    </ButtonsWrapper>
  )
}

function PlayerDropdownContent({ user, toggleUserMenu }) {
  return (
    <>
      <Balances userBalances={user.get('balances')} />
      <Infos
        user={user}
        toggleUserMenu={toggleUserMenu}
      />

      <ButtonsWrapper>
        <Button
          md
          to={PLAYER_PROFILE_ADDRESS}
          onClick={toggleUserMenu}
        >
          {Translator.trans('Show profile', {}, 'users')}
        </Button>

        <UrlLink url={BASE_PATH + LOGOUT_ADDRESS}>
          <Button
            link
            md
          >
            {Translator.trans('logout')}
          </Button>
        </UrlLink>
      </ButtonsWrapper>
    </>
  )
}

PlayerDropdownContent.propTypes = {
  toggleUserMenu: PropTypes.func,
  user: ImmutablePropTypes.map,
}

export default UserDropdown
