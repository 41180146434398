import { GOOGLE_SITE_VERIFICATION } from '../App/constants'
import { GOOGLE_SITE_VERIFICATION_META_NAME } from './constants'

export function initGoogleSiteVerifications(statistical: boolean) {
  if (statistical && GOOGLE_SITE_VERIFICATION) {
    const meta = document.createElement('meta')
    meta.name = GOOGLE_SITE_VERIFICATION_META_NAME
    meta.content = GOOGLE_SITE_VERIFICATION

    document.head.appendChild(meta)

    return () => {
      document.head.removeChild(meta)
    }
  }

  return undefined
}
