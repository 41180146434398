import styled from 'styled-components'

import { bh, transition, cmsContentSidebarActiveColor } from '../../utils/style-utils'

export default styled.span`
  display: ${(props) => (props.hasChildren ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: ${bh / 6}px;
  height: 100%;
  width: ${bh * 3}px;
  ${transition('transform', 0.2)};
  ${(props) => props.isOpen && 'transform: rotate(180deg);'}

  &:after {
    content: '';
    position: relative;
    top: -${bh / 6}px;
    width: ${bh / 2}px;
    height: ${bh / 2}px;
    transform: rotate(135deg);

    border: 1px solid ${cmsContentSidebarActiveColor};
    border-left: none;
    border-bottom: none;
  }
`
