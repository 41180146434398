const cache = {}

function getCached(key) {
  return cache[key]
}
function saveCached(key, value) {
  cache[key] = value
  return value
}

export function getCachedFn(fn) {
  return (...args) => {
    const cacheKey = `${fn.name}--${args.join('-')}`
    const cached = getCached(cacheKey)
    if (cached) {
      return cached
    }

    return saveCached(cacheKey, fn(...args))
  }
}
