import { string, object } from 'yup'

import { categoriesSchema } from './schemas/categoriesSchema'
import { gamesSchema } from './schemas/gamesSchema'

export const gamesWithCategoriesSchema = object()
  .shape({
    defaultCategory: string(),
    mainCategory: string(),
    funGamesCategory: string(),
    categories: categoriesSchema,
    games: gamesSchema,
  })
  .from('fungamesCategory', 'funGamesCategory')
