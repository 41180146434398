import Translator from 'bazinga-translator'
import { fromJS } from 'immutable'
import { SubmissionError } from 'redux-form'

// import { SubmissionError } from 'redux-form/es/immutable'
import compose from './compose'
import { getMaxLengthNormalizer } from './normalizers'
import {
  required,
  number,
  minValue,
  maxValue,
  minLength,
  maxLength,
  regexpTest,
  maxSizeTest,
  mimeTypesTest,
  uploadedTest,
  emailTest,
  errorsTest,
} from './validators'

/**
 * @param {HtmlInputElement} el
 */
export function setFocus(el) {
  if (el && el.focus) {
    el.focus()
  }
}

/**
 * @param {Object} fields Immutable Map Obj
 */
export function getValuesFromFields(fields) {
  return fields.reduce((obj, field) => obj.set(field.get('name'), field.get('value')), fromJS({}))
}

/**
 * @param {object} values
 * @param {object} flat={}
 * @param {string} parentKey=false
 *
 * @return {object} flat object { "key[subkey]" : "value", ... }
 */
export function getFlattenValues(values, flat = {}, parentKey = false) {
  let flatten = flat

  Object.keys(values).forEach((key) => {
    const keyToAppend = parentKey ? `${parentKey}[${key}]` : key
    const value = values[key]
    const isValueObject = value && value.constructor === Object
    if (isValueObject) {
      flatten = getFlattenValues(value, flatten, keyToAppend)
    } else {
      flatten[keyToAppend] = value
    }
  })

  // prevents from sending unchecked checkobxes
  Object.keys(flatten).forEach((key) => {
    if (flatten[key] === false) delete flatten[key]
  })

  return flatten
}

/**
 *
 * @param {Object} formData immutableJS Map object
 * @return {Object} initial value object
 */
export function getInitialValues(formData) {
  if (!formData) {
    return fromJS({})
  }

  const obj = {}

  formData.get('fields').forEach((val) => {
    if (!val) {
      return
    }
    const name = val.get('name')
    const type = val.get('type')
    const expanded = val.get('expanded')
    let value = type === 'checkbox' ? val.get('checked') : val.get('value')

    if (type === 'choice' && value === '' && !expanded) {
      value = val.getIn(['choices', 0, 'value'])
    }

    if ((name.match(/\[/g) || []).length > 1) {
      const parts = name.split('[').map((e) => e.split(']')[0])

      if (!obj[parts[0]]) {
        obj[parts[0]] = {}
      }

      if (!obj[parts[0]][parts[1]]) {
        obj[parts[0]][parts[1]] = {}
      }

      obj[parts[0]][parts[1]][parts[2]] = value
    } else if (name.indexOf('[') > -1) {
      // UNFLATTEN NAMES LIKE message[body] = 'asd' TO {message: {body: 'asd'}}
      const n0 = name.replace(/\[.+]/, '')
      const n1 = name.replace(/.+\[/, '').replace(']', '')

      if (!obj[n0]) {
        obj[n0] = {}
      }

      obj[n0][n1] = value
    } else {
      obj[val.get('name')] = value
    }
  })

  return fromJS(obj)
}

export function getSubmissionError(errors, values) {
  const obj = {}
  if (errors && errors.response && errors.response.status === 413) {
    obj._error = 'uploading_file_is_to_big' // eslint-disable-line no-underscore-dangle
  } else if (!errors) {
    obj._error = Translator.trans('unknown_error', {}, 'errors') // eslint-disable-line no-underscore-dangle
  } else if (typeof errors === 'string') {
    obj._error = errors // eslint-disable-line no-underscore-dangle
  } else {
    fromJS(errors).forEach((val, key) => {
      const name = key
      if (name === '_form') {
        obj._error = val // eslint-disable-line no-underscore-dangle
      } else if (name.indexOf('[') > -1) {
        const parts = name.split('[').map((e) => e.split(']')[0])

        if (!obj[parts[0]]) {
          obj[parts[0]] = {}
        }

        obj[parts[0]][parts[1]] = val

        if (parts.length > 2) {
          obj[parts[0]][parts[1]] = {}

          obj[parts[0]][parts[1]][parts[2]] = val
        }
      } else if (name.indexOf('_') > -1) {
        // UNFLATTEN NAMES LIKE message_body = 'asd' TO {message: {body: 'asd'}}
        // Now the function uses values to properly map names to fields
        const nameEnd = name.split('_').pop()
        const baseName = Object.keys(values)[0]

        Object.keys(values[baseName]).forEach((e) => {
          if (e === nameEnd) {
            if (!obj[baseName]) {
              obj[baseName] = {}
            }
            obj[baseName][e] = val
          }

          // handling of repeated form Fields
          if (values[baseName][e] !== null && typeof values[baseName][e] === 'object') {
            Object.keys(values[baseName][e]).forEach((f) => {
              if (f === nameEnd) {
                if (!obj[baseName]) {
                  obj[baseName] = {}
                }
                if (!obj[baseName][e]) {
                  obj[baseName][e] = {}
                }
                obj[baseName][e][f] = val
              }
            })
          }
        })
      } else {
        obj[val.get('name')] = val
      }
    })
  }

  return new SubmissionError(obj)
}

const validatorCached = {}

export const getValidateFromJQValidator = (validator) => {
  const cachedKey = validator.hashCode()
  if (validatorCached[cachedKey]) {
    return validatorCached[cachedKey]
  }

  const validatorArray = []
  let msgs = validator.get('messages')
  const req = validator.get('required')
  const min = validator.get('min')
  const max = validator.get('max')
  const email = validator.get('email')
  const maxSize = validator.get('max_size')
  const mimeTypes = validator.get('mime_types')
  const uploaded = validator.get('uploaded')
  let maxlength = validator.get('maxlength')
  let minlength = validator.get('minlength')
  const rangelength = validator.get('rangelength')
  const regexp = validator.get('regexp')
  const errors = validator.get('errors')

  if (rangelength !== undefined) {
    minlength = rangelength.get(0)
    maxlength = rangelength.get(1)
    msgs = msgs.set('minlength', msgs.get('rangelength')).set('maxlength', msgs.get('rangelength'))
  }

  if (min !== undefined || max !== undefined) {
    validatorArray.push(number(msgs.get('min') || msgs.get('max')))
  }

  if (req === true && msgs !== undefined) {
    validatorArray.push(required(msgs.get('required')))
  }
  if (errors === true) {
    validatorArray.push(errorsTest())
  }
  if (min !== undefined) {
    validatorArray.push(minValue(min)(msgs.get('min')))
  }
  if (max !== undefined) {
    validatorArray.push(maxValue(max)(msgs.get('max')))
  }
  if (minlength !== undefined) {
    validatorArray.push(minLength(minlength)(msgs.get('minlength')))
  }
  if (maxlength !== undefined) {
    validatorArray.push(maxLength(maxlength)(msgs.get('maxlength')))
  }
  if (regexp !== undefined && email !== true) {
    validatorArray.push(regexpTest(regexp)(msgs.get('regexp')))
  }
  if (mimeTypes !== undefined) {
    validatorArray.push(mimeTypesTest(mimeTypes)(msgs.get('mime_types')))
  }
  if (maxSize !== undefined) {
    validatorArray.push(maxSizeTest(maxSize)(msgs.get('max_size')))
  }
  if (uploaded !== undefined) {
    validatorArray.push(uploadedTest(msgs.get('uploaded')))
  }
  if (email === true) {
    validatorArray.push(emailTest()(msgs.get('email')))
  }

  validatorCached[cachedKey] = validatorArray.length ? validatorArray : undefined
  return validatorCached[cachedKey]
}

export const getNormalizeFromJQValidator = (validator) => {
  const maxlength = validator.get('maxlength')
  const normalizes = []

  if (maxlength) {
    normalizes.push(getMaxLengthNormalizer(maxlength))
  }

  return normalizes.length ? compose(...normalizes) : null
}

/**
 * Validate value by validators array
 * @param {string} value
 * @param {Function[]} validators
 * @returns {boolean|string[]} if valid -> true, else array of string errors
 */
export function isValid(value, validators) {
  const results = validators
    .map((validator) => validator(value)) // validate with add validator function
    .filter((err) => err) // remove undefined (valid) values

  const allValid = results.length === 0

  return allValid || results
}

export function getFileObjFromBlob(file) {
  if (file && file.name && file.size && file.type) {
    return {
      filename: file.name,
      size: file.size,
      uploaded: false,
      mimeType: file.type,
    }
  }

  return false
}
