import { keepPreviousData } from '@tanstack/react-query'

import { getActiveSubscriptions } from '../../../containers/Tournaments/service'
import { userMembershipsListSchema } from '../../../containers/Tournaments/validator/userMembership'
import { useQueryWithValidator } from '../../useQueryWithValidator'
import { CACHE_TIME_ACTIVE_SUBSCRIPTIONS } from './constants'
import { getTournamentsCacheKey } from './utils'

export const ACTIVE_SUBSCRIPTIONS_CACHE_KEY = getTournamentsCacheKey('subscriptions', 'active')

interface Props {
  playerId: string
  enabled?: boolean
}

export function useActiveSubscriptions({ playerId, enabled = true }: Props) {
  return useQueryWithValidator({
    cacheKey: [...ACTIVE_SUBSCRIPTIONS_CACHE_KEY, playerId],
    queryFn: () => getActiveSubscriptions(),
    schema: userMembershipsListSchema,
    options: {
      gcTime: CACHE_TIME_ACTIVE_SUBSCRIPTIONS,
      staleTime: CACHE_TIME_ACTIVE_SUBSCRIPTIONS,
      enabled: !!playerId && enabled,
      placeholderData: keepPreviousData,
    },
  })
}
