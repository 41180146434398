import { Location } from 'history'
import { isArray } from 'lodash'
import { AnyAction, Reducer } from 'redux'
import { RouterState } from 'redux-first-history'

export type QueryValue = string | string[] | undefined
export type Query = Record<string, QueryValue>
interface LocationWithQuery extends Location {
  query?: Query
}

export interface RouterStateWithQuery extends RouterState {
  location: LocationWithQuery
  get: (key: string) => RouterStateWithQuery
}

export function createRouteReducerWithQuery(routerReducer: Reducer<RouterState>) {
  return function routeReducerWithQuery(state: RouterState, action: AnyAction) {
    const newState = routerReducer(state, action) as RouterStateWithQuery
    const search = newState?.location?.search || ''

    if (!search) {
      return newState
    }

    if (newState.location) {
      newState.location.query = getSearchParams(new URLSearchParams(search))
    }

    return newState
  }
}

function getSearchParams(urlSearchParams: URLSearchParams) {
  let searchParams = {} as Record<string, string | string[]>

  urlSearchParams.forEach((value, key) => {
    const arrayValue = searchParams[key]

    if (arrayValue) {
      if (isArray(arrayValue)) {
        searchParams = { ...searchParams, [key]: [...arrayValue, value] }

        return
      }
      searchParams = { ...searchParams, [key]: [arrayValue, value] }

      return
    }

    searchParams = { ...searchParams, [key]: value }
  })

  return searchParams
}
