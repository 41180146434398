import React from 'react'

import { PlayerPopover } from '../../../PlayerPopover'
import { ImportantButtons } from './ImportantButtons'

export function Player() {
  return (
    <>
      <ImportantButtons />
      <PlayerPopover />
    </>
  )
}
