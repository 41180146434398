import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import CmsPageContentHeader from 'components/CmsPage/ContentHeader'
import { makeSelectLoading } from 'containers/MainLayout/selectors'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import ErrorPage from '../ErrorPage'
import { makeSelectPageData } from './selectors'

interface Props {
  pageData: any
  isLoading: boolean
}

function PageContentBox(props: Props) {
  const { pageData, isLoading, ...rest } = props

  if (pageData.get('error')) {
    return <ErrorPage error={pageData.get('error').toJS()} />
  }

  return (
    <CmsPageContentHeader
      page={pageData}
      loading={isLoading}
      {...rest}
    />
  )
}

PageContentBox.propTypes = {
  pageData: ImmutablePropTypes.map,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
}

const mapStateToProps = createStructuredSelector({
  pageData: makeSelectPageData(),
  isLoading: makeSelectLoading(),
})

export default connect(mapStateToProps)(PageContentBox)
