import React from 'react'

export default function flagEn(props) {
  return (
    <svg
      viewBox="0 0 45 30"
      width="900"
      height="600"
      {...props}
    >
      <clipPath id="t">
        <path d="M22.5,15 h22.5 v15 z v15 h-22.5 z h-22.5 v-15 z v-15 h22.5 z" />
      </clipPath>
      <path
        d="M0,0 v30 h45 v-30 z"
        fill="#00247d"
      />
      <path
        d="M0,0 L45,30 M45,0 L0,30"
        stroke="#fff"
        strokeWidth="6"
      />
      <path
        d="M0,0 L45,30 M45,0 L0,30"
        clipPath="url(#t)"
        stroke="#cf142b"
        strokeWidth="4"
      />
      <path
        d="M22.5,0 v30 M0,15 h45"
        stroke="#fff"
        strokeWidth="10"
      />
      <path
        d="M22.5,0 v30 M0,15 h45"
        stroke="#cf142b"
        strokeWidth="6"
      />
    </svg>
  )
}
