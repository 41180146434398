import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import { animationBouncingEase, grayDark, grayDarker, grayLighter, transition } from 'utils/style-utils'

export const FooterMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 1.5rem 2rem;

  background: ${grayDarker};
`

export const SecondaryMenuWrapper = styled(FooterMenuWrapper)`
  /* DISABLE FOOTER GAP BETWEEN MENUS */
  margin-top: -1rem;

  padding: 1rem 2rem;
  background: ${grayDark};
  /* justify-content: end; */
`

export const StyledLink = styled(UrlLink)`
  &,
  &:hover,
  &:focus,
  &:active {
    color: ${grayLighter};
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${grayLighter};
    display: block;
    ${transition('transform', 0.6, animationBouncingEase)}
  }

  &:hover {
    text-decoration: underline;

    > svg {
      transform: scale(1.2);
    }
  }
`
