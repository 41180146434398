import React from 'react'

import Translator from 'bazinga-translator'

import Button from '../Btn'

function FinishGameBtn(props) {
  return (
    <div>
      {Translator.trans('You have unfinished game', {}, 'errors')}
      {': '}
      <Button
        md
        block
        danger
        {...props}
      >
        {Translator.trans('finish_game')}
      </Button>
    </div>
  )
}

FinishGameBtn.propTypes = {
  // gameFinishUrl: PropTypes.string
}

export default FinishGameBtn
