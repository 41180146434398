import { useQuery } from '@tanstack/react-query'
import { getQueryFnWithYupValidation } from 'components/useQueryWithValidator/utils'

import { getGames } from '../../../containers/GamesBlock/service'
import { GamesWithCategories } from '../../../containers/GamesBlock/types'
import { gamesWithCategoriesSchema } from '../../../containers/GamesBlock/validator'
import { GAMES_CACHE_TIME } from './constants'
import { getGameCacheKey } from './utils'

interface Props<TSelectData> {
  select?: (data: GamesWithCategories) => TSelectData
}

export function useGamesWithCategories<TSelectData = GamesWithCategories>({ select }: Props<TSelectData> = {}) {
  return useQuery({
    queryKey: getGameCacheKey(),
    queryFn: getQueryFnWithYupValidation(getGames, gamesWithCategoriesSchema),
    select,
    gcTime: GAMES_CACHE_TIME,
    staleTime: GAMES_CACHE_TIME,
  })
}
