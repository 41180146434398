import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import { bh } from '../../../utils/style-utils'

const StyledImg = styled.img`
  max-width: 260px;
  margin: 0 ${bh}px ${bh}px 0;
`

function Img({ media, ...rest }) {
  return (
    <StyledImg
      src={media.getIn(['paths', 'small'])}
      alt={media.get('title')}
      {...rest}
    />
  )
}

Img.propTypes = {
  media: ImmutablePropTypes.map.isRequired,
}

export default Img
