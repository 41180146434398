import React from 'react'

import CityAutocomplete from '../../../../../../containers/Autocompletes/CityAutocomplete'
import { FormProps } from '../../../../../Form/types'
import { useClearFieldsOnCityChange } from './useClearFieldsOnCityChange'

function RUIANCityAutocomplete(props: FormProps) {
  const clearFieldsOnCityChange = useClearFieldsOnCityChange()

  return (
    <CityAutocomplete
      onCustomChange={() => clearFieldsOnCityChange()}
      {...props}
    />
  )
}

export default RUIANCityAutocomplete
