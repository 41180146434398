import React from 'react'

export default function GooglePlus(props) {
  return (
    <svg
      width="24.59"
      height="25"
      viewBox="0 0 24.59 25"
      {...props}
    >
      <path
        d="M1635.43,98.43a3.092,3.092,0,0,0-1.46-2.488l-0.63-.5a0.959,0.959,0,0,1-.46-0.764,1.279,1.279,0,0,1,.49-0.87,2.918,2.918,0,0,0,.23-4.921h1.08l1.12-.64h-3.6a4.724,4.724,0,0,0-3.13.978,3.22,3.22,0,0,0-1.12,2.38,2.787,2.787,0,0,0,2.9,2.79c0.18,0,.37-0.019.56-0.036a1.728,1.728,0,0,0-.17.693,1.9,1.9,0,0,0,.52,1.208,7.4,7.4,0,0,0-3.33.817,2.551,2.551,0,0,0-1.35,2.167c0,1.315,1.22,2.541,3.76,2.541C1633.84,101.787,1635.43,100.1,1635.43,98.43Zm-3.75-4.531c-1.51,0-2.19-1.972-2.19-3.161a2.114,2.114,0,0,1,.38-1.314,1.628,1.628,0,0,1,1.23-.588c1.45,0,2.2,1.991,2.2,3.268A1.615,1.615,0,0,1,1631.68,93.9Zm0.01,7.14c-1.87,0-3.07-.905-3.07-2.166a1.988,1.988,0,0,1,1.5-1.829,6.862,6.862,0,0,1,1.84-.285,2.745,2.745,0,0,1,.4.018c1.33,0.959,1.9,1.439,1.9,2.345C1634.26,100.224,1633.37,101.04,1631.69,101.04Zm3.9-6.06h1.89V96.9h0.95V94.98h1.89V94.018h-1.89V92.1h-0.95v1.923h-1.89V94.98ZM1646,94.5a12.3,12.3,0,1,0-12.3,12.5A12.4,12.4,0,0,0,1646,94.5Zm-23.48,0a11.181,11.181,0,1,1,11.18,11.362A11.269,11.269,0,0,1,1622.52,94.5Z"
        transform="translate(-1621.41 -82)"
      />
    </svg>
  )
}
