import { BASE_PATH, LANG } from '../App/constants'

const TOURNAMENTS_BASE_PATH = `${BASE_PATH}/api/tournaments`
export const REFETCH_DELAY = 3000

export const GET_PLAYER_TOURNAMENTS_HISTORY_URL = () => `${TOURNAMENTS_BASE_PATH}/membership/obsolete/showcase`
export const GET_TOURNAMENTS_SHOWCASE_URL = () => `${TOURNAMENTS_BASE_PATH}/nearest/showcase`
export const GET_VIP_TOURNAMENTS_SHOWCASE_URL = (playerLevel: string) =>
  `${TOURNAMENTS_BASE_PATH}/nearest/${playerLevel}/showcase`
export const GET_SUBSCRIBE_TOURNAMENT_URL = (tournamentId: number) =>
  `${TOURNAMENTS_BASE_PATH}/tournament/${tournamentId}/subscribe`
export const GET_ACTIVE_SUBSCRIPTIONS_TOURNAMENT_URL = () => `${TOURNAMENTS_BASE_PATH}/membership/nearest/showcase`
export const GET_TOURNAMENT_DETAILS_URL = (id: number) => `${LANG}/tournament/${id}`
export const GET_ARCHIVAL_TOURNAMENTS_URL = () => `/${LANG}/archival-tournaments`
export const GET_ALL_ARCHIVAL_TOURNAMENTS_URL = () => `${TOURNAMENTS_BASE_PATH}/obsolete/showcase`
export const GET_GAME_TOURNAMENTS_ONGOING_SHOWCASE_URL = (slug: string) =>
  `${TOURNAMENTS_BASE_PATH}/game/${slug}/ongoing/showcase`
export const GET_GAME_VIP_TOURNAMENTS_ONGOING_SHOWCASE_URL = (slug: string, playerLevel: string) =>
  `${TOURNAMENTS_BASE_PATH}/game/${slug}/${playerLevel}/ongoing/showcase`
