import { useChangeFormValue } from '../../../../../../containers/RegisterPage/utils'
import { CITY_TEXT_FIELD, getExternalRegistrationFieldName, PLACE_FIELD, STREET_TEXT_FIELD, ZIP_FIELD } from '../utils'

export function useClearFieldsOnStreetChange(): () => void {
  const clearTownText = useChangeFormValue(getExternalRegistrationFieldName(CITY_TEXT_FIELD))
  const clearStreetText = useChangeFormValue(getExternalRegistrationFieldName(STREET_TEXT_FIELD))
  const clearZipText = useChangeFormValue(getExternalRegistrationFieldName(ZIP_FIELD))
  const clearPlaceSelect = useChangeFormValue(getExternalRegistrationFieldName(PLACE_FIELD))

  return () => {
    clearTownText('')
    clearStreetText('')
    clearZipText('')
    clearPlaceSelect(null)
  }
}
