import React from 'react'

import { grayLighter } from '../../utils/style-utils'

export default function X(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 26 26"
      {...props}
    >
      <path d="M16.7 13.4a.6.6 0 0 1 0-.8l9-9a1 1 0 0 0 0-1.6L24 .4a1.3 1.3 0 0 0-.8-.4 1 1 0 0 0-1 .4l-8.8 9a.6.6 0 0 1-.8 0l-9-9A1 1 0 0 0 2 .4L.4 2a1 1 0 0 0 0 1.7l9 9a.6.6 0 0 1 0 .7l-9 9a1 1 0 0 0 0 1.6L2 25.6a1 1 0 0 0 1.7 0l9-9a.6.6 0 0 1 .7 0l9 9a1 1 0 0 0 1.6 0l1.6-1.6a1 1 0 0 0 0-1.7z" />
    </svg>
  )
}

export const CLOSE_BUTTON_HTML = `
<g>
  <svg width="12" height="12" viewBox="0 0 26 26" version="1.0" xmlns="http://www.w3.org/2000/svg">
    <path fill=${grayLighter} d="M16.7 13.4a.6.6 0 0 1 0-.8l9-9a1 1 0 0 0 0-1.6L24 .4a1.3 1.3 0 0 0-.8-.4 1 1 0 0 0-1 .4l-8.8 9a.6.6 0 0 1-.8 0l-9-9A1 1 0 0 0 2 .4L.4 2a1 1 0 0 0 0 1.7l9 9a.6.6 0 0 1 0 .7l-9 9a1 1 0 0 0 0 1.6L2 25.6a1 1 0 0 0 1.7 0l9-9a.6.6 0 0 1 .7 0l9 9a1 1 0 0 0 1.6 0l1.6-1.6a1 1 0 0 0 0-1.7z" />
  </svg>
</g>
`
