import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { INBOX_PATH } from '../../containers/App/constants'
import ButtonBase from '../Btn'
import UrlLink from '../UrlLink'

const Button = styled(ButtonBase)`
  span {
    display: inline-block;
  }
`

function InboxButton({ newMessages, ...props }) {
  return (
    <UrlLink link={INBOX_PATH}>
      <Button
        primary
        {...props}
      >
        {Translator.trans('Inbox', {}, 'buttons')}
      </Button>
    </UrlLink>
  )
}

InboxButton.propTypes = {
  newMessages: PropTypes.number,
}

export default InboxButton
