import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'
import { useInjectReducer } from '../../utils/routing/injectReducer'
import { useInjectSaga } from '../../utils/routing/injectSaga'
import { PAYMENT_METHODS_STATE_KEY } from '../PaymentMethodsPage/constants'
import reducer from '../PaymentMethodsPage/reducer'
import saga from '../PaymentMethodsPage/sagas'

const key = PAYMENT_METHODS_STATE_KEY
const Component = lazy(() => import(/* webpackChunkName: "MakeWithdraw" */ '../PaymentMethodsPage'))

export default function Loadable() {
  const { isLoading } = useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component type="withdraw" />
    </Suspense>
  )
}
