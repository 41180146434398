import { array, string, object, number } from 'yup'

export const cityValidator = object({
  type: string().required(),
  id: number().required(),
  name: string().required(),
  group: string().required(),
})

export const cityArrayValidator = array().of(cityValidator).required()
