/* eslint-disable default-param-last */
import Translator from 'bazinga-translator'
import { fromJS } from 'immutable'
import { flattenDeep, uniqBy } from 'lodash'

import {
  ADD_FLASH_MESSAGE,
  CLOSE_ALL_FLASH_MESSAGES,
  CLOSE_FLASH_MESSAGE,
  INIT_LOAD_SUCCESS,
} from '../MainLayout/constants'
import { SET_DEFAULT_PAYMENT_METHOD_ERROR } from '../PaymentMethodsPage/constants'

const initialState = fromJS([])

export function getNewFlashMessages(currentFlashes, ...newFlashes) {
  const flashMessages = flattenDeep([currentFlashes.toJS(), newFlashes])
    .filter(Boolean)
    .filter((message) => message.text)
    .map((message) => ({ ...message, text: cleanupText(message.text) }))
    .filter((message) => message.text)

  return fromJS(uniqBy(flashMessages, 'text'))
}

function cleanupText(text) {
  return text.replace(/<\/?[^>]+(>|$)/g, '').trim()
}

export default function reducerFlashMessages(state = initialState, action) {
  switch (action.type) {
    case INIT_LOAD_SUCCESS: {
      return getNewFlashMessages(state, action.data && action.data.flashMessages, action.flashMessages)
    }

    case ADD_FLASH_MESSAGE: {
      if (!action.data) {
        return state
      }
      const { text, type } = action.data

      return getNewFlashMessages(state, [{ text, type }])
    }

    case CLOSE_FLASH_MESSAGE: {
      return state.filter((message) => message.get('text') !== action.message)
    }

    case CLOSE_ALL_FLASH_MESSAGES: {
      return fromJS([])
    }

    case SET_DEFAULT_PAYMENT_METHOD_ERROR: {
      return getNewFlashMessages(state, {
        type: 'error',
        text: (action.data && action.data.error) || Translator.trans('unknown_error', {}, 'errors'),
      })
    }

    default: {
      let newState = state

      if (action.flashMessages) {
        newState = getNewFlashMessages(newState, action.flashMessages)
      }

      if (action.error && action.error.flashMessages) {
        newState = getNewFlashMessages(newState, action.error.flashMessages)
      }

      if (action.data && action.data.type === 'error_message') {
        newState = getNewFlashMessages(newState, [{ text: action.data.data, type: 'error' }])
      }

      return newState
    }
  }
}
