import React from 'react'

import Translator from 'bazinga-translator'
import withCondition from 'components/withCondition'

import { PLAYER_PAYMENT_METHODS_ADDRESS } from '../../containers/App/constants'
import Btn from '../Btn'

function MakeDepositButtonComponent({ ...props }) {
  return (
    <Btn
      to={PLAYER_PAYMENT_METHODS_ADDRESS}
      primary
      md
      {...props}
    >
      {Translator.trans('Make deposit', {}, 'payments')}
    </Btn>
  )
}

export const MakeDepositButton = withCondition(MakeDepositButtonComponent)
