import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import Button from './Button'
import { Span, SpanTop, SpanBottom } from './Span'

const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  display: none;
  text-align: center;
  position: relative;
  z-index: 11;

  ${media.md`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

function MenuButton({ clickHandler, open }) {
  return (
    <Wrapper>
      <Button
        id="hamburger-menu-button"
        onClick={(e) => {
          e.stopPropagation()
          clickHandler()
        }}
        open={open}
      >
        <SpanTop open={open} />
        <Span open={open} />
        <SpanBottom open={open} />
      </Button>
    </Wrapper>
  )
}

MenuButton.propTypes = {
  clickHandler: PropTypes.func,
  open: PropTypes.bool,
}

export default MenuButton
