import React from 'react'

export default function Dice(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        d="M1127.27,353.574a1.033,1.033,0,1,0,1.03,1.033A1.039,1.039,0,0,0,1127.27,353.574Zm3.1,4.131a1.033,1.033,0,1,0,1.03,1.033A1.039,1.039,0,0,0,1130.37,357.705Zm-6.2-8.261a1.033,1.033,0,1,0,1.03,1.032A1.038,1.038,0,0,0,1124.17,349.444Zm7.23-7.229a1.033,1.033,0,1,0,1.03,1.032A1.03,1.03,0,0,0,1131.4,342.215Zm6.2,0a1.033,1.033,0,1,0,1.03,1.032A1.03,1.03,0,0,0,1137.6,342.215Zm4.13,11.359a1.033,1.033,0,1,0,1.04,1.033A1.03,1.03,0,0,0,1141.73,353.574Zm8.25-10.422a1.034,1.034,0,0,0-.71-0.985l-14.54-6.132a0.939,0.939,0,0,0-.87.209l-14.13,5.934a1.041,1.041,0,0,0-.71.965c0,0.036-.02.067-0.02,0.1V359.77c0,0.013.01,0.022,0.01,0.034a1.043,1.043,0,0,0,.72,1.048l14.54,6.131a1,1,0,0,0,.22.015l0.01,0h0.01a0.921,0.921,0,0,0,.63-0.224l14.13-5.934a1.033,1.033,0,0,0,.72-1.038c0-.011.01-0.021,0.01-0.032V343.249C1150,343.214,1149.98,343.186,1149.98,343.152Zm-16.51,21.2-12.4-5.229v-14.23l12.4,5.228V364.35Zm1.01-16.1-11.87-5,11.91-5.006,11.87,5Zm13.45,10.867-12.4,5.211V350.088l12.4-5.211v14.242Z"
        transform="translate(-1119 -336)"
      />
    </svg>
  )
}
