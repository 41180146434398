import { SHOW_BUTTON_HTML } from '../../components/Icons/LiveChat'
import { CLOSE_BUTTON_HTML } from '../../components/Icons/X'
import { LIVE_CHAT_WIDGET_SELECTOR, LIVE_CHAT_WIDGET_URL } from '../App/constants'

const CLOSE_BUTTON_ID = 'live-widget-close-button'
const SHOW_BUTTON_ID = 'live-widget-show-button'
const LIVE_CHAT_WIDGET_IS_HIDDEN_KEY = 'is-live-widget-hidden'
const closeButtonStyle = 'background: none; border: none; font-weight: bold;'
const showButtonStyle =
  'background: none; border: none; font-weight: bold; position: fixed; right: 0; bottom: 0; z-index: 10;'

function getCloseButton() {
  const button = document.createElement('button')
  const widget = document.querySelector(LIVE_CHAT_WIDGET_SELECTOR) as HTMLDivElement
  const showButton = getShowButton()

  button.innerHTML = CLOSE_BUTTON_HTML
  button.style.cssText = closeButtonStyle
  button.id = CLOSE_BUTTON_ID
  button.onclick = () => {
    localStorage.setItem(LIVE_CHAT_WIDGET_IS_HIDDEN_KEY, 'true')
    widget.style.cssText = 'display: none'

    document.body.appendChild(showButton)
  }

  return button
}

export function getShowButton() {
  const button = document.createElement('button')
  const widget = document.querySelector(LIVE_CHAT_WIDGET_SELECTOR) as HTMLDivElement

  button.innerHTML = SHOW_BUTTON_HTML
  button.style.cssText = showButtonStyle
  button.id = SHOW_BUTTON_ID
  button.onclick = () => {
    localStorage.removeItem(LIVE_CHAT_WIDGET_IS_HIDDEN_KEY)
    if (!widget) {
      const script = getLiveChatWidget()

      document.body.appendChild(script)
    } else {
      widget.style.cssText = 'display: flex;'
    }
    document.body.removeChild(button)
  }

  return button
}

function handleRemovingWidget() {
  if (LIVE_CHAT_WIDGET_SELECTOR) {
    setTimeout(() => {
      const widget = document.querySelector(LIVE_CHAT_WIDGET_SELECTOR)
      const isExistingCloseButton = !!document.getElementById(CLOSE_BUTTON_ID)
      const isExistingShowButton = !!document.getElementById(SHOW_BUTTON_ID)
      const closeWidgetButton = getCloseButton()

      if (!isExistingShowButton && isLiveWidgetHiddenByUser()) {
        const showWidgetButton = getShowButton()

        document.body.appendChild(showWidgetButton)
      }

      if (widget && !isExistingCloseButton) {
        widget.appendChild(closeWidgetButton)
      } else if (!widget) {
        handleRemovingWidget()
      }
    }, 1000)
  }
}

export function getLiveChatWidget() {
  const script = document.createElement('script')
  script.src = `${LIVE_CHAT_WIDGET_URL}?${Date.now() / 60000 || 0}`
  script.async = true
  script.onload = () => {
    handleRemovingWidget()
  }

  return script
}

const isLiveWidgetHiddenByUser = () => localStorage.getItem(LIVE_CHAT_WIDGET_IS_HIDDEN_KEY)

export function shouldDisplayLiveChatShowButton() {
  return isLiveWidgetHiddenByUser() && LIVE_CHAT_WIDGET_URL
}

export function shouldDisplayLiveChatWidget() {
  return !isLiveWidgetHiddenByUser() && LIVE_CHAT_WIDGET_URL
}
