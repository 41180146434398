import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { media, bh, lg, alertBoxShadow } from '../../utils/style-utils'
import Box from '../Box'
import Overlay from '../Overlay'
import Bg from '../Overlay/Bg'
import Portal from './Portal'

const ZoomIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-10px, 0) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
`

const StyledOverlay = styled(Overlay)`
  padding: 1rem;
`

const DialogBox = styled(Box)`
  position: relative;
  z-index: 10;
  margin: auto;
  min-width: ${bh * 20}px;
  max-width: ${lg}px;
  transform: translate(0, 0);
  box-shadow: ${alertBoxShadow};
  word-wrap: break-word;

  animation: 0.3s ${ZoomIn} cubic-bezier(0.26, 1.43, 0.65, 1);

  display: inline-block;
  vertical-align: middle;
  text-align: left;

  ${media.sm`
    min-width: auto;
  `}

  ${media.xs`
    width: 100%;
  `}

  ${(p) =>
    p.fullHeight
      ? `
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `
      : ''}
`

function Dialog({ title, children, Footer, fullHeight, ...rest }) {
  useEffect(() => {
    document.querySelector('html').classList.add('scroll-lock')

    return () => document.querySelector('html').classList.remove('scroll-lock')
  }, [])

  return (
    <Portal>
      <StyledOverlay
        {...rest}
        noClearFix={fullHeight}
      >
        <DialogBox
          Header={title}
          Footer={Footer}
          fullHeight={fullHeight}
        >
          {children}
        </DialogBox>
        <Bg />
      </StyledOverlay>
    </Portal>
  )
}

Dialog.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  Footer: PropTypes.any,
  fullHeight: PropTypes.bool,
}

export default Dialog
