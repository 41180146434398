import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'
import { useInjectReducer } from '../../utils/routing/injectReducer'
import { TRANSACTION_HISTORY_PAGE_STATE } from './constants'
import reducer from './reducer'

const key = TRANSACTION_HISTORY_PAGE_STATE
const Component = lazy(() => import(/* webpackChunkName: "TransactionHistoryPage" */ './index'))

export default function Loadable() {
  const { isLoading } = useInjectReducer({ key, reducer })

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component />
    </Suspense>
  )
}
