import { all, call, put, takeLatest } from 'redux-saga/effects'

import { request } from '../../utils/request'
import formdata from '../../utils/requestFormdata'
import { loadSuccess, loadError, resendSmsSuccess, resendSmsError } from './actions'
import { LOAD, RESEND_SMS } from './constants'

export function* initLoad(action) {
  const requestURL = `${action.pathname}?t=${Date.now()}`
  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(request, requestURL)
    yield put(loadSuccess(data.data, data.flashMessages))
  } catch (err) {
    yield put(loadError(err))
  }
}

function* initResendSms(action) {
  const requestURL = `${action.pathname}?json=1`
  const { postData } = action
  try {
    // Call our request helper (see 'utils/request')
    const data = postData ? yield call(formdata, requestURL, postData) : yield call(request, requestURL)
    yield put(resendSmsSuccess(data))
  } catch (err) {
    yield put(resendSmsError(err))
  }
}

export function* initData() {
  yield takeLatest(LOAD, initLoad)

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE)
  // yield cancel(watcher)
}

function* watchResendSms() {
  yield takeLatest(RESEND_SMS, initResendSms)

  // yield take(LOCATION_CHANGE)
  // yield cancel(watcher)
}

export default function* rootSaga() {
  yield all([initData(), watchResendSms()])
}
