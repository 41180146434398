import React from 'react'

import styled from 'styled-components'

import { cmsContentBg } from '../../utils/style-utils'
import CenteredLoader from './CenteredLoader'

const Full = styled.div`
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${cmsContentBg};
`

function FullCenteredLoader(props) {
  return (
    <Full {...props}>
      <CenteredLoader />
    </Full>
  )
}

export default FullCenteredLoader
