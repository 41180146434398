import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'

const Component = lazy(
  () => import(/* webpackChunkName: "ResetPasswordCheck" */ '../../components/ResetPassword/ResetCheck'),
)

export default function Loadable() {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component />
    </Suspense>
  )
}
