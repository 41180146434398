import styled from 'styled-components'
import { grayDark, grayLighter } from 'utils/style-utils'

export const LawInfoWrapper = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${grayLighter};
  justify-content: center;

  & * {
    padding: 0 0.5rem;
    &:not(:last-child) {
      border-right: 1px solid ${grayDark};
    }
  }
`
