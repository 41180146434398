import Translator from 'bazinga-translator'

enum TournamentType {
  betAmount = 'betAmount',
  roundsAmount = 'roundsAmount',
  weightedWin = 'weightedWin',
}

export const TournamentTypeMap = [
  {
    id: 1,
    name: TournamentType.betAmount,
    value: Translator.trans('bet_amount_type_label', {}, 'tournaments'),
  },
  {
    id: 2,
    name: TournamentType.roundsAmount,
    value: Translator.trans('rounds_amount_type_label', {}, 'tournaments'),
  },
  {
    id: 3,
    name: TournamentType.weightedWin,
    value: Translator.trans('weighted_win_amount_type_label', {}, 'tournaments'),
  },
]
