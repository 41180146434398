import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import { closeAnnouncement } from '../MainLayout/actions'
import { makeSelectAnnouncements } from '../MainLayout/selectors'
import SingleAnnouncement from './SingleAnnouncement'
import { AnnouncementsListProp } from './props'

class AnnouncementsBox extends React.PureComponent {
  static propTypes = {
    announcements: AnnouncementsListProp,
    onClose: PropTypes.func.isRequired,
  }

  render() {
    const { announcements, onClose } = this.props

    return (
      <>
        {announcements.map((announcement) => {
          const id = announcement.get('id')

          return (
            <SingleAnnouncement
              key={`announcement-${id}`}
              onClose={() => onClose(id)}
              announcement={announcement}
            />
          )
        })}
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  announcements: makeSelectAnnouncements(),
})

const mapDispatchToProps = {
  onClose: closeAnnouncement,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsBox)
