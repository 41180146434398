import styled, { keyframes } from 'styled-components'

import { defaultAnimationEase, media } from '../../utils/style-utils'
import Header from './Header'

const slideDown = keyframes`
  0%   { transform: translateY(-80px); }
  50%  { transform: translateY(-80px); }
  100% { transform: translateY(0px); }
`

const slideLeft = keyframes`
  0%   { transform: translateX(80px); }
  50%  { transform: translateX(80px); }
  100% { transform: translateX(0px); }
`

const HeaderAnimateIn = styled(Header)`
  animation: ${slideDown} 1s ${defaultAnimationEase};

  ${media.landscapeMd`
    animation: ${slideLeft} 1s ${defaultAnimationEase};
  `}
`

export default HeaderAnimateIn
