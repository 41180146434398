import React from 'react'

import PropTypes from 'prop-types'

import Link from './Link'

export default function MenuHeader({ header, toggleMenuPosition }) {
  const url = header.get('uri')
  const isActive = header.get('isActive')

  return (
    <Link
      id={'link-header'}
      isActive={isActive}
      isHeader
      onClick={() => url && !isActive && toggleMenuPosition(header.get('id'))}
      to={url}
    >
      {header.get('label')}
    </Link>
  )
}

MenuHeader.propTypes = {
  header: PropTypes.object,
  toggleMenuPosition: PropTypes.func.isRequired,
}
