import styled from 'styled-components'

import { bh, transition, animationBouncingEase, formFieldHeight, formFieldColor } from '../../utils/style-utils'

const topPad = bh * 2
const labelTop = bh / 1.5
const labelLeft = bh

const Wrapper = styled.div.attrs({
  className: 'field-wrapper',
})`
  padding: ${(p) => (p.type === 'radio' || p.type === 'checkbox' ? topPad / 2 : topPad)}px 0 0;
  position: relative;

  .field-label-floating {
    width: 90%;
    height: ${bh}px;
    line-height: ${bh}px;
    top: 0;
    left: ${labelLeft}px;
    transform: translate3d(0, ${labelTop}px, 0);
  }

  /* FLOATING LABEL */
  & > div > input, /* money input */
  & > label > input, /* file input */
  & > select,
  & > textarea,
  & > input {
    &::placeholder {
      color: transparent;
    }

    & ~ .field-label-floating {
      ${transition('transform', 0.3, animationBouncingEase)}
    }

    &[value=''] ~ .field-label-floating {
      transform: translate3d(0, ${topPad + (formFieldHeight - bh) / 2}px, 0);
    }

    &:focus ~ .field-label-floating {
      color: ${formFieldColor};
      transform: translate3d(0, ${labelTop}px, 0);
    }
  }

  & > div > input,
  & > label > input {
    & ~ .field-label-floating {
      top: ${-topPad}px;
    }
  }

  & > select,
  & > label > input {
    & ~ .field-label-floating {
      transform: translate3d(0, ${labelTop}px, 0) !important;
    }
  }

  ${(p) =>
    p.center
      ? `
    text-align: center;
  `
      : ''}
`

export default Wrapper
