/* eslint-disable default-param-last */
import { fromJS } from 'immutable'
import { applyMiddleware, compose, createStore } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { combineReducers } from 'redux-immutable'
import createSagaMiddleware from 'redux-saga'

import { rootReducers } from './reducers'
import { createRouteReducerWithQuery } from './utils/routing/routeReducerWithQuery'

let configureStoreAndHistoryCache

export default function configureStore(initialState = {}, history) {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
    selectRouterState: (state) => state.get('router'),
  })

  const sagaMiddleware = createSagaMiddleware()
  const middleware = [routerMiddleware, sagaMiddleware]
  let composeEnhancers = compose

  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    }
  }

  const enhancers = applyMiddleware(...middleware)
  const reducers = { ...rootReducers, router: createRouteReducerWithQuery(routerReducer) }
  const store = createStore(combineReducers(reducers), fromJS(initialState), composeEnhancers(enhancers))

  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {}
  store.injectedSagas = {}
  store.currentReducers = reducers

  return { store, createReduxHistory }
}

export function getConfiguredStore(initialState = {}, history) {
  if (configureStoreAndHistoryCache) {
    return configureStoreAndHistoryCache
  }

  if (!history) {
    return undefined
  }

  configureStoreAndHistoryCache = configureStore(initialState, history)

  return configureStoreAndHistoryCache
}
