/* eslint-disable no-func-assign */
import React from 'react'

import PropTypes from 'prop-types'

import flagCs from '../Icons/flagCs'
import flagEn from '../Icons/flagEn'
import { FLAG_CS, FLAG_EN } from './constants'

function Icon() {
  return <span />
}

function Flag({ flag }) {
  switch (flag) {
    case FLAG_EN:
      Icon = flagEn
      break
    case FLAG_CS:
      Icon = flagCs
      break
    default:
      break
  }

  return <Icon />
}

Flag.propTypes = {
  flag: PropTypes.string,
}

export default Flag
