import { createSelector } from 'reselect'

export const makeSelectLocationState = () => {
  let prevRoutingState
  let prevRoutingStateJS

  return (state) => {
    const routingState = state.get('route') // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState
      prevRoutingStateJS = routingState.toJS()
    }

    return prevRoutingStateJS
  }
}

export const selectRouteDomain = (state) => state.get('route')

export const makeSelectLocationBeforeTransitions = () =>
  createSelector(selectRouteDomain, (substate) => substate.getIn(['locationBeforeTransitions', 'location']))
export const makeSelectCurrentRoute = () =>
  createSelector(selectRouteDomain, (substate) => substate.get('locationBeforeTransitions').toJS())
export const makeSelectPreviousRoute = () =>
  createSelector(selectRouteDomain, (substate) => substate.get('previousLocationBeforeTransition')?.toJS())
