import React from 'react'

import Translator from 'bazinga-translator'
import Button from 'components/Btn'
import { LOGIN_ADDRESS, REGISTER_ADDRESS } from 'containers/LoginPage/constants'

import { Menu } from '../../../Menu'
import { Links } from '../styles'

export function Anonymous() {
  return (
    <>
      <Menu />
      <Links>
        <Button
          link
          md
          to={REGISTER_ADDRESS}
        >
          {Translator.trans('Register')}
        </Button>

        <Button
          link
          md
          to={LOGIN_ADDRESS}
        >
          {Translator.trans('Log in')}
        </Button>
      </Links>
    </>
  )
}
