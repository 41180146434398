/* eslint-disable react/style-prop-object */
import React from 'react'

export default function Synot(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="200mm"
      height="200mm"
      version="1.1"
      // style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 20000 20000"
      {...props}
    >
      <g id="Warstwa_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2237239860864">
          <path
            className="fil0"
            d="M348 4437l3218 0 5084 5286 0 -5286c900,0 1800,0 2700,0l0 5286 5084 -5286 3218 0 0 1829 -1959 0 -4909 5104c-1856,0 -3712,0 -5568,0l-4909 -5104 -1959 0 0 -1829z"
          />
          <rect
            className="fil0"
            x="8650"
            y="11995"
            width="2700"
            height="3568"
          />
        </g>
      </g>
    </svg>
  )
}
