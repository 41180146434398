import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import Content from '../../components/CmsPage/Content'
import ErrorPage from '../ErrorPage'
import { makeSelectPageData } from './selectors'

function PageContentBox(props) {
  const { pageData, children, ...rest } = props

  if (pageData.get('error')) {
    return <ErrorPage error={pageData.get('error').toJS()} />
  }

  return <Content {...rest}>{children}</Content>
}

PageContentBox.propTypes = {
  pageData: ImmutablePropTypes.map,
  children: PropTypes.node,
}

const mapStateToProps = createStructuredSelector({
  pageData: makeSelectPageData(),
})

export default connect(mapStateToProps)(PageContentBox)
