import React from 'react'

import PropTypes from 'prop-types'

export function withSwipe(WrappedComponent) {
  return class WithSwipe extends React.Component {
    static propTypes = {
      onLeftSwipe: PropTypes.func.isRequired,
      onRightSwipe: PropTypes.func.isRequired,
      delta: PropTypes.number,
    }

    constructor(props) {
      super(props)

      this.startX = 0
      this.currentX = 0
      this.beingTouched = false
    }

    handleStart(clientX) {
      this.startX = clientX
      this.currentX = clientX
      this.beingTouched = true
    }

    handleMove(clientX) {
      if (this.beingTouched) {
        this.currentX = clientX
      }
    }

    handleEnd() {
      const { startX, currentX } = this
      const { onLeftSwipe, onRightSwipe, delta } = this.props

      if (Math.abs(startX - currentX) > (delta || 50)) {
        if (startX > currentX) {
          onLeftSwipe()
        }
        if (startX < currentX) {
          onRightSwipe()
        }
      }

      this.startX = 0
      this.currentX = 0
      this.beingTouched = false
    }

    handleTouchStart(touchStartEvent) {
      this.handleStart(touchStartEvent.targetTouches[0].clientX)
    }

    handleTouchMove(touchMoveEvent) {
      this.handleMove(touchMoveEvent.targetTouches[0].clientX)
    }

    handleMouseDown(mouseDownEvent) {
      mouseDownEvent.preventDefault()
      this.handleStart(mouseDownEvent.clientX)
    }

    handleMouseMove(mouseMoveEvent) {
      this.handleMove(mouseMoveEvent.clientX)
    }

    render() {
      const { onLeftSwipe, onRightSwipe, ...rest } = this.props

      return (
        <WrappedComponent
          onTouchStart={(touchStartEvent) => this.handleTouchStart(touchStartEvent)}
          onTouchMove={(touchMoveEvent) => this.handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => this.handleEnd()}
          onMouseDown={(mouseDownEvent) => this.handleMouseDown(mouseDownEvent)}
          onMouseMove={(mouseMoveEvent) => this.handleMouseMove(mouseMoveEvent)}
          onMouseUp={() => this.handleEnd()}
          onMouseLeave={() => this.handleEnd()}
          {...rest}
        />
      )
    }
  }
}
