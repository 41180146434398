import React from 'react'

import { DashboardButton } from 'components/UserHeader/DashboardButton'

export function Staff() {
  return (
    <DashboardButton
      link
      md
    />
  )
}
