import React from 'react'

import PropTypes from 'prop-types'

import { hrefToTo } from '../../utils/url'
// import { NavLink } from 'react-router-dom'
import UrlLink from '../UrlLink'

function FixedLink({ className, to, style, onClick, children, activeClassName, exact, id }) {
  return (
    <UrlLink
      onClick={onClick}
      link={hrefToTo(to)}
      className={className}
      style={style}
      activeClassName={activeClassName || 'active'}
      exact={exact}
      id={id}
    >
      {children}
    </UrlLink>
  )
}

FixedLink.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.any,
  exact: PropTypes.bool,
  id: PropTypes.string,
}

export default FixedLink
