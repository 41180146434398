import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getConsentsCookie } from '../../components/Cookie/utils'
import { makeSelectCookies } from '../MainLayout/selectors'
import { initGoogleSiteVerifications } from './googleSiteVerifications'
import { initMetaAnalytics } from './metaAnalytics'
import { analyticsInit, updateGoogleConsents } from './utils'

export function AnalyticsCode(): null {
  useSelector(makeSelectCookies())
  const { statistical, marketing } = getConsentsCookie()

  useEffect(() => {
    if (statistical) {
      updateGoogleConsents(marketing)
    }
  }, [marketing, statistical])
  useEffect(() => analyticsInit(statistical), [statistical])
  useEffect(() => initMetaAnalytics(statistical), [statistical])
  useEffect(() => initGoogleSiteVerifications(statistical), [statistical])

  return null
}
