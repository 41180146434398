import * as React from 'react'

import { IconProps } from '../types'

function Info(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm10-3.333v10h2v-10h-2Zm0-1.111V5.333h2v2.223h-2Z"
      />
    </svg>
  )
}
export default Info
