import { array, string, object, number } from 'yup'

import { SynchronizationStatus } from '../types/SynchronizationStatus'
import { backgroundSchema } from './schemas/backgroundSchema'
import { tournamentConditionsSchema } from './schemas/conditionsSchema'
import { rewardTypeSchema } from './schemas/rewardTypeSchema'
import { tournamentDateSchema } from './schemas/tournamentDateSchema'
import { tournamentTypeSchema } from './schemas/tournamentTypeSchema'

export const tournamentSchema = object({
  id: number().required(),
  name: string().required(),
  channel: string().required(),
  description: string(),
  rewardAmt: number().required(),
  games: array().of(string().required()).required(),
  type: tournamentTypeSchema,
  startDate: tournamentDateSchema,
  finishDate: tournamentDateSchema,
  rewardType: rewardTypeSchema,
  conditions: tournamentConditionsSchema,
  background: backgroundSchema,
  logo: string(),
  synchronizationStatus: string().oneOf(Object.values(SynchronizationStatus)),
}).required()

export const tournamentsListSchema = array().of(tournamentSchema).required()
