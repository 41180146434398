import { css } from 'styled-components'

import { pulse } from '../../utils/style-animations'
import { brandSecondary, brandPrimary } from '../../utils/style-utils'

export default css`
  ${(props) => (props.secondary ? `color: ${brandSecondary};` : '')}
  ${(props) => (props.primary ? `color: ${brandPrimary};` : '')}
  ${(props) => (props.center ? 'text-align: center;' : '')}
  ${(props) =>
    props.skeleton
      ? css`
          width: 100%;
          background: rgba(255, 255, 255, 0.1);
          animation: 2s ${pulse} infinite linear;

          &:before {
            content: '\\00a0';
          }
        `
      : ''}

  margin: 0.5em 0;
  line-height: 1.25em;
  word-break: break-word;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
