import styled from 'styled-components'

import { warningBg, warningColor } from '../../utils/style-utils'
import Alert from './index'

export default styled(Alert).attrs({
  alertType: 'warning',
})`
  color: ${warningColor};
  background: ${warningBg};

  .alert-icon {
    fill: ${warningColor};
  }
`
