import { TournamentWithMembership } from './types/Tournament'

export function getDatesForThreshold(
  tournaments: TournamentWithMembership[],
  futureTournaments: TournamentWithMembership[],
) {
  return [
    ...tournaments.map((tournament) => tournament.finishDate.date),
    ...futureTournaments.map((tournament) => tournament.startDate.date),
  ]
}
