/* eslint-disable react/state-in-constructor */
import React, { Component, ErrorInfo, ReactNode } from 'react'

import ErrorFallback from '../../components/ErrorFallback'
import { Wrapper } from './styles/Wrapper'

interface Props {
  children: ReactNode
}

interface State {
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: undefined,
    errorInfo: undefined,
  }

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
    return { error, errorInfo }
  }

  public render() {
    if (this.state.error) {
      return (
        <Wrapper>
          <ErrorFallback
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </Wrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
