import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  RELOAD_REGISTER_FORM,
  RELOAD_REGISTER_FORM_SUCCESS,
  RELOAD_REGISTER_FORM_ERROR,
  FINISH_WITHOUT_CONFIRMING,
  GO_TO_STAGE_TWO,
} from './constants'

export function load(pathname) {
  return {
    type: LOAD,
    pathname,
  }
}

export function loadSuccess(data, flashMessages) {
  return {
    type: LOAD_SUCCESS,
    data,
    flashMessages,
  }
}

export function loadError(error) {
  return {
    type: LOAD_ERROR,
    error,
  }
}

export function reloadRegisterForm() {
  return {
    type: RELOAD_REGISTER_FORM,
  }
}

export function reloadRegisterFormSuccess(data) {
  return {
    type: RELOAD_REGISTER_FORM_SUCCESS,
    data,
  }
}

export function reloadRegisterFormError(error) {
  return {
    type: RELOAD_REGISTER_FORM_ERROR,
    error,
  }
}

export function goToStageTwo(data) {
  return {
    type: GO_TO_STAGE_TWO,
    data,
  }
}

export function finishWithoutConfirming() {
  return {
    type: FINISH_WITHOUT_CONFIRMING,
  }
}
