/* eslint-disable default-param-last */
import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'redux-first-history'
import { reducer as formReducer } from 'redux-form/immutable'

import flashMessages from './containers/FlashMessagesBox/reducer'
import global from './containers/MainLayout/reducer'
import sharedBlocks from './containers/SharedBlock/reducer'

const routeInitialState = fromJS({
  locationBeforeTransitions: null,
})

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        previousLocationBeforeTransition: state.get('locationBeforeTransitions'),
        locationBeforeTransitions: action.payload,
      })
    default:
      return state
  }
}

export const rootReducers = {
  route: routeReducer,
  form: formReducer,
  global,
  sharedBlocks,
  flashMessages,
}
