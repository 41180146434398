import { SERVER_DATE } from './containers/App/constants'
import './i18n/cs'
import { render } from './main'
import { setServerClientTimeDiff } from './utils/dates'
import './utils/fontObserver'
import './utils/public-path'

// eslint-disable-next-line no-console
console.timeEnd('js-start')

setServerClientTimeDiff(SERVER_DATE)
render()
