import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import FinishGameBtn from '../../components/UserHeader/FinishGameBtn'
import { ACCOUNT_ACCOUNTING_SERVER } from '../App/constants'
import { makeSelectGameFinishUrl, makeSelectUserBalances, makeSelectSendingGameFinish } from '../MainLayout/selectors'
import { sendGameFinish } from './actions'

function FinishGameBtnBox({ gameFinishUrl, dispatchSendGameFinish, loading, balances }) {
  const inGame = balances.getIn([ACCOUNT_ACCOUNTING_SERVER, 'balance'])

  if (!gameFinishUrl || !inGame) {
    return null
  }

  return (
    <FinishGameBtn
      loading={loading}
      onClick={() => dispatchSendGameFinish(gameFinishUrl)}
    />
  )
}

FinishGameBtnBox.propTypes = {
  loading: PropTypes.bool,
  gameFinishUrl: PropTypes.string,
  balances: ImmutablePropTypes.map,
  dispatchSendGameFinish: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  gameFinishUrl: makeSelectGameFinishUrl(),
  balances: makeSelectUserBalances(),
  loading: makeSelectSendingGameFinish(),
})

const mapDispatchToProps = {
  dispatchSendGameFinish: sendGameFinish,
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishGameBtnBox)
