import React, { PropsWithChildren } from 'react'

import { MainFooter } from 'components/MainFooter'

import { DesktopAppBar } from './DesktopAppBar'
import { MobileAppBar } from './MobileAppBar'

export function NewLayout({ children }: PropsWithChildren) {
  return (
    <>
      <DesktopAppBar />
      <MobileAppBar />
      {children}
      <MainFooter />
    </>
  )
}
