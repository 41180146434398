import styled from 'styled-components'

import { transition, animationBouncingEase, fontWeightBold } from '../../utils/style-utils'
import UrlLink from '../UrlLink'

export const StyledUrlLink = styled(UrlLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span {
    display: block;
    font-size: 0.8rem;
    font-weight: ${fontWeightBold};
    text-transform: uppercase;
    line-height: 1em;
    margin-top: 0.5em;

    transform: translateY(-0.75rem);

    ${transition('transform')}
  }

  & > svg {
    transform: scale(0.5);
    ${transition('all', 0.6, animationBouncingEase)}
  }

  &:hover {
    & > svg {
      transform: scale(0.6);
    }
  }
`
