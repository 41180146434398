import { css } from 'styled-components'

import { bh, brandPrimary, fontWeightMedium, media } from '../../utils/style-utils'
import H from './index'

export const styles = css`
  color: ${brandPrimary};
  ${H}
  font-size: ${bh * 1.875}px;
  font-weight: ${fontWeightMedium};

  ${media.touch`
    font-size: 1.8rem;
  `}
`
