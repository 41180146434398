import { LANG } from '../App/constants'

export const THREAD_STATE_KEY = 'thread'
export const NEW_MESSAGE_STATE_KEY = 'newMessage'
export const REPLY_FORM_NAME = 'fos_message_new_thread'

export const CONTENT_LOAD = 'Casino/MessagesThread/LOAD'
export const CONTENT_LOAD_SUCCESS = 'Casino/MessagesThread/LOAD_SUCCESS'
export const CONTENT_LOAD_ERROR = 'Casino/MessagesThread/LOAD_ERROR'

export const PLAYER_MESSAGES_ADDRESS = `/${LANG}/message`

export const THREAD_TYPE_NEWSLETTER = 3
