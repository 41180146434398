export const PAYMENT_DETAILS_STATE_KEY = 'PaymentDetails'

export const PAYMENT_STATUS_FINISHED = 'finished'
export const PAYMENT_STATUS_PENDING = 'pending'
export const PAYMENT_STATUS_CONFIRMED = 'confirmed'
export const PAYMENT_STATUS_FAILED = 'failed'

export const CONTENT_LOAD = 'Casino/PaymentDetails/LOAD'
export const CONTENT_LOAD_SUCCESS = 'Casino/PaymentDetails/LOAD_SUCCESS'
export const CONTENT_LOAD_ERROR = 'Casino/PaymentDetails/LOAD_ERROR'
