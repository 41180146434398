import React from 'react'

import H1 from 'components/H/H1'

import Hr from '../Hr'

export function Skeleton() {
  return (
    <div className="skeleton">
      <H1 skeleton />

      <Hr />
    </div>
  )
}
