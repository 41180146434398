import React from 'react'

import PropTypes from 'prop-types'

import DefaultLi from './Li'
import DefaultUl from './Ul'

function List(props) {
  const { items, Ul, Li } = props

  const content =
    items &&
    items.map((item, index) => (
      <Li
        key={`item-${index}`}
        item={item}
        {...props}
      />
    ))

  return <Ul {...props}>{content}</Ul>
}

List.propTypes = {
  items: PropTypes.object,
  Ul: PropTypes.any,
  Li: PropTypes.func,
}

List.defaultProps = {
  Li: DefaultLi,
  Ul: DefaultUl,
}

export default List
