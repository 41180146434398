import React from 'react'

import { money as toMoney } from '../../utils/numbers'

interface Props {
  credits?: number | string | null
  money?: number | string | null
}

function Money({ credits, money, ...rest }: Props) {
  const value = credits ? toMoney(credits, true) : toMoney(money, false)

  return <span {...rest}>{value}</span>
}

export default Money
