import FontFaceObserver from 'fontfaceobserver'

import { extraFontFamily } from './style-utils'

const fontObserver = new FontFaceObserver(extraFontFamily, {})
fontObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  },
)
