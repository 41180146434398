import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import { mainHeaderColor, transition, white } from 'utils/style-utils'

export const MainMenuBox = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

export const StyledUrlLink = styled(UrlLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;

  color: ${mainHeaderColor};
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 800;

  ${transition('all', 0.6)};

  &:hover {
    color: ${white};
    text-shadow:
      0 0 4px ${white},
      0 0 16px ${white},
      0 0 32px ${white},
      0 0 64px ${white};
  }
`
