import styled from 'styled-components'

import { brandPrimary } from '../../../utils/style-utils'
import FlexBoxGrid from '../../FlexBoxGrid'

export const AccountRowWrapper = styled(FlexBoxGrid)`
  ${(p) =>
    p.isSum
      ? `
    color: ${brandPrimary};
  `
      : ''}
`
