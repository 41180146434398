import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { bh, brandSecondary, media, fontWeightBold } from '../../utils/style-utils'
import Money from '../Money'

const StyledMoney = styled(Money)`
  cursor: pointer;
  display: block;
  margin-top: -${bh / 4}px;
  color: ${brandSecondary};
  font-weight: ${fontWeightBold};

  ${media.md`
    margin-top: -${bh / 2}px;
    font-size: ${bh * 0.75}px;
  `}
`

function BalanceStatus({ money }) {
  return (
    <StyledMoney
      className="user-balance-status"
      money={money}
    />
  )
}

BalanceStatus.propTypes = {
  money: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default BalanceStatus
