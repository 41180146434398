import styled, { keyframes } from 'styled-components'

import Info from './Info'

const fadeIn = keyframes`
  0%   { opacity: 0; }
  20%  { opacity: 0; }
  100% { opacity: 1; }
`

const InfoAnimateIn = styled(Info)`
  animation: ${fadeIn} 1s linear;
`

export default InfoAnimateIn
