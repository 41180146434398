import styled from 'styled-components'

import { bh, media } from '../../utils/style-utils'

export default styled.div`
  position: fixed;
  min-width: 60%;
  top: ${bh * 2}px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 200;

  ${media.sm`
    width: 90%;
    top: 5%;
    top: 5vw;
  `}
`
