import { getCachedFn } from './cache'

/**
 * Gets scale from two sizes
 * @param {{width: number, height: number}} size1
 * @param {{width: number, height: number}} size2
 * @return {Number[]}
 */
export function getScaleFromWidths(size1, size2) {
  const scaleX = size1.width / size2.width
  const scaleY = size1.height / size2.height

  return [scaleX, scaleY]
}

/**
 * Generate transform3D from props
 * @param {{x: number, y: number, z: number, scale: number[]}} props
 * @return {{transform: string}} css string
 */
export function styleTransform({ x, y, z, scale }) {
  return {
    transform: scale
      ? `translate3D(${x || 0}px, ${y || 0}px, ${z || 0}px) scale(${scale[0]}, ${scale[1]})`
      : `translate3D(${x || 0}px, ${y || 0}px, ${z || 0}px)`,
  }
}

/**
 * Get fill 6 characters hex
 * @param {string} hex
 * @return {string} full hex
 */
function fullHex(hex) {
  return hex.length === 4 // WHEN WE HAVE SHORT HASH (example #000)
    ? hex
        .split('')
        .map((d) => (d === '#' ? d : `${d}${d}`))
        .join('')
    : hex // WHEN WE HAVE FULL HASH (example #000000)
}

/**
 * Generate RGB array from hex string
 * @param {string} hex
 * @return {number[]} RGB Array [R, G, B]
 */
export function hexToRgb(hex) {
  if (typeof hex !== 'string') {
    return [0, 0, 0]
  }

  const hexWithoutHash = fullHex(hex).replace('#', '').toLowerCase()

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexWithoutHash)

  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0]
}

function hexRgbaFn(hex, alpha) {
  if (alpha === 1) {
    return hex
  }
  return `rgba(${hexToRgb(hex).join(', ')}, ${alpha})`
}

function shadeFn(clr, percent) {
  const f = parseInt(fullHex(clr).slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16 // eslint-disable-line no-bitwise
  const G = (f >> 8) & 0x00ff // eslint-disable-line no-bitwise
  const B = f & 0x0000ff // eslint-disable-line no-bitwise
  const RStr = (Math.round((t - R) * p) + R) * 0x10000
  const GStr = (Math.round((t - G) * p) + G) * 0x100
  const BStr = Math.round((t - B) * p) + B

  return `#${(0x1000000 + RStr + GStr + BStr).toString(16).slice(1)}`
}

export function darken(clr, percent) {
  return shade(clr, -1 * percent)
}
export function lighten(clr, percent) {
  return shade(clr, percent)
}

export function hexRgba(...args) {
  return getCachedFn(hexRgbaFn)(...args)
}

function shade(...args) {
  return getCachedFn(shadeFn)(...args)
}
