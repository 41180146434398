import React, { PropsWithChildren } from 'react'

import { NewLayout } from 'components/Layout/New'
import OldLayout from 'components/Layout/Old'

import { useIsNewLayout } from '../App/NewLayout/useIsNewLayout'
import ErrorPage from '../ErrorPage'
import { useSelectError } from './selectors'

export function MainLayout({ children }: PropsWithChildren) {
  const error = useSelectError()
  const isNewLayout = useIsNewLayout()

  if (error) {
    return <ErrorPage error={error} />
  }

  if (isNewLayout) {
    return <NewLayout>{children}</NewLayout>
  }

  return <OldLayout>{children}</OldLayout>
}
