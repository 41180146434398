import {
  TOGGLE_USER_MENU,
  SEND_GAME_FINISH,
  SEND_GAME_FINISH_SUCCESS,
  SEND_GAME_FINISH_ERROR,
} from '../MainLayout/constants'

export function toggleUserMenu() {
  return {
    type: TOGGLE_USER_MENU,
  }
}

export function sendGameFinish(url: string) {
  return {
    type: SEND_GAME_FINISH,
    url,
  }
}

export function gameFinishSuccess(data: any) {
  return {
    type: SEND_GAME_FINISH_SUCCESS,
    data,
  }
}

export function gameFinishError(error: Error) {
  return {
    type: SEND_GAME_FINISH_ERROR,
    error,
  }
}

export type SendGameFinishAction = ReturnType<typeof sendGameFinish>
