import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import Container from '../Container'

export default styled(Container)`
  display: flex;
  position: relative;

  ${media.md`
    flex-direction: column;
    width: 100%;
  `}
`
