import React from 'react'

import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { bh, loaderColor } from '../../utils/style-utils'
import Spinner from '../Icons/Spinner'
import BaseBg from '../Overlay/Bg'

const fadeIn = keyframes`
  0%   { opacity: 0; }
  40%  { opacity: 0; }
  100% { opacity: 0.5; }
`
const Bg = styled(BaseBg)`
  animation: 0.6s ${fadeIn} linear;
  opacity: 0.5;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const StyledCenteredLoader = styled(Spinner)`
  height: ${(p) => (p.sm ? bh * 2 : bh * 5)}px;
  width: ${(p) => (p.sm ? bh * 2 : bh * 5)}px;

  & > path {
    fill: ${loaderColor};
  }
`

function CenteredLoader({ bg, ...rest }: any) {
  return (
    <Wrapper>
      <LoaderWrapper
        data-testid="centered-loader"
        className="loader-container"
        {...rest}
      >
        <StyledCenteredLoader {...rest} />
      </LoaderWrapper>
      {bg && <Bg />}
    </Wrapper>
  )
}

CenteredLoader.propTypes = {
  bg: PropTypes.bool,
}

CenteredLoader.defaultProps = {
  bg: true,
}

export default CenteredLoader
