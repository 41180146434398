/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

export const initialState = fromJS({
  loading: undefined,
  error: false,
  playerBetsWins: {},
})

function contactUsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state.set('loading', true).set('error', false).set('playerBetsWins', initialState.get('playerBetsWins'))

    case CONTENT_LOAD_SUCCESS:
      return state.set('loading', false).set('error', false).set('playerBetsWins', fromJS(action.data.playerBetsWins))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default contactUsReducer
