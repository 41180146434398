import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import Hr from '../../Hr'
import LightBox from '../../LightBox/Loadable'
import Img from './Img'
import Youtube from './Youtube'

const Wrapper = styled.div``

const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

class CmsPageGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      startWithId: 0,
    }
  }

  open = (id) => {
    this.setState({ isOpen: true, startWithId: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { gallery } = this.props

    if (!gallery || gallery.size === 0) {
      return null
    }

    const { isOpen, startWithId } = this.state

    const images = gallery.filter((media) => media.get('type') === 'image')
    const movies = gallery.filter((media) => media.get('type') === 'youtube')

    if (!gallery || gallery.size === 0) {
      return null
    }

    return (
      <Wrapper>
        <Images>
          {images.map((media, id) => (
            <Img
              key={media.get('id')}
              media={media}
              onClick={() => this.open(id)}
            />
          ))}
        </Images>

        {movies.size && images.size ? <Hr /> : null}

        {movies.map((media) => (
          <Youtube
            key={media.get('id')}
            media={media}
          />
        ))}

        {isOpen && (
          <LightBox
            images={images}
            startWithId={startWithId}
            isOpen={isOpen}
            close={this.close}
          />
        )}
      </Wrapper>
    )
  }
}

CmsPageGallery.propTypes = {
  gallery: ImmutablePropTypes.list,
}

export default CmsPageGallery
