import { type IconKey } from 'components/IconLink/iconMap'
import { array, string, object, bool, number, mixed } from 'yup'

const gameInCategorySchema = object({
  position: number().required(),
  size: number().required(),
  slug: string().required(),
  topRankPosition: number().required(),
})

export const categorySchema = object({
  id: string().required(),
  icon: mixed<IconKey>().required(),
  isActive: bool().required(),
  isProvider: bool().default(false),
  games: array().of(gameInCategorySchema).required(),
  label: string().required(),
  rel: string().required(),
  rows: number().required(),
  slug: string().required(),
  uri: string().required(),
})

export const categoriesSchema = array().of(categorySchema.required()).required()
