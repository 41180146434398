import React from 'react'

import styled from 'styled-components'

import { grayDarker } from '../../utils/style-utils'
import DotLoader from './DotLoader'

interface Props {
  height?: string
  width?: string
}

const LoaderWrapper = styled.div<Props>`
  ${(props) => (props.width ? `width: ${props.width}` : '')};
  ${(props) => (props.height ? `height: ${props.height}` : '')};

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: ${grayDarker};
`

export default function InlineCenterLoader(props: Props) {
  return (
    <LoaderWrapper
      data-testid="inline-centered-loader"
      className="inline-loader-container"
      {...props}
    >
      <DotLoader />
    </LoaderWrapper>
  )
}
