import * as React from 'react'

import { IconProps } from '../types'

function UserAdd(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM5.5 7.5a6.5 6.5 0 1 1 10.463 5.152c.543.195 1.05.428 1.525.694l-.976 1.745C15.336 14.432 13.858 14 12 14c-4.76 0-7.297 3.263-8.422 6.103a.556.556 0 0 0 .08.58.887.887 0 0 0 .703.317H14v2H4.36c-1.778 0-3.414-1.684-2.642-3.634.964-2.432 2.944-5.363 6.37-6.674A6.49 6.49 0 0 1 5.5 7.5ZM15 20c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4Zm5 3v-2h2v-2h-2v-2h-2v2h-2v2h2v2h2Z"
      />
    </svg>
  )
}
export default UserAdd
