import React from 'react'

import { UserType } from 'types/User'

import { Anonymous } from './Anonymous'
import { Player } from './Player'
import { Staff } from './Staff'

export const userComponentMap = {
  [UserType.unknown]: () => <></>,
  [UserType.anonymous]: Anonymous,
  [UserType.player]: Player,
  [UserType.staff]: Staff,
}
