export function getMaxLengthNormalizer(maxlength) {
  return (value, prevValue) => {
    if (value === '') {
      return value
    }

    if (value.length > maxlength) {
      if (value.length - prevValue.length > 3) {
        return value.substring(0, maxlength)
      }
      return prevValue
    }

    return value
  }
}
