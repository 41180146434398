import * as React from 'react'

import { IconProps } from '../types'

function GamesSeven(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6.444a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v11.111a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6.445Zm2 1h4.667v9.111H3v-9.11Zm6.667 0v9.111h4.666v-9.11H9.667ZM21 16.555h-4.667v-9.11H21v9.11Zm-.246-7.527h-3.949v1.86h1.5v-.36h.497l-1.23 4.612 1.449.387 1.733-6.5Zm-10.615 0h3.948l-1.733 6.499-1.449-.387 1.23-4.612h-.496v.36h-1.5v-1.86Zm-2.718 0H3.472v1.86h1.5v-.36h.496l-1.23 4.612 1.45.387 1.733-6.5Z"
      />
    </svg>
  )
}
export default GamesSeven
