import React from 'react'

import styled from 'styled-components'

import CenteredLoader from './CenteredLoader'

export default function MidCenteredLoader(props) {
  return (
    <Wrapper>
      <CenteredLoader {...props} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  min-width: 320px;
  min-height: 180px;
`
