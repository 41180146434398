import React, { PropsWithChildren } from 'react'
import { connect } from 'react-redux'

import AnimatedLoader from 'components/Loader/AnimatedLoader'
import { MainFooterBox } from 'containers/MainFooterBox'
import MainHeaderBox from 'containers/MainHeaderBox'
import MobileMenuBox from 'containers/MobileMenuBox'
import SecondaryHeaderBox from 'containers/SecondaryHeaderBox'
import { createStructuredSelector } from 'reselect'

import { windowClicked } from '../../../containers/MainLayout/actions'
import {
  makeSelectChunkLoading,
  makeSelectFirstLoading,
  makeSelectLoading,
} from '../../../containers/MainLayout/selectors'
import Header from '../../Header'
import HeaderBox from '../../Header/HeaderBox'

interface Props {
  error?: { code: number; message: string }
  loading: boolean
  firstLoad: boolean
  chunkLoading: boolean
  windowClicked: () => void
}

function OldLayout({
  children,
  loading,
  firstLoad,
  chunkLoading,
  windowClicked: onWindowClick,
}: PropsWithChildren<Props>) {
  return (
    <div onClick={onWindowClick}>
      <AnimatedLoader shown={loading === true && firstLoad === true} />
      <AnimatedLoader
        className="chunk-loader"
        shown={chunkLoading === true}
      />
      <HeaderBox>
        <Header>
          <MainHeaderBox />
          <SecondaryHeaderBox />
          <MobileMenuBox />
        </Header>
      </HeaderBox>

      {children}

      <MainFooterBox />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  firstLoad: makeSelectFirstLoading(),
  chunkLoading: makeSelectChunkLoading(),
})

const mapDispatchToProps = {
  windowClicked,
}

export default connect(mapStateToProps, mapDispatchToProps)(OldLayout)
