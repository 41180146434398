/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR, FORM_NAME } from './constants'

// The initial state of the App
export const initialState = fromJS({
  loading: undefined,
  error: undefined,
  [FORM_NAME]: undefined,
  dataToBeGranted: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set(FORM_NAME, initialState.get(FORM_NAME))
        .set('dataToBeGranted', initialState.get('dataToBeGranted'))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(FORM_NAME, fromJS(action.data[FORM_NAME]))
        .set('dataToBeGranted', fromJS(action.data.dataToBeGranted))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
