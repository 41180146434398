import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

export const AnnouncementProp = ImmutablePropTypes.mapContains({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
})

export const AnnouncementsListProp = ImmutablePropTypes.listOf(AnnouncementProp)
