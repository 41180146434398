import React from 'react'

import PlaceAutocomplete from '../../../../../../containers/Autocompletes/PlaceAutocomplete'
import { PlaceDTO } from '../../../../../../containers/Autocompletes/PlaceAutocomplete/types'
import { StreetDTO } from '../../../../../../containers/Autocompletes/StreetAutocomplete/types'
import { useWatchRegistrationField } from '../../../../../../containers/RegisterPage/selectors'
import { Suggestion } from '../../../../../Form/Autocomplete/types'
import { FormProps } from '../../../../../Form/types'
import { STREET_FIELD } from '../utils'
import { useSetTextValuesOnPlaceSelection } from './useSetTextValuesOnPlaceSelection'

function RUIANPlaceAutocomplete(props: FormProps) {
  const street = useWatchRegistrationField<Suggestion<StreetDTO>>(STREET_FIELD)
  const setTextValuesOnPlaceChange = useSetTextValuesOnPlaceSelection()

  return (
    <PlaceAutocomplete
      onCustomChange={((suggestion: Suggestion<PlaceDTO>) => setTextValuesOnPlaceChange(suggestion.entity!)) as any}
      queryParams={{ id: street?.entity?.id || 0, type: street?.entity?.type || '' }}
      isDisabled={!street?.id}
      {...props}
    />
  )
}

export default RUIANPlaceAutocomplete
