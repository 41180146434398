export const TRANSACTION_HISTORY_PAGE_STATE = 'transactionHistory'

export const SET_FILTERED = 'Casino/TransactionHistoryPage/SET_FILTERED'
export const SET_FOCUSED = 'Casino/TransactionHistoryPage/SET_FOCUSED'
export const OPERATION_ID_WIN = 5

export const PAYMENT_HISTORY_TYPES = [
  { value: 'payments', label: 'payments' },
  { value: 'waiting_deposits', label: 'waiting_deposits' },
  { value: 'failed_deposits', label: 'failed_deposits' },
]
export const LIMIT_HISTORY_TYPES = [{ value: 'limits', label: 'Limits' }]
export const GAME_HISTORY_TYPES = [{ value: 'games', label: 'games' }]
export const SESSION_HISTORY_TYPES = [{ value: 'sessions', label: 'Sessions' }]
