import styled from 'styled-components'

import { headerHeight } from '../../styles'

export const TopAppBarBox = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${headerHeight};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`
