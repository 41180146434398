/* eslint-disable no-multi-spaces */
import baseTheme from './betorRedTheme'
import logo from './img/betorplus.svg'

export default {
  ...baseTheme,
  logoHeight: '40%',
  logo,
  footerLogo: logo,
}
