import styled, { css } from 'styled-components'

import { bh, brandSecondary, fontWeightLight, media } from '../../utils/style-utils'
import H from './index'

export const styles = css`
  color: ${brandSecondary};
  ${H}
  font-size: ${bh * 1.75}px;
  font-weight: ${fontWeightLight};

  ${media.touch`
    font-size: 1.6rem;
  `}
`

export default styled.h3`
  ${styles}
`
