import React from 'react'

import PropTypes from 'prop-types'

function Li({ item }) {
  return <li>{item}</li>
}

Li.propTypes = {
  item: PropTypes.object,
}

export default Li
