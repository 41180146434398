import styled from 'styled-components'

import { dangerBg, dangerColor } from '../../utils/style-utils'
import Alert from './index'

export default styled(Alert).attrs({
  alertType: 'danger',
})`
  color: ${dangerColor};
  background: ${dangerBg};

  .alert-icon,
  .message-close svg {
    fill: ${dangerColor};
  }
`
