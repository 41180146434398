export const GOOGLE_SITE_VERIFICATION_META_NAME = 'google-site-verification'

/** @public */
export enum AnalyticsActions {
  login = 'prihlaseni',
  registration = 'registrace',
  temporaryAccount = 'docasne_konto',
}

export enum AnalyticsEventCategory {
  click = 'click',
  page = 'page',
}

export enum AnalyticsConsentAction {
  default = 'default',
  update = 'update',
}

export enum AnalyticsConsent {
  granted = 'granted',
  denied = 'denied',
}
