/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import {
  CONTENT_LOAD,
  CONTENT_LOAD_SUCCESS,
  CONTENT_LOAD_ERROR,
  FORM_EDIT_PROFILE_NAME,
  INFORMATIONS_PATH_STATE_KEY,
} from './constants'

const initialState = fromJS({
  loading: undefined,
  error: false,
  formType: undefined,
  [FORM_EDIT_PROFILE_NAME]: {
    fields: [],
  },
  [INFORMATIONS_PATH_STATE_KEY]: '',
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('formType', undefined)
        .set(FORM_EDIT_PROFILE_NAME, initialState.get(FORM_EDIT_PROFILE_NAME))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('formType', action.data.formType)
        .set(FORM_EDIT_PROFILE_NAME, fromJS(action.data[FORM_EDIT_PROFILE_NAME]))
        .set(INFORMATIONS_PATH_STATE_KEY, fromJS(action.data[INFORMATIONS_PATH_STATE_KEY]))
    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
