import styled from 'styled-components'

import { media, mainHeaderHeightMd } from '../../utils/style-utils'

const HeaderBox = styled.div`
  ${media.md`
    height: ${mainHeaderHeightMd}px;
  `}
`

export default HeaderBox
