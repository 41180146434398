import React, { lazy, Suspense } from 'react'

const Component = lazy(() => import(/* webpackChunkName: "CustomReactQueryDevtools" */ './CustomReactQueryDevtools'))

export default function LoadableReactQueryDevtools() {
  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}
