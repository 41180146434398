import styled from 'styled-components'

import { media, secondaryHeaderBg, secondaryHeaderHeight } from '../../utils/style-utils'

export default styled.div`
  background: ${secondaryHeaderBg};

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: ${secondaryHeaderHeight}px;
  }

  ${media.md`
    display: none;
  `}
`
