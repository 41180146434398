import Translator from 'bazinga-translator'

import {
  CONTENT_LOAD,
  CONTENT_LOAD_SUCCESS,
  CONTENT_LOAD_ERROR,
  DELETE_PAYMENT_METHOD_START,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_START,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  TOGGLE_NEW_CREDIT_CARD_FORM,
  TOGGLE_NEW_BANK_ACCOUNT_FORM,
  TOGGLE_FORM,
  SHOW_FORM,
  HIDE_FORM,
  SELECT_PAYMENT_METHOD,
  BANK_ACCOUNT_ADDED,
  ITEM_VERIFIED,
  WITHDRAW_SUCCESS,
} from './constants'

export function load(pathname) {
  return {
    type: CONTENT_LOAD,
    pathname,
  }
}

export function loadSuccess(data) {
  return {
    type: CONTENT_LOAD_SUCCESS,
    data,
  }
}

export function loadError(error) {
  return {
    type: CONTENT_LOAD_ERROR,
    error,
  }
}

export function deletePaymentMethod(data) {
  return {
    type: DELETE_PAYMENT_METHOD_START,
    data,
  }
}

export function deletePaymentMethodSuccess(data, flashMessages) {
  return {
    type: DELETE_PAYMENT_METHOD_SUCCESS,
    data,
    flashMessages,
  }
}

export function deletePaymentMethodError(data) {
  const msg = (data && (data.error || data.data._form)) || Translator.trans('unknown_error', {}, 'errors') // eslint-disable-line no-underscore-dangle
  const flashMessages = [{ type: 'error', text: msg }]
  return {
    type: DELETE_PAYMENT_METHOD_ERROR,
    data,
    flashMessages,
  }
}

export function defaultPaymentMethod(data) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_START,
    data,
  }
}

export function setDefaultPaymentMethodSuccess(data, flashMessages) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
    data,
    flashMessages,
  }
}

export function setDefaultPaymentMethodError(data) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_ERROR,
    data,
  }
}

export function toggleForm(data) {
  return {
    type: TOGGLE_FORM,
    data,
  }
}

export function showForm(data) {
  return {
    type: SHOW_FORM,
    data,
  }
}

export function hideForm(data) {
  return {
    type: HIDE_FORM,
    data,
  }
}

export function toggleNewCardForm() {
  return {
    type: TOGGLE_NEW_CREDIT_CARD_FORM,
  }
}

export function toggleNewBankAccountForm() {
  return {
    type: TOGGLE_NEW_BANK_ACCOUNT_FORM,
  }
}

export function selectPaymentMethod(data) {
  return {
    type: SELECT_PAYMENT_METHOD,
    data,
  }
}

export function bankAccountAdded() {
  return {
    type: BANK_ACCOUNT_ADDED,
  }
}

export function itemVerified(data, flashMessages) {
  return {
    type: ITEM_VERIFIED,
    data,
    flashMessages,
  }
}

export function withdrawSuccess(data, flashMessages) {
  return {
    type: WITHDRAW_SUCCESS,
    data,
    flashMessages,
  }
}
