import React from 'react'

import { MobileConfirmButton } from 'components/MobileConfirm/MobileConfirmButton'
import { AmlFormButton } from 'components/UserHeader/AmlFormButton'
import { FinishRegistrationButton } from 'components/UserHeader/FinishRegistrationButton'
import { VerifyCardButton } from 'components/UserHeader/VerifyCardButton'
import { useUser } from 'containers/MainLayout/selectors'

export function ImportantButtons() {
  const user = useUser()

  const showMobileConfirm = !!user.mobile && !user.isMobileConfirmed
  const showVerifyCard = !showMobileConfirm && user.isCardToVerify
  const showFinishRegistration = !showVerifyCard && !user.fullyActivated
  const showAmlForm = !showFinishRegistration && user.isAMLFillRequired

  return (
    <>
      <AmlFormButton condition={showAmlForm} />
      <FinishRegistrationButton condition={showFinishRegistration} />
      <VerifyCardButton condition={showVerifyCard} />
      <MobileConfirmButton condition={showMobileConfirm} />
    </>
  )
}
