/* eslint-disable react/style-prop-object */
import React from 'react'

export default function Egaming(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="200mm"
      height="200mm"
      version="1.1"
      // style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 20000 20000"
      {...props}
    >
      <g id="Warstwa_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M3529 10815c962,7714 13488,7519 14542,0 -4847,0 -9695,0 -14542,0zm-1 -32l12943 -1598c-401,-3217 -3146,-5707 -6471,-5707 -3167,0 -5807,2258 -6398,5252 -133,674 -167,1372 -74,2053zm6472 -8936l0 0c4502,0 8153,3651 8153,8153 0,4502 -3651,8153 -8153,8153 -4502,0 -8153,-3651 -8153,-8153 0,-4502 3651,-8153 8153,-8153z"
        />
      </g>
    </svg>
  )
}
