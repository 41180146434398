import { AnySchema } from 'yup'
import { ZodType } from 'zod'

import { flashMessagesResponseSchema } from '../../containers/FlashMessagesBox/schema'

export function getQueryFnWithYupValidation<TData>(
  queryFn: () => Promise<unknown>,
  schema: AnySchema<unknown, any, TData>,
) {
  return async () => {
    const data = await queryFn()
    const validatedData = schema.validateSync(data)
    const flashMessages = getFlashMessages(data)

    if (flashMessages) {
      return { ...validatedData, flashMessages }
    }

    return validatedData
  }
}

export function getQueryFnWithZodValidation<TData>(queryFn: () => Promise<unknown>, schema: ZodType<TData>) {
  return async () => {
    const data = await queryFn()

    const validatedData = schema.parse(data)

    const flashMessages = getFlashMessages(data)

    if (flashMessages) {
      return { ...validatedData, flashMessages }
    }

    return validatedData
  }
}

export function getMutateFnWithZodValidation<TRequestBody, TData>(
  queryFn: (data: TRequestBody) => Promise<unknown>,
  requestSchema: ZodType<TRequestBody>,
  responseSchema: ZodType<TData>,
) {
  return async (data: TRequestBody) => {
    const body = requestSchema.parse(data)
    const response = await queryFn(body)

    const validatedData = responseSchema.parse(response)

    const flashMessages = getFlashMessages(data)

    if (flashMessages) {
      return { ...validatedData, flashMessages }
    }

    return validatedData
  }
}

function getFlashMessages(data: unknown) {
  const validatedFlashMessages = flashMessagesResponseSchema.safeParse(data)

  if (validatedFlashMessages.success) {
    const { flashMessages } = validatedFlashMessages.data

    return flashMessages
  }

  return null
}
