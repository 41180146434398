import React from 'react'

import Translator from 'bazinga-translator'
import withCondition from 'components/withCondition'

import { AML_FORM_PATH } from '../../containers/App/constants'
import Button from '../Btn'
import ExclamationTriangle from '../Icons/components/Warning'

function AmlFormButtonComponent({ ...props }) {
  return (
    <Button
      link
      to={AML_FORM_PATH}
      danger
      md
      {...props}
    >
      <ExclamationTriangle />
      {Translator.trans('fill_aml_form', {}, 'users')}
    </Button>
  )
}

export const AmlFormButton = withCondition(AmlFormButtonComponent)
