import React from 'react'

import { HOME_ADDRESS, THEME } from 'containers/App/constants'
import { logoMono } from 'utils/style-utils'
import { scrollToTop } from 'utils/windowHelpers'

import { LogoBox, LogoLink } from './styles'

export function Logo() {
  return (
    <LogoBox>
      <LogoLink
        to={HOME_ADDRESS}
        onClick={scrollToTop}
      >
        <img
          src={logoMono}
          alt={THEME}
        />
      </LogoLink>
    </LogoBox>
  )
}
