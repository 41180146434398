import React from 'react'

import { useSelectUserType } from 'containers/MainLayout/selectors'

import { UserBox } from './styles'
import { userComponentMap } from './utils'

export function User() {
  const userType = useSelectUserType()
  const UserComponent = userComponentMap[userType] || userComponentMap.unknown

  return (
    <UserBox>
      <UserComponent />
    </UserBox>
  )
}
