import { SubmissionError } from 'redux-form'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { request } from '../../utils/request'
import formdata from '../../utils/requestFormdata'
import { AnalyticsActions, AnalyticsEventCategory } from '../Analytics/constants'
import { googleTagEvent } from '../Analytics/utils'
import { SUCCESS_DATA } from '../App/constants'
import { closeAllFlashMessages, initReset } from '../MainLayout/actions'
import { loginFormLoaded, loginFormLoadingError, loginFormLoad as loginFormLoadAction } from './actions'
import { LOGIN_FORM_LOAD_URI, LOGIN_FORM_LOAD, LOGIN_FORM_SEND_URI, SEND_LOGIN_FORM } from './constants'

export function* loginFormLoad() {
  const requestURL = `${LOGIN_FORM_LOAD_URI}?t=${Date.now()}`

  try {
    const data = yield call(request, requestURL)
    if (data.type === SUCCESS_DATA) {
      yield put(loginFormLoaded(data.data, data.flashMessages))
    }
  } catch (err) {
    yield put(loginFormLoadingError(err))
  }
}

export function* loginFormData() {
  yield takeLatest(LOGIN_FORM_LOAD, loginFormLoad)
}

function* loginFormSend(action) {
  const { values, reject } = action
  yield put(closeAllFlashMessages())

  try {
    yield call(formdata, LOGIN_FORM_SEND_URI, values.toJS(), {}, LOGIN_FORM_LOAD_URI)
    googleTagEvent(AnalyticsActions.login, AnalyticsEventCategory.click)

    yield put(initReset())
  } catch (error) {
    yield put(loginFormLoadAction())
    reject(new SubmissionError({ _error: error.data }))
  }
}

function* sendLoginFormData() {
  yield takeLatest(SEND_LOGIN_FORM, loginFormSend)
}

export default function* rootSagas() {
  yield all([loginFormData(), sendLoginFormData()])
}
