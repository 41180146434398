import React from 'react'

import { Icon } from 'components/IconLink/Icon'
import type { MenuItem } from 'types/MenuItem'

import { StyledLink } from './menuStyles'

interface Props {
  item: MenuItem
}

export function MenuItem({ item }: Props) {
  return (
    <StyledLink
      link={item.uri}
      rel={item.rel}
    >
      {item.icon !== 'none' ? <Icon icon={item.icon} /> : item.label}
    </StyledLink>
  )
}
