import styled from 'styled-components'

import { bh, fontWeightBold, media } from '../../utils/style-utils'
import H from './index'

export default styled.h1`
  ${H}
  font-size: ${bh * 2.5}px;
  font-weight: ${fontWeightBold};

  ${media.touch`
    font-size: 2rem;
  `}
`
