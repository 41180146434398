import React from 'react'

import { CityDTO } from '../../../../../../containers/Autocompletes/CityAutocomplete/types'
import StreetAutocomplete from '../../../../../../containers/Autocompletes/StreetAutocomplete'
import { useWatchRegistrationField } from '../../../../../../containers/RegisterPage/selectors'
import { Suggestion } from '../../../../../Form/Autocomplete/types'
import { FormProps } from '../../../../../Form/types'
import { CITY_FIELD } from '../utils'
import { useClearFieldsOnStreetChange } from './useClearFieldsOnStreetChange'

function RUIANStreetAutocomplete(props: FormProps) {
  const city = useWatchRegistrationField<Suggestion<CityDTO>>(CITY_FIELD)
  const clearFieldsOnStreetChange = useClearFieldsOnStreetChange()

  return (
    <StreetAutocomplete
      onCustomChange={() => clearFieldsOnStreetChange()}
      queryParams={{ id: city?.entity?.id || 0, type: city?.entity?.type || '' }}
      isDisabled={!city?.id}
      {...props}
    />
  )
}

export default RUIANStreetAutocomplete
