import React from 'react'

import { HamburgerBox } from './styles'

export function Hamburger() {
  return (
    <HamburgerBox>
      <div />
      <div />
      <div />
    </HamburgerBox>
  )
}
