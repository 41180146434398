import { differenceBy, orderBy } from 'lodash'

import { Membership } from '../../../containers/Tournaments/types/Membership'
import { Tournament } from '../../../containers/Tournaments/types/Tournament'
import { UserMembership } from '../../../containers/Tournaments/types/UserMembership'
import { isServerDateFuture, isServerDatePast } from '../../../utils/dates'
import { MergedTournamentsAndSubscriptionsResult } from './types'

export function mergeTournamentsAndSubscriptions(
  data: Tournament[],
  userMemberships: UserMembership[],
): MergedTournamentsAndSubscriptionsResult {
  const tournamentsWithSubscriptions = orderBy(
    data?.map((tournament) => {
      const userMembership = userMemberships?.find((membership) => membership.tournamentId === tournament.id)

      return {
        ...tournament,
        userMembership,
        isSubscribed: !!userMembership,
      }
    }),
    'startDate.date',
    'asc',
  )

  const futureTournaments =
    tournamentsWithSubscriptions?.filter((tournament) => isServerDateFuture(tournament.startDate.date)) ?? []
  const finishedTournaments =
    orderBy(
      tournamentsWithSubscriptions?.filter((tournament) => isServerDatePast(tournament.finishDate.date)),
      'finishDate.date',
      'desc',
    ) ?? []
  const allTournaments =
    differenceBy(tournamentsWithSubscriptions, [...futureTournaments, ...finishedTournaments], 'id') ?? []
  const tournaments = [
    ...orderBy(
      allTournaments.filter(({ isSubscribed }) => isSubscribed),
      'finishDate.date',
      'asc',
    ),
    ...orderBy(
      allTournaments.filter(({ isSubscribed }) => !isSubscribed),
      'startDate.date',
      'desc',
    ),
  ]

  return { tournaments, futureTournaments, finishedTournaments }
}

export function markCurrentPlayer(memberships: Membership[], subscriptions: UserMembership[]) {
  return memberships.map((membership) => {
    const isCurrentPlayer = subscriptions?.some((subscription) => subscription.membershipId === membership.id)

    return {
      ...membership,
      isCurrentPlayer,
    }
  })
}
