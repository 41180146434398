import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { bh, media } from '../../utils/style-utils'
import DotLoader from '../Loader/DotLoader'
import ImportantButton from './ImportantButton'
import UserDropdown from './UserDropdown'
import UserToggle from './UserToggle'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${bh / 2}px;
  position: relative;

  ${media.md`
    flex-direction: column;
  `}
`

function UserLogged({ user, toggleUserMenu, userMenuOpen, reloading }) {
  if (reloading) {
    return (
      <Wrapper>
        <DotLoader />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <ImportantButton user={user} />

      <UserToggle
        id="logged-user-button"
        toggleUserMenu={toggleUserMenu}
        user={user}
      />

      <UserDropdown
        user={user}
        open={userMenuOpen}
        toggleUserMenu={toggleUserMenu}
      />
    </Wrapper>
  )
}

UserLogged.propTypes = {
  user: PropTypes.object,
  toggleUserMenu: PropTypes.func,
  reloading: PropTypes.bool,
  userMenuOpen: PropTypes.bool,
}

export default UserLogged
