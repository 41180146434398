import React from 'react'
import { useSelector } from 'react-redux'

import Translator from 'bazinga-translator'

import { makeSelectUser } from '../../containers/MainLayout/selectors'
import Button from '../Btn'
import InfoDialog from '../Dialog/InfoDialog'
import { useCancelAllWithdrawals } from '../Queries/Payments/useCancelAllWithdrawals'
import { useIsCancelAllWithdrawalsAvailable } from '../Queries/Payments/useIsCancelAllWithdrawalsAvailable'
import withCondition from '../withCondition'

interface Props {
  buttonProps?: any
}

function CancelAllWithdrawalsButton({ buttonProps }: Props) {
  const user = useSelector(makeSelectUser())?.toJS()
  const { data, isPending: isCancelAvailableLoading, refetch } = useIsCancelAllWithdrawalsAvailable(user?.id)
  const { mutate, error, isPending } = useCancelAllWithdrawals({
    onSettled: () => {
      refetch()
    },
  })

  if (!data?.cancelWithdrawalAvailable || isCancelAvailableLoading) {
    return null
  }

  return (
    <>
      {error?.data && (
        <InfoDialog
          title={Translator.trans('Error')}
          btnLabel={Translator.trans('OK')}
        >
          {Translator.trans(error.data)}
        </InfoDialog>
      )}
      <Button
        className="cancel-all-waiting-withdrawals"
        danger
        sm
        onClick={mutate}
        disabled={isPending}
        {...buttonProps}
      >
        {Translator.trans('cancelPendingWithdrawals', {}, 'payments')}
      </Button>
    </>
  )
}

export default withCondition(CancelAllWithdrawalsButton)
