/* eslint-disable no-useless-constructor, @typescript-eslint/no-empty-function */
import { Centrifuge } from 'centrifuge'

import { WebSocketInstance, AuthorizationData } from '../types'

export class WebSocketEngine {
  private static instance: Promise<WebSocketInstance>

  private constructor() {}

  private static async initializeWebSocket(getAuthorizationData: () => Promise<AuthorizationData>) {
    const { websocketURL, token } = await getAuthorizationData()

    const centrifuge = new Centrifuge(websocketURL, { token })

    centrifuge.on('connecting', (context) => {
      console.debug(`Connecting: ${context.code}, ${context.reason}`)
    })

    centrifuge.on('connected', (context) => {
      console.debug(`Connected over: ${context.transport}`)
    })

    centrifuge.on('disconnected', (context) => {
      console.debug(`Disconnected: ${context.code}, ${context.reason}`)
    })

    centrifuge.connect()

    return centrifuge
  }

  public static async getWebSocketEngine(getAuthorizationData: () => Promise<AuthorizationData>) {
    if (WebSocketEngine.instance === undefined) {
      WebSocketEngine.instance = WebSocketEngine.initializeWebSocket(getAuthorizationData)
    }

    return WebSocketEngine.instance
  }
}
