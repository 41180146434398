import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import { isSelfUrl, redirect } from '../../utils/url'
import H1 from '../H/H1'
import StyledCmsArticle from './StyledCmsArticle'

export function catchHref(e) {
  if (e && e.target) {
    const isA = e.target.nodeName === 'A'
    if (isA) {
      const url = e.target.href
      const selfUrl = isSelfUrl(url)
      if (selfUrl) {
        redirect(url)
        e.preventDefault()
      }
    }
  }
}

function getH1ClassName() {
  return document.location.pathname
    .replace('/', '') // clear first slash
    .replace(/\//g, '-') // replace / with -
}

export default function ContentArticle({ page, contentRef }) {
  return (
    <>
      <H1
        ref={contentRef}
        secondary
        className={getH1ClassName()}
      >
        {page.get('title')}
      </H1>
      <StyledCmsArticle
        onClick={(e) => catchHref(e)}
        dangerouslySetInnerHTML={{ __html: page.get('article') }}
      />
    </>
  )
}

ContentArticle.propTypes = {
  page: ImmutablePropTypes.map.isRequired,
  contentRef: PropTypes.any,
}
