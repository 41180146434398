import { keyframes } from 'styled-components'

export const pulse = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
`
