import React from 'react'

import { usePopover } from 'utils/hooks/usePopover'

import { PlayerPopoverContent } from './Content'
import { Trigger } from './Trigger'
import { PopoverBox } from './styles'

export function PlayerPopover() {
  const { ref, isOpen, closePopover, togglePopover } = usePopover()

  return (
    <PopoverBox ref={ref}>
      <Trigger onClick={togglePopover} />
      <PlayerPopoverContent
        closePopover={closePopover}
        condition={isOpen}
      />
    </PopoverBox>
  )
}
