import styled from 'styled-components'

import { media, mainHeaderBg, mainHeaderHeight, mainHeaderHeightMd } from '../../utils/style-utils'

export default styled.nav`
  height: ${mainHeaderHeight}px;
  background: ${mainHeaderBg};

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${media.md`
    z-index: 10;
    width: 100%;
    height: ${mainHeaderHeightMd}px;

    & > div {
      flex-wrap: wrap;
    }
  `}
`
