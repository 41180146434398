import React from 'react'

import PropTypes from 'prop-types'

import Down from './Down'
import Up from './Up'
import Wrapper from './Wrapper'

function DoubleClaim({ topLabel, bottomLabel, topClick, bottomClick, ...rest }) {
  return (
    <Wrapper {...rest}>
      {topLabel !== '' && (
        <Up
          interactive={!!topClick}
          onClick={topClick}
          len={topLabel.length}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="88"
            viewBox="0 0 572 80"
          >
            <path
              d="M866.45,226.714L1417,221l-46.48,64.074L845,301Z"
              transform="translate(-845 -221)"
            />
          </svg>
          <span>{topLabel}</span>
        </Up>
      )}

      {bottomLabel !== '' && (
        <Down
          interactive={!!bottomClick}
          onClick={bottomClick}
          len={bottomLabel.length}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="101"
            viewBox="0 0 427 91"
          >
            <path
              d="M1332.99,286.5L922,280l34.694,72.884L1349,371Z"
              transform="translate(-922 -280)"
            />
          </svg>
          <span>{bottomLabel}</span>
        </Down>
      )}
    </Wrapper>
  )
}

DoubleClaim.propTypes = {
  topLabel: PropTypes.string,
  bottomLabel: PropTypes.string,
  topClick: PropTypes.func,
  bottomClick: PropTypes.func,
}

DoubleClaim.defaultProps = {
  topLabel: ' ',
  bottomLabel: ' ',
}

export default DoubleClaim
