import React, { PropsWithChildren } from 'react'

import LandingPage from 'components/LandingPage'

import Banner from '../../components/CmsPage/Banner'
import Container from '../../components/CmsPage/Container'
import PageContentBox from './PageContentBox'
import SideBar from './SideBar'
import { useSelectPageData } from './selectors'

export default function CmsPageBox({ children, ...rest }: PropsWithChildren) {
  const pageData = useSelectPageData()

  if (pageData && pageData.get('template') === 'landing') {
    return (
      <LandingPage
        page={pageData}
        background={pageData.get('background')}
      />
    )
  }

  return (
    <>
      <Banner />
      <Container>
        <SideBar />
        <PageContentBox {...rest}>{children}</PageContentBox>
      </Container>
    </>
  )
}
