import { BASE_PATH, LANG } from '../App/constants'

export const MOBILE_CONFIRM_STATE_KEY = 'mobileConfirm'
export const FORM_MOBILE_CONFIRM_NAME = 'formMobileConfirm'
export const RESEND_SMS_PATH = `${BASE_PATH}/${LANG}/register/verify`
export const RESENDING_SMS_STATE_KEY = 'resendingSms'
export const USER_MOBILE_STATE_KEY = 'userMobile'

export const RESEND_SMS = 'Casino/MobileConfirm/RESEND_SMS'
export const RESEND_SMS_SUCCESS = 'Casino/MobileConfirm/RESEND_SMS_SUCCESS'
export const RESEND_SMS_ERROR = 'Casino/MobileConfirm/RESEND_SMS_ERROR'

export const LOAD = 'Casino/MobileConfirm/LOAD'
export const LOAD_SUCCESS = 'Casino/MobileConfirm/LOAD_SUCCESS'
export const LOAD_ERROR = 'Casino/MobileConfirm/LOAD_ERROR'
