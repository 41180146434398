import React from 'react'

import { PlayerPopover } from 'components/Layout/New/PlayerPopover'
import { Hide } from 'components/Media/Hide'
import { Show } from 'components/Media/Show'

import { ImportantButtons } from './ImportantButtons'
import { Warning } from './Warning'

export function Player() {
  return (
    <>
      <Hide on="sm">
        <ImportantButtons />
      </Hide>

      <Show on="sm">
        <Warning />
      </Show>

      <PlayerPopover />
    </>
  )
}
