import { array, string, object, number } from 'yup'

export const placeValidator = object({
  type: string().required(),
  id: number().required(),
  name: string().required(),
  town: string().required(),
  street: string().required(),
  zip: string().required(),
})

export const placeArrayValidator = array().of(placeValidator).required()
