import styled from 'styled-components'

import { transition, black } from '../../utils/style-utils'

const Wrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  pointer-events: all;

  background-color: ${black};

  transform-origin: top left;

  z-index: 11;
  ${transition('transform', 0.3)};
`

export default Wrapper
