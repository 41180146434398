import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import Info from '../Alert/Info'
import { typeHandler } from './constants'
import { TextWrapper, XWrapper, StyledX } from './styledComponents'

class Message extends React.PureComponent {
  static propTypes = {
    message: ImmutablePropTypes.mapContains({
      text: PropTypes.string,
    }).isRequired,
    close: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { message, close } = this.props
    const { type } = message.toJS()
    if (type !== 'permanent') {
      setTimeout(() => close(message.get('text')), 15000)
    }
  }

  getWrapper = (type) => typeHandler[type] || Info

  renderContent = (text) => {
    if (text.indexOf('<') > -1) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: text }} />
    } else {
      return Translator.trans(text)
    }
  }

  render() {
    const { message, close } = this.props
    const { type, text } = message.toJS()
    const Wrapper = this.getWrapper(type)

    return (
      <Wrapper
        sm
        className="flash-message"
      >
        <TextWrapper>{this.renderContent(text)}</TextWrapper>
        <XWrapper
          className="message-close"
          onClick={() => close(text)}
        >
          <StyledX />
        </XWrapper>
      </Wrapper>
    )
  }
}

export default Message
