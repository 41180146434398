import * as React from 'react'

import { IconProps } from '../types'

function Award2(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.743 2H2v7.5A4.5 4.5 0 0 0 6.5 14v-.053l.168.728A3 3 0 0 0 9.59 17H11v3H7v2h10v-2h-4v-3h1.409a3 3 0 0 0 2.923-2.325l.161-.699A5 5 0 0 0 22 9V2H3.743ZM20 4h-.204l-1.808 7.834A3.001 3.001 0 0 0 20 9V4ZM4 9.5V4h.204l1.837 7.958A2.5 2.5 0 0 1 4 9.5ZM17.743 4H6.257l2.36 10.225A1 1 0 0 0 9.59 15h4.818a1 1 0 0 0 .974-.775L17.743 4Z"
      />
    </svg>
  )
}
export default Award2
