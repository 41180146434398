import * as React from 'react'

import { IconProps } from '../types'

function Grid(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.952 1C1.426 1 1 1.448 1 2v8c0 .552.426 1 .952 1h8.096c.526 0 .952-.448.952-1V2c0-.552-.426-1-.952-1H1.952Zm.953 8V3h6.19v6h-6.19Zm-.953 4C1.426 13 1 13.448 1 14v8c0 .552.426 1 .952 1h8.096c.526 0 .952-.448.952-1v-8c0-.552-.426-1-.952-1H1.952Zm.953 8v-6h6.19v6h-6.19ZM13 2c0-.552.426-1 .952-1h8.096c.526 0 .952.448.952 1v8c0 .552-.426 1-.952 1h-8.096c-.526 0-.952-.448-.952-1V2Zm1.905 1v6h6.19V3h-6.19Zm-.953 10c-.526 0-.952.448-.952 1v8c0 .552.426 1 .952 1h8.096c.526 0 .952-.448.952-1v-8c0-.552-.426-1-.952-1h-8.096Zm.953 8v-6h6.19v6h-6.19Z"
      />
    </svg>
  )
}
export default Grid
