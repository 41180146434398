import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { COOKIE_LAW_CONFIRM_KEY } from '../../containers/App/constants'
import { cookieConsentsChange } from '../../containers/MainLayout/actions'
import { isCookieSet } from '../../utils/cookies'
import { isKioskCached } from '../../utils/device'
import { CookieDialog } from './CookieDialog'
import { SimpleCookieDialog } from './SimpleCookieDialog'
import { Consents, setConsentsCookie } from './utils'

function CookieInfo() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(!isKioskCached() && !isCookieSet(COOKIE_LAW_CONFIRM_KEY))
  const [showShort, setShowShort] = useState(true)
  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowShort((value) => !value)
    setShowMore((value) => !value)
  }
  const agree = (props: Consents) => {
    setConsentsCookie(props)
    setShow(false)
    dispatch(cookieConsentsChange())
  }
  const agreeAll = () => {
    agree({ marketing: true, functional: true, statistical: true, unclassified: true })
  }
  const agreeNecessary = () => {
    agree({ marketing: false, functional: false, statistical: false, unclassified: false })
  }

  if (!show) {
    return null
  }

  return (
    <>
      <SimpleCookieDialog
        showShort={showShort}
        toggleShowMore={toggleShowMore}
        agreeAll={agreeAll}
      />
      <CookieDialog
        agree={agree}
        showMore={showMore}
        toggleShowMore={toggleShowMore}
        agreeNecessary={agreeNecessary}
      />
    </>
  )
}

export default CookieInfo
