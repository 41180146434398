import { fromJS } from 'immutable'

export default function insertCaptchaToStateForm(state, formKey = 'form') {
  const formName = state.getIn([formKey, 'name'])
  return state.updateIn([formKey, 'fields'], (arr) =>
    arr.push(
      fromJS({
        formName,
        expanded: null,
        id: `${formName}_captcha`,
        label: '',
        name: `${formName}[captcha]`,
        type: 'captcha',
        value: '',
      }),
    ),
  )
}
