import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Message from './Message'
import Wrapper from './Wrapper'

function FlashMessages({ flashMessages, close }) {
  return (
    <Wrapper>
      {flashMessages.map((message) => (
        <Message
          key={message}
          message={message}
          close={close}
        />
      ))}
    </Wrapper>
  )
}

FlashMessages.propTypes = {
  flashMessages: ImmutablePropTypes.list.isRequired,
  close: PropTypes.func.isRequired,
}

export default FlashMessages
