import styled, { css } from 'styled-components'

import { lighten, darken } from '../../utils/style-fns'
import {
  bh,
  cmsContentBorder,
  cmsContentSidebarActiveBg,
  cmsContentSidebarActiveColor,
  cmsContentSidebarBg,
  cmsContentSidebar3DeepBg,
  cmsContentSidebarColor,
  fontWeightMedium,
  transition,
} from '../../utils/style-utils'
import Link from '../Link'

export default styled(Link)`
  width: 100%;
  display: block;
  position: relative;
  padding: ${bh / 1.5}px ${bh}px;
  padding-left: ${(props) => props.nestedLevel * bh}px;
  color: ${cmsContentSidebarColor};
  border-bottom: solid 1px ${cmsContentBorder};

  ${transition('background-color', 0.2)}

  &:hover {
    background-color: ${(p) =>
      p.nestedLevel < 2 ? lighten(cmsContentSidebarBg, 0.1) : darken(cmsContentSidebar3DeepBg, 0.4)};
  }

  ${(p) => (p.isHeader ? isHeaderCss : null)}
  ${(p) => (p.isActive ? isActiveCss : null)}
`

const isActiveCss = css`
  &,
  &:active,
  &:focus {
    background: ${cmsContentSidebarActiveBg};
    color: ${cmsContentSidebarActiveColor};
  }

  pointer-events: none;
  cursor: default;
`

const isHeaderCss = css`
  text-transform: uppercase;
  font-weight: ${fontWeightMedium};
`
