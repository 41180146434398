import Translator from 'bazinga-translator'

export enum TournamentReward {
  cashable = 'cashable',
  nonCashable = 'nonCashable',
  freeRounds = 'freeRounds',
}

export enum TournamentRewardToId {
  cashable = 1,
  nonCashable = 2,
  freeRounds = 3,
}

export const TournamentRewardMap = [
  {
    id: TournamentRewardToId.cashable,
    name: TournamentReward.cashable,
    value: Translator.trans('cashable_reward_type_label', {}, 'tournaments'),
  },
  {
    id: TournamentRewardToId.nonCashable,
    name: TournamentReward.nonCashable,
    value: Translator.trans('non_cashable_reward_type_label', {}, 'tournaments'),
  },
  {
    id: TournamentRewardToId.freeRounds,
    name: TournamentReward.freeRounds,
    value: Translator.trans('free_rounds_reward_type_label', {}, 'tournaments'),
  },
]
