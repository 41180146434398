import styled from 'styled-components'

import { bh } from '../../utils/style-utils'
import CenteredLoader from './CenteredLoader'

const SmallCenteredLoader = styled(CenteredLoader).attrs({
  bg: false,
})`
  height: ${(p) => (p.xl ? bh * 4 : bh * 2)}px;
  width: ${(p) => (p.xl ? bh * 4 : bh * 2)}px;
`

export default SmallCenteredLoader
