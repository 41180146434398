import React from 'react'
import { Link } from 'react-router-dom'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { LOGIN_ADDRESS, REGISTER_CHOICE_ADDRESS } from '../../containers/LoginPage/constants'
import { bh, mainHeaderColor, media } from '../../utils/style-utils'
import { getRegisterAddress } from '../../utils/url'
import User from '../Icons/components/User'
import UserRegister from '../Icons/components/UserAdd'
import DotLoader from '../Loader/DotLoader'
import { Hide } from '../Media/Hide'
import { Show } from '../Media/Show'
import Login from './Login'
import Register from './Register'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${bh / 2}px;
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${bh}px;

  span {
    color: ${mainHeaderColor};
    font-size: 0.875rem;
  }

  ${media.md`
    top: ${(props) => (props.user ? `${bh / 3}px` : null)};
  `}
`

const UserIcon = styled(User)`
  width: 25px;
  height: 25px;
  fill: ${mainHeaderColor};
  cursor: pointer;
`

const UserRegisterIcon = styled(UserRegister)`
  width: 25px;
  height: 25px;
  fill: ${mainHeaderColor};
  cursor: pointer;
`

function UserUnlogged({ reloading }) {
  if (reloading) {
    return (
      <Wrapper>
        <DotLoader />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Show on="md">
        <StyledLink
          id="login-user-button-mobile"
          to={LOGIN_ADDRESS}
        >
          <UserIcon />
          <span>{Translator.trans('Log in')}</span>
        </StyledLink>

        <StyledLink
          id="register-user-button-mobile"
          to={REGISTER_CHOICE_ADDRESS}
        >
          <UserRegisterIcon />
          <span>{Translator.trans('Register')}</span>
        </StyledLink>
      </Show>

      <Hide on="md">
        <Login
          id="login-user-button"
          to={LOGIN_ADDRESS}
          style={{ marginRight: bh / 2 }}
        />
        <Register
          id="register-user-button"
          to={getRegisterAddress()}
        />
      </Hide>
    </Wrapper>
  )
}

UserUnlogged.propTypes = {
  reloading: PropTypes.bool,
}

export default UserUnlogged
