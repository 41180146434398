import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  bh,
  media,
  white,
  grayDarker,
  grayDark,
  transition,
  grayLighter,
  brandSecondary,
} from '../../utils/style-utils'
import FlagIcon from '../FlagIcon'

const StyledLi = styled.li`
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  padding: 15px 0;
  white-space: nowrap;
  ${transition('background', 0.2)};
  background: ${grayDarker};

  &:last-child {
    border: none;
  }

  &:hover {
    background: ${grayDark};
    > a {
      text-decoration: underline;
    }
  }

  ${media.md`
    height: 40px;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid ${grayLighter};
    padding-left: 40px;
    padding-right: 40px;

    &:last-child {
      border-bottom: 1px solid ${grayLighter};
    }

    &:hover {
      background: ${grayDarker};

      > a {
        text-decoration: none;
        color: ${brandSecondary};
      }
    }
  `}
`

const StyledA = styled.a`
  display: block;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0 10px;

  &,
  &:hover,
  &:focus {
    color: ${white};
  }

  > span {
    vertical-align: middle;
    font-size: ${bh * 0.75}px;
  }

  > svg {
    width: 20px;
    height: 10px;
    vertical-align: middle;
    margin-right: 5px;
  }

  ${media.md`
    display: flex;
    justify-content: space-between;
    padding: 0;

    > span {
      font-size: 18px;
    }

    > svg {
      order: 1;
    }
  `}
`

function Li({ item }) {
  return (
    <StyledLi>
      <StyledA
        href={item.get('url')}
        rel={item.get('rel')}
      >
        <FlagIcon flag={item.get('lang')} />
        <span>{item.get('label')}</span>
      </StyledA>
    </StyledLi>
  )
}

Li.propTypes = {
  item: PropTypes.object,
}

export default Li
