import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { transition, white } from 'utils/style-utils'

export const LogoBox = styled.div`
  height: 100%;
`

export const LogoLink = styled(Link)`
  height: 100%;
  padding: 0 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 2rem;
    display: block;

    ${transition('filter', 0.6)};
  }

  &:hover {
    img {
      filter: drop-shadow(0 0 16px ${white});
    }
  }
`
