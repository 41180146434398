function genericRecursive(
  data,
  id,
  result,
  callback,
  parent,
  parentIndex,
  conditionClause = (value, identifier) => value && value.get('id') === identifier,
) {
  data.forEach((element, index) => {
    if (conditionClause(element, id)) {
      callback(result, element, parent, index, parentIndex)
    }

    if (element && element.get('children').size > 0) {
      genericRecursive(element.get('children'), id, result, callback, element, index, conditionClause)
    }
  })
}

export function searchDeepForNodeById(structure, elementId) {
  const node = []
  const callback = (result, element, parent, index) => result.push({ element, index })

  genericRecursive(structure, elementId, node, callback)

  return node[0]
}

function searchDeepForNodeParentById(structure, elementId) {
  const node = []
  const callback = (result, element, parent, index, parentIndex) => {
    if (parent) {
      result.push({
        element: parent,
        index: parentIndex,
      })
    } else {
      result.push(null)
    }
  }

  genericRecursive(structure, elementId, node, callback)

  return node[0]
}

export function searchDeepForAnyActiveNode(structure) {
  const nodes = []
  const callback = (result, element, parent, index) => result.push({ element, index })
  const condition = (element) => element && element.get('isActive')

  genericRecursive(structure, null, nodes, callback, null, null, condition)

  return nodes
}

export function getPathToElementById(structure, elementId) {
  const node = searchDeepForNodeById(structure, elementId)

  if (!node) {
    return null
  }

  const path = [{ id: node.element.get('id'), index: node.index }]

  while (path[path.length - 1].id) {
    const currentParent = searchDeepForNodeParentById(structure, path[path.length - 1].id)

    path.push({ id: currentParent && currentParent.element.get('id'), index: currentParent && currentParent.index })
  }

  return path
    .reverse()
    .filter((value) => value.id)
    .reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.index)

      return accumulator
    }, [])
}

function insertChildrenNodeToPath(path) {
  if (path.length === 1) {
    return path
  }
  const newPath = [path[0]]
  path.shift()
  path.forEach((node) => {
    newPath.push('children', node)
  })

  return newPath
}

export function toggleisOpenFlagByElementId(structure, id) {
  const path = getPathToElementById(structure, id)
  const newPath = insertChildrenNodeToPath(path)

  const newStructure = structure.setIn([...newPath, 'isOpen'], !structure.getIn([...newPath, 'isOpen']))

  return newStructure
}

export function getAllActiveNodes(structure) {
  const nodes = []
  const callback = (result, element) => result.push(element && element.get('id'))
  const conditionClause = (element) => element && element.get('isActive')

  genericRecursive(structure, null, nodes, callback, null, null, conditionClause)

  return nodes
}

export function deactivateAllActiveNodes(structure) {
  let modifiedStructure = structure
  const activeNodesIds = getAllActiveNodes(structure)
  const pathsToActiveNodes = activeNodesIds.map((id) => {
    const path = getPathToElementById(structure, id)

    return insertChildrenNodeToPath(path)
  })

  pathsToActiveNodes.forEach((path) => {
    modifiedStructure = modifiedStructure.setIn([...path, 'isActive'], false)
  })

  return modifiedStructure
}

export function handleUserInteraction(structure, id, singleChild = true) {
  const path = getPathToElementById(structure, id)
  const { element } = searchDeepForNodeById(structure, id)

  if (!element.get('children').size && element.get('isOpen')) {
    return structure
  }

  let fixedStructure = singleChild ? deactivateAllActiveNodes(structure) : structure

  for (let i = 0; i < path.length; i++) {
    const fixedPath = insertChildrenNodeToPath(path.slice(0, i + 1))

    fixedStructure = fixedStructure.setIn([...fixedPath, 'isOpen'], !element.get('isOpen'))
    if (i === path.length - 1) {
      fixedStructure = fixedStructure.setIn([...fixedPath, 'isActive'], true)
    }
  }

  fixedStructure = deactivateHeader(fixedStructure)

  return fixedStructure
}

function isHeader(structure) {
  return structure && structure.size === 1 && structure.getIn([0, 'children']).size > 0
}

function deactivateHeader(structure) {
  if (isHeader(structure) && getAllActiveNodes(structure.getIn([0, 'children'])).length > 0) {
    return structure.setIn([0, 'isActive'], false)
  }

  return structure
}

export function reinitializeMenu(structure) {
  const activeOrphans = getAllActiveNodes(structure)

  if (!activeOrphans.length) {
    return structure
  }

  let fixedStructure = deactivateAllActiveNodes(structure)

  activeOrphans.forEach((orphan) => {
    fixedStructure = handleUserInteraction(fixedStructure, orphan, false)
  })

  fixedStructure = deactivateHeader(fixedStructure)

  return fixedStructure
}
