import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'

import { selectGlobal } from '../MainLayout/selectors'

export const makeSelectPageData = () => createSelector(selectGlobal, (global) => global.getIn(['cmsPage', 'pageData']))

export const useSelectPageData = () => useSelector(makeSelectPageData())

export const makeSelectTitle = () => createSelector(makeSelectPageData(), (pageData) => pageData.get('title'))

export const makeSelectSiteTitle = () => createSelector(makeSelectPageData(), (pageData) => pageData.get('siteTitle'))

export const makeSelectRelatedPages = () =>
  createSelector(makeSelectPageData(), (pageData) => pageData.get('relatedPages'))

export const makeSelectRelCanonical = () =>
  createSelector(makeSelectPageData(), (pageData) => pageData.get('relCanonical'))

export const makeSelectMetaHeaders = () =>
  createSelector(makeSelectPageData(), (pageData) => pageData.get('metaHeaders'))
