import React from 'react'
import { createPortal } from 'react-dom'

import PropTypes from 'prop-types'

const modalRoot = document.getElementById('app') || document.querySelector('body')

class Portal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  render() {
    return createPortal(this.props.children, this.el)
  }
}

Portal.propTypes = {
  children: PropTypes.node,
}

export default Portal
