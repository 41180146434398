/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR, FORM_AML_CHECK_FORM } from './constants'

const initialState = fromJS({
  loading: undefined,
  error: false,
  [FORM_AML_CHECK_FORM]: {
    fields: [],
  },
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set(FORM_AML_CHECK_FORM, initialState.get(FORM_AML_CHECK_FORM))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(FORM_AML_CHECK_FORM, fromJS(action.data[FORM_AML_CHECK_FORM]))
    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
