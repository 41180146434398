import { isMatch } from 'date-fns'
import { deburr } from 'lodash'
import { change } from 'redux-form/es/immutable'
import { put, select, all, takeEvery } from 'redux-saga/effects'

import { REDUX_FORM_BLUR, REDUX_FORM_CHANGE } from '../App/constants'
import { BIRTH_DATE_FORMAT_MASK } from './constants'
import { selectRegisterFormValues } from './selectors'

const nameFields = ['registration_external[firstname]', 'registration_external[lastname]']

function isUsernameAvailableToGenerate({ firstname, lastname, birth, username }) {
  return firstname && lastname && isMatch(birth, BIRTH_DATE_FORMAT_MASK) && !username
}

function isExpectedActionTriggered(action) {
  const isRightForm = action.meta?.form === 'formRegister'
  const isUsernameNotModified = action.meta?.field !== 'registration_external[username]'
  const isActionRelatedToKeyFields =
    (action.type === REDUX_FORM_BLUR && nameFields.includes(action.meta.field)) ||
    (action.type === REDUX_FORM_CHANGE && action.meta.field === 'registration_external[birth]')

  return isRightForm && isUsernameNotModified && isActionRelatedToKeyFields
}

function generatePlayerUsername({ firstname, lastname, birth }) {
  const firstLetterOfName = firstname.substring(0, 1).toLowerCase()
  const twoLastDigitsOfBirthYear = birth.substring(2, 4).toLowerCase()
  const trimmedLastName = lastname.toLowerCase().replace(' ', '').substring(0, 30)

  return deburr(`${firstLetterOfName}${trimmedLastName}${twoLastDigitsOfBirthYear}`)
}

function* getValuesFromState() {
  const values = yield select(selectRegisterFormValues)

  return {
    firstname: values.get('firstname'),
    lastname: values.get('lastname'),
    birth: values.get('birth'),
    username: values.get('username'),
  }
}

function* watchChangedRegisterFields() {
  yield takeEvery(REDUX_FORM_CHANGE, initializePlayerUsername)
}

function* watchBlurRegisterFields() {
  yield takeEvery(REDUX_FORM_BLUR, initializePlayerUsername)
}

function* initializePlayerUsername(action) {
  if (!isExpectedActionTriggered(action)) {
    return
  }
  const values = yield getValuesFromState()

  if (isUsernameAvailableToGenerate(values)) {
    yield put(change('formRegister', 'registration_external[username]', generatePlayerUsername(values)))
  }
}

export default function* rootSaga() {
  yield all([watchChangedRegisterFields(), watchBlurRegisterFields()])
}
