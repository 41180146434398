import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import FinishGameBtn from '../../containers/MainHeaderBox/FinishGameBtnBox'
import { bh, media, boxFooterBg } from '../../utils/style-utils'
import { MobileConfirmButton } from '../MobileConfirm/MobileConfirmButton'
import CancelAllWithdrawalsButton from '../PlayerProfile/CancelAllWithdrawalsButton'
import { DashboardButton } from './DashboardButton'
import { FinishRegistrationButton } from './FinishRegistrationButton'
import InboxButton from './InboxButton'
import { MakeDepositButton } from './MakeDepositButton'
import { VerifyCardButton } from './VerifyCardButton'

const ImportantButtonWrapper = styled.div`
  padding: ${bh / 2}px 0;
  border-bottom: 1px dashed ${boxFooterBg};

  ${media.md`
    display: block;
  `}
`

const InboxWrapper = styled(ImportantButtonWrapper)`
  display: block;

  ${media.md`
    padding-top: ${bh / 2}px;
    margin-bottom: 0;
  `}
`

function Infos({ user, toggleUserMenu }) {
  const newMessages = user.get('newMessages')
  const mobileConfirmed = user.get('isMobileConfirmed') || user.get('isStaff')
  const showFinishRegistration = !user.get('fullyActivated')
  const gameFinishUrl = user.get('gameFinishUrl')
  const hasWaitingWithdrawal = (user.getIn(['balances', 'CustomerWithdrawalAccount', 'balance']) ?? 0) > 0
  const hasMobile = !!user.get('mobile')

  if (user.get('isStaff')) {
    return (
      <ImportantButtonWrapper onClick={toggleUserMenu}>
        <DashboardButton
          md
          block
        />
      </ImportantButtonWrapper>
    )
  }

  return (
    <div>
      {gameFinishUrl && <FinishGameBtn url={gameFinishUrl} />}

      <ImportantButtonWrapper onClick={toggleUserMenu}>
        {user.get('isOnlineDepositPossible') && (
          <MakeDepositButton
            md
            block
          />
        )}
      </ImportantButtonWrapper>

      {hasWaitingWithdrawal && (
        <ImportantButtonWrapper>
          <CancelAllWithdrawalsButton buttonProps={{ block: true }} />
        </ImportantButtonWrapper>
      )}

      {!mobileConfirmed && hasMobile && (
        <ImportantButtonWrapper onClick={toggleUserMenu}>
          <MobileConfirmButton
            md
            block
          />
        </ImportantButtonWrapper>
      )}

      {showFinishRegistration && (
        <ImportantButtonWrapper
          onClick={toggleUserMenu}
          style={{ textAlign: 'center' }}
        >
          <FinishRegistrationButton
            md
            block
          />
        </ImportantButtonWrapper>
      )}

      {user.get('isCardToVerify') && (
        <ImportantButtonWrapper onClick={toggleUserMenu}>
          <VerifyCardButton
            md
            block
          />
        </ImportantButtonWrapper>
      )}

      {newMessages > 0 && (
        <InboxWrapper onClick={toggleUserMenu}>
          <InboxButton
            md
            block
            newMessages={newMessages}
          />
        </InboxWrapper>
      )}
    </div>
  )
}

Infos.propTypes = {
  toggleUserMenu: PropTypes.func,
  user: ImmutablePropTypes.map,
}

export default Infos
