import styled from 'styled-components'

import { successBg, successColor } from '../../utils/style-utils'
import Alert from './index'

export default styled(Alert).attrs({
  alertType: 'success',
})`
  color: ${successColor};
  background: ${successBg};

  .alert-icon {
    fill: ${successColor};
  }
`
