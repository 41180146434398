import React from 'react'

import { renderOnMobile } from 'components/Media/renderOnMobile'

import { BottomAppBar } from './BottomAppBar'
import { TopAppBar } from './TopAppBar'
import { FixedFix } from './styles'

function MobileAppBarComponent() {
  return (
    <>
      <TopAppBar />
      <BottomAppBar />
      <FixedFix />
    </>
  )
}

export const MobileAppBar = renderOnMobile(MobileAppBarComponent)
