import React, { useState } from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import Block from '../../containers/SharedBlock'
import Button from '../Btn'
import Dialog from '../Dialog'
import { ButtonsWrapper } from '../Dialog/ButtonsWrapper'
import { CookieOption } from './CookieOption'
import { Consents } from './utils'

interface Props {
  showMore: boolean
  toggleShowMore: () => void
  agree: (props: Consents) => void
  agreeNecessary: () => void
}
export function CookieDialog({ showMore, toggleShowMore, agree, agreeNecessary }: Props) {
  const [functional, setFunctional] = useState(true)
  const [marketing, setMarketing] = useState(true)
  const [statistical, setStatistical] = useState(true)
  const [unclassified, setUnclassified] = useState(true)

  if (!showMore) {
    return null
  }

  return (
    <Dialog
      fullHeight
      className="cookie-info-details"
      title={Translator.trans('terms_and_conditions_of_cookies_header')}
      onClose={() => toggleShowMore()}
      Footer={
        <ButtonsWrapper>
          <Button
            sm
            className="confirm-cancel"
            onClick={() => toggleShowMore()}
          >
            {Translator.trans('Cancel')}
          </Button>

          <Button
            sm
            secondary
            className="cookie-info-agree-necessary"
            onClick={agreeNecessary}
          >
            {Translator.trans('cookies_agree_necessary')}
          </Button>

          <Button
            sm
            className="confirm-yes"
            primary
            onClick={() =>
              agree({
                statistical,
                functional,
                marketing,
                unclassified,
              })
            }
          >
            {Translator.trans('cookies_agree')}
          </Button>
        </ButtonsWrapper>
      }
    >
      <Block name="terms_and_conditions_of_cookies_description" />

      <form>
        <CookieOption
          label={Translator.trans('necessary_cookies_label')}
          description={Translator.trans('necessary_cookies_description')}
          name="necessary_cookies"
          value
          disabled
        />

        <CookieOption
          label={Translator.trans('statistical_cookies_label')}
          description={Translator.trans('statistical_cookies_description')}
          name="statistical_cookies"
          value={statistical}
          onChange={() => setStatistical(!statistical)}
        />

        <CookieOption
          label={Translator.trans('functional_cookies_label')}
          description={Translator.trans('functional_cookies_description')}
          value={functional}
          onChange={() => setFunctional(!functional)}
          name="functional_cookies"
        />

        <CookieOption
          label={Translator.trans('marketing_cookies_label')}
          description={Translator.trans('marketing_cookies_description')}
          name="marketing_cookies"
          value={marketing}
          onChange={() => setMarketing(!marketing)}
        />

        <CookieOption
          label={Translator.trans('unclassified_cookies_label')}
          description={Translator.trans('unclassified_cookies_description')}
          value={unclassified}
          onChange={() => setUnclassified(!unclassified)}
          name="unclassified_cookies"
        />
      </form>
    </Dialog>
  )
}

CookieDialog.propTypes = {
  showMore: PropTypes.bool,
  toggleShowMore: PropTypes.func,
  agree: PropTypes.func,
}
