import React from 'react'

import { LOGIN_ADDRESS } from 'containers/LoginPage/constants'

import { LoginLink, StyledUserIcon } from './styles'

export function Anonymous() {
  return (
    <LoginLink
      id="login-user-button-mobile"
      to={LOGIN_ADDRESS}
    >
      <StyledUserIcon />
    </LoginLink>
  )
}
