import styled from 'styled-components'

import { bh, brandSecondary, mainHeaderColor, mainHeaderHeight, media, fontWeightMedium } from '../../utils/style-utils'
import IconLink from '../IconLink/index'

const MenuLink = styled(IconLink)`
  height: ${mainHeaderHeight}px;
  color: ${mainHeaderColor};
  font-size: ${bh * 0.875}px;

  & > svg {
    fill: ${mainHeaderColor};
    width: ${bh * 4}px;
    height: ${bh * 4}px;
  }

  & > span {
    color: ${mainHeaderColor};
    font-weight: ${fontWeightMedium};
  }

  &.active,
  &:hover {
    > svg {
      fill: ${brandSecondary};
    }
  }

  &.active {
    pointer-events: none;
    cursor: default;

    span {
      color: ${brandSecondary};
    }
  }

  ${media.lg`
    font-size: ${bh * 0.75}px;
  `}

  ${media.md`
    height: 100%;
    & > span {
      color: white;
    }
    & > svg {
      fill: white;
      width: ${bh * 3}px;
      height: ${bh * 3}px;
    }
  `}

  ${media.heightXs`
    ${media.md`
      > span {
        margin: 0;
        transform: none;
      }
      &:hover {
        > span {
          transform: none;
          color: ${brandSecondary};
        }
      }
      > svg {
        display: none;
      }
    `}
  `}
`

export default MenuLink
