import React from 'react'

export default function Adell(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      width="64"
      height="64"
      {...props}
    >
      <path
        d="M598.222 603.869c-8.136-11.39-312.436-797.363-410.072-1057.726l-39.055-102.519h320.573l39.054 97.637 37.427 99.263 218.055-4.882 218.054-4.881 32.546-92.755 34.172-94.382h159.473c87.873 0 159.473 4.882 159.473 13.019 0 6.509-97.637 268.5-216.427 580.936L935.067 607.124l-164.354 4.881c-91.127 1.628-169.236-1.627-172.49-8.136zM834.177 61.988c32.545-97.637 53.7-180.628 48.818-185.51-4.882-4.881-63.464-9.763-130.182-9.763-89.5 0-118.79 4.882-112.282 19.527 3.255 11.391 30.918 96.01 61.837 188.764 29.29 91.127 58.581 165.981 63.463 165.981 4.882-1.627 34.173-81.363 68.346-179z"
        transform="matrix(.1 0 0 -.1 0 77)"
      />
    </svg>
  )
}
