/* eslint-disable react/style-prop-object */
import React from 'react'

export default function Pragmatic(props) {
  return (
    <svg
      width="200mm"
      height="200mm"
      viewBox="0 0 20000 20000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M379.592 254.57A216.712 216.712 0 0 0 161.246 469.64a216.712 216.712 0 0 0 215.068 218.343 216.712 216.712 0 0 0 218.344-215.066A216.712 216.712 0 0 0 379.592 254.57zm-.238 31.291a185.42 185.42 0 0 1 184.015 186.817A185.42 185.42 0 0 1 376.551 656.69a185.42 185.42 0 0 1-184.014-186.816 185.42 185.42 0 0 1 186.817-184.014z"
        transform="scale(26.45833)"
      />
      <path d="M4632 4355c440 268 1580 1022 2078 2774 826-510 1779-834 2800-913-277-1119-266-2395 274-3425-226 71-1314 900-1193 2562-337-914-1024-2438-1923-3556-96 1513 86 2833 345 3991-168-354-1072-1479-2381-1433z" />
      <ellipse
        transform="matrix(.97714 .2126 -.01475 .9999 0 0)"
        rx="1558.658"
        ry="1170.864"
        cx="11514.181"
        cy="7063.233"
      />
    </svg>
  )
}
