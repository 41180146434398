import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import { bh, media } from '../../utils/style-utils'
import { MobileConfirmButton } from '../MobileConfirm/MobileConfirmButton'
import { AmlFormButton } from './AmlFormButton'
import { DashboardButton } from './DashboardButton'
import { FinishRegistrationButton } from './FinishRegistrationButton'
import { MakeDepositButton } from './MakeDepositButton'
import { VerifyCardButton } from './VerifyCardButton'

const Wrapper = styled.div`
  margin: 0 ${bh * 1.5}px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${media.md`
    display: none;
  `}
`

function ImportantButton({ user }) {
  const hasMobile = !!user.get('mobile')

  if (user.get('isStaff')) {
    return (
      <Wrapper>
        <DashboardButton md />
      </Wrapper>
    )
  }

  let content = ''

  if (hasMobile && !user.get('isMobileConfirmed')) {
    content = <MobileConfirmButton md />
  } else if (user.get('isCardToVerify')) {
    content = <VerifyCardButton md />
  } else if (!user.get('fullyActivated')) {
    content = (
      <div style={{ display: 'inline-block' }}>
        <FinishRegistrationButton md />
      </div>
    )
  } else if (user.get('isAMLFillRequired')) {
    content = <AmlFormButton md />
  }

  return (
    <Wrapper>
      {user.get('isOnlineDepositPossible') && (
        <MakeDepositButton
          md
          style={{ marginRight: content ? 8 : 0 }}
        />
      )}
      {content}
    </Wrapper>
  )
}

ImportantButton.propTypes = {
  user: ImmutablePropTypes.map,
}

export default ImportantButton
