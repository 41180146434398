import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isArray } from 'lodash'
import { push } from 'redux-first-history'

import { Query } from '../../routing/routeReducerWithQuery'

export default function useMutateURLSearchParams() {
  const dispatch = useDispatch()
  const history = useHistory()

  return (queryParams: Query) => {
    const urlParameters = new URLSearchParams(history.location.search)

    Object.entries(queryParams).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((val) => urlParameters.set(key, val))

        return
      }

      if (typeof value === 'undefined' || value === null || value.length === 0) {
        urlParameters.delete(key)

        return
      }

      urlParameters.set(key, value)
    })

    dispatch(push(`?${urlParameters.toString()}`))
  }
}
