import * as React from 'react'

import { IconProps } from '../types'

function GamesCherry(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.784 7.342c.34-.037.703-.103 1.09-.206 3.142-.846 3.737-3.069 3.737-3.069s-1.63-1.625-4.772-.783c-1.84.492-2.806 1.46-3.291 2.178a10.098 10.098 0 0 0-1.305-.432L7 6l-.287.958c.81.243 1.337.642 1.688 1.1.359.47.575 1.057.672 1.723.145.997.007 2.083-.226 2.979a4.501 4.501 0 1 0 4.596 6.622 3.76 3.76 0 0 0 4.838-3.605 3.763 3.763 0 0 0-3.018-3.688c-.725-2.074-2.07-3.632-3.479-4.747Zm-.732 2.152a6.582 6.582 0 0 0-.028-.179c.874.784 1.65 1.758 2.16 2.944a3.767 3.767 0 0 0-1.484 1.027 4.47 4.47 0 0 0-.835-.362c.235-1.032.359-2.247.187-3.43Zm3.468 4.521c-.535 0-1.012.236-1.336.612a4.48 4.48 0 0 1 .81 2.832 1.76 1.76 0 0 0 2.288-1.682c0-.974-.79-1.762-1.762-1.762Zm-5.02.698a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
      />
    </svg>
  )
}
export default GamesCherry
