import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { bh } from '../../utils/style-utils'
import Button from '../Btn'
import CheckRound from '../Icons/components/Check'
import BaseDialog from './index'

const Dialog = styled(BaseDialog)`
  z-index: ${(p) => Number(p.zIndex) || 11};
  font-size: ${bh * 1.2}px;
`

const ButtonsWrapper = styled.div`
  text-align: right;

  button {
    margin-right: ${bh / 2}px;
  }
`

class InfoDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isClosed: false,
    }
  }

  close() {
    this.setState({ isClosed: true })
    return true
  }

  render() {
    const { isClosed } = this.state

    if (isClosed) {
      return null
    }

    const { title, message, onClose, children, btnLabel, className, zIndex } = this.props

    const Footer = btnLabel && (
      <ButtonsWrapper>
        <Button
          className="confirm-yes"
          primary
          onClick={() => this.close() && onClose?.()}
        >
          <CheckRound />
          {btnLabel}
        </Button>
      </ButtonsWrapper>
    )

    return (
      <Dialog
        title={title}
        Footer={Footer}
        className={className}
        zIndex={zIndex}
      >
        {message}
        {children}
      </Dialog>
    )
  }
}

InfoDialog.propTypes = {
  zIndex: PropTypes.number,
  message: PropTypes.string,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
}

InfoDialog.defaultProps = {
  zIndex: 11,
}

export default InfoDialog
