import React from 'react'
import { Helmet } from 'react-helmet'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import {
  makeSelectMetaHeaders,
  makeSelectRelCanonical,
  makeSelectSiteTitle,
  makeSelectTitle,
} from '../../containers/CmsPageBox/selectors'

interface Props {
  title: string
  siteTitle: string
  relCanonical: string
  meta: any
}

function ConnectedMeta({ title, siteTitle, meta, relCanonical }: Props) {
  const fixedTitle = title && siteTitle ? `${title} - ${siteTitle}` : title || ''

  return (
    <>
      <Helmet title={fixedTitle} />
      {meta &&
        meta
          .map((content: string, name: string) => (
            <Helmet
              key={name}
              meta={[{ name, content }]}
            />
          ))
          .toList()}
      {relCanonical && <Helmet link={[{ rel: 'canonical', href: relCanonical }]} />}
    </>
  )
}

ConnectedMeta.propTypes = {
  title: PropTypes.string,
  siteTitle: PropTypes.string,
  relCanonical: PropTypes.string,
  meta: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  title: makeSelectTitle(),
  siteTitle: makeSelectSiteTitle(),
  relCanonical: makeSelectRelCanonical(),
  meta: makeSelectMetaHeaders(),
})

export default connect(mapStateToProps, {})(ConnectedMeta)
