import { SuggestionsFetchRequestedParams } from 'react-autosuggest'

import { debounce } from 'lodash'

import { GetFetchSuggestionsParams, Suggestion } from './types'

export function getSuggestionValue(suggestion: Suggestion<any>) {
  return suggestion.label
}

export function getFetchSuggestions<TData, TParams>({
  fetchData,
  setTransformedSuggestions,
  queryParams,
}: GetFetchSuggestionsParams<TData, TParams>) {
  return debounce(async ({ value }: SuggestionsFetchRequestedParams) => {
    if (value.length > 1) {
      const data = await fetchData(value, queryParams)

      setTransformedSuggestions(data)
    }
  }, 200)
}
