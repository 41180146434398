import React from 'react'

export default function New() {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
    >
      <path
        d="M633.857,353.6a0.974,0.974,0,0,0-.971.978v4.434L629.2,354a0.965,0.965,0,0,0-1.083-.346,0.977,0.977,0,0,0-.666.929v6.853a0.97,0.97,0,1,0,1.94,0v-3.893l3.691,5.017a0.969,0.969,0,0,0,1.75-.583v-7.394A0.974,0.974,0,0,0,633.857,353.6Zm15.727,0a0.973,0.973,0,0,0-.97.978v3.039l-0.6-1.109a0.966,0.966,0,0,0-1.69-.02l-0.751,1.305v-3.216a0.97,0.97,0,1,0-1.94,0v6.854a0.969,0.969,0,0,0,1.81.49l1.7-2.952,1.6,2.933a0.97,0.97,0,0,0,.851.507,0.954,0.954,0,0,0,.241-0.031,0.976,0.976,0,0,0,.729-0.947v-6.854A0.973,0.973,0,0,0,649.584,353.6Zm-8.173,1.955a0.978,0.978,0,0,0,0-1.955h-4.043a0.974,0.974,0,0,0-.971.978v6.853a0.975,0.975,0,0,0,.971.978h4.043a0.978,0.978,0,0,0,0-1.956h-3.073v-1.471h3.073a0.978,0.978,0,0,0,0-1.955h-3.073v-1.472h3.073Zm11.44-7.874-13.064-13.279a0.966,0.966,0,0,0-1.574,0l-13.064,13.279A4.2,4.2,0,0,0,622,351.759V364.8a4.19,4.19,0,0,0,4.169,4.2h25.662A4.19,4.19,0,0,0,656,364.8v-13.04A4.2,4.2,0,0,0,652.851,347.685ZM639,336.651l11.368,10.907H627.632ZM654.059,364.8a2.239,2.239,0,0,1-2.228,2.245H626.169a2.24,2.24,0,0,1-2.229-2.245v-13.04a2.24,2.24,0,0,1,2.229-2.246h25.662a2.24,2.24,0,0,1,2.228,2.246V364.8h0Zm-28.321-14.525a0.97,0.97,0,0,0-.687.286,0.974,0.974,0,1,0,1.373,1.383,0.984,0.984,0,0,0,0-1.383A0.973,0.973,0,0,0,625.738,350.274Zm26.524,0a0.968,0.968,0,0,0-.686.286,0.978,0.978,0,0,0,.686,1.669,0.977,0.977,0,0,0,.686-0.286,0.981,0.981,0,0,0,0-1.383A0.968,0.968,0,0,0,652.262,350.274Z"
        transform="translate(-622 -334)"
      />
    </svg>
  )
}
