import React from 'react'

import PropTypes from 'prop-types'

import { getServerDate } from '../../utils/dates'
import { pad } from '../../utils/numbers'

class TimeCounter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.count()
  }

  componentWillUnmount() {
    this.stopCounting()
  }

  count = () => {
    const d = getServerDate()
    const hours = d.getHours()
    const minutes = d.getMinutes()
    const seconds = d.getSeconds()

    const str = this.props.noSecs
      ? `${pad(hours, 2)}:${pad(minutes, 2)}`
      : `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`

    this.setState({
      timeToShow: str,
    })

    this.toId = setTimeout(this.count, 1000)
  }

  stopCounting() {
    clearTimeout(this.toId)
  }

  render() {
    const { timeToShow } = this.state
    const { noSecs, start, ...rest } = this.props

    return <span {...rest}>{timeToShow}</span>
  }
}

TimeCounter.propTypes = {
  noSecs: PropTypes.bool,
  timeToShow: PropTypes.string,
  start: PropTypes.any,
}

TimeCounter.defaultProps = {
  noSecs: false,
}

export default TimeCounter
