import styled from 'styled-components'

export const DepositButtonBox = styled.div`
  flex: 1;

  min-width: 8rem;
  max-width: 16rem;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  > * {
    flex: 1;
  }
`
