/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import insertCaptchaToStateForm from '../../utils/insertCaptchaToStateForm'
import { RESET_PAGE_STATE, INSERT_CAPTCHA } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

// The initial state of the App
const initialState = fromJS({
  loading: undefined,
  error: false,
  form: {
    fields: [],
  },
  thread: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('form', initialState.get('form'))
        .set('thread', initialState.get('thread'))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('form', fromJS(action.data.form))
        .set('thread', fromJS(action.data.thread))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case INSERT_CAPTCHA:
      return insertCaptchaToStateForm(state)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
