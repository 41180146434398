import styled from 'styled-components'

interface Props {
  brightness?: number
}

export const DarkGlass = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  ${({ brightness }) => `backdrop-filter: blur(0.5rem) brightness(${brightness || 0.5}) saturate(1.5)`};
`
