import styled from 'styled-components'

export const UserBox = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`
