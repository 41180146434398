/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

const initialState = fromJS({
  loading: undefined,
  error: false,
  form: {
    fields: [],
  },
})

function ResetPasswordSendReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state.set('loading', true).set('error', false).set('form', initialState.get('form'))

    case CONTENT_LOAD_SUCCESS:
      return state.set('loading', false).set('error', false).set('form', fromJS(action.data.form))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default ResetPasswordSendReducer
