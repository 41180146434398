import Loadable from 'react-loadable'

import Loading from '../Loader/CenteredLoader'

const LoadableComponent = Loadable({
  loader: () => import('./index'),
  loading: Loading,
})

export default LoadableComponent
