import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import MenuWrapper from '../../../components/GenericMenu/SidebarMenuWrapper'
import GenericMenu from '../../GenericMenu'
import { toggleGenericMenuPosition, toggleGenericMenuSection } from '../../MobileMenuBox/actions'
import { makeSelectRelatedPages } from '../selectors'

class SideBarBox extends React.Component {
  constructor() {
    super()
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.relatedPages.equals(nextProps.relatedPages)
  }

  togglePosition = (id) => {
    this.props.togglePosition(id)
  }

  toggleSection = (id) => {
    this.props.toggleSection(id)
  }

  render() {
    const { relatedPages } = this.props

    if (relatedPages.size === 0) {
      return null
    }

    const topPage = relatedPages.get(0)

    return (
      <GenericMenu
        className="sidebar-menu"
        path={['cmsPage', 'pageData', 'relatedPages']}
        structure={topPage.get('children')}
        toggleMenuPosition={this.togglePosition}
        toggleMenuSection={this.toggleSection}
        header={topPage.set('children', [])}
        Wrap={MenuWrapper}
      />
    )
  }
}

SideBarBox.propTypes = {
  relatedPages: ImmutablePropTypes.list,
  togglePosition: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  relatedPages: makeSelectRelatedPages(),
})

const mapDispatchToProps = (dispatch) => ({
  togglePosition: (id) => dispatch(toggleGenericMenuPosition(id, 'desktop')),
  toggleSection: (id) => dispatch(toggleGenericMenuSection(id, 'desktop')),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarBox)
