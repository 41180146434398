import React from 'react'
import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import FlashMessages from '../../components/FlashMessages'
import { closeFlashMessage } from '../MainLayout/actions'
import { makeSelectFlashMessages } from './selectors'

class FlashMessagesBox extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return <FlashMessages {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  flashMessages: makeSelectFlashMessages(),
})

function mapDispatchToProps(dispatch) {
  return {
    close(message) {
      dispatch(closeFlashMessage(message))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessagesBox)
