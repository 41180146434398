import { useCallback, useState } from 'react'

import { getCookie, setCookie } from 'utils/cookies'

import { cookieKey, Layout } from './constant'

export function useLayout() {
  const layoutFromCookie = getCookie(cookieKey) as Layout
  const [layout, setLayoutState] = useState(layoutFromCookie)

  const setLayout = useCallback((newLayout: Layout) => {
    setCookie(cookieKey, newLayout)
    setLayoutState((currentLayout) => {
      if (currentLayout !== newLayout) {
        window.location.reload()
      }

      return newLayout
    })
  }, [])

  const setNewLayout = useCallback(() => {
    setLayout(Layout.NEW)
  }, [setLayout])

  const setOldLayout = useCallback(() => {
    setLayout(Layout.OLD)
  }, [setLayout])

  return { layout, setNewLayout, setOldLayout }
}
