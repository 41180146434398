import { matchPath } from 'react-router-dom'

export function isGameCategoryPath(path) {
  const matchHome = matchPath(path, {
    path: '/:lang?',
    exact: true,
  })

  const matchCategory = matchPath(path, {
    path: '/:lang/games/:categorySlug?',
    exact: true,
  })

  return !!(matchHome || matchCategory)
}
