import React from 'react'

import type { MenuItem } from 'types/MenuItem'

import { StyledUrlLink } from './styles'

interface Props {
  item: MenuItem
}

export function MenuItem({ item: { uri, label } }: Props) {
  return <StyledUrlLink url={uri}>{label}</StyledUrlLink>
}
