import { useEffect } from 'react'

import { getThreshold } from './utils'

interface Props {
  refetchFn: () => void
  dates: Date[]
  maxThreshold: number
}

export default function useRefetchOnThreshold({ refetchFn, dates, maxThreshold }: Props) {
  useEffect(() => {
    if (dates.length > 0) {
      const threshold = getThreshold(dates)

      if (threshold > 0 && threshold < maxThreshold) {
        const timeoutId = setTimeout(() => {
          refetchFn()
        }, threshold)

        return () => clearTimeout(timeoutId)
      }

      return undefined
    }

    return undefined
  }, [dates])
}
