import styled from 'styled-components'

import { media } from '../../utils/style-utils'

const Ul = styled.ul`
  flex-grow: 1;
  list-style: none;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  align-items: center;

  ${media.md`
    display: none;
  `}
`

export default Ul
