/* eslint-disable @typescript-eslint/no-var-requires */
import React, { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import saga from './containers/MainLayout/sagas'
import ReactQueryProvider from './containers/ReactQueryProvider'
import sharedBlockSaga from './containers/SharedBlock/sagas'
import { WebSocketProvider } from './containers/WebSocketProvider'
import { getConfiguredStore } from './reduxStore'
import history from './utils/routing/history'
import getInjectors from './utils/routing/sagaInjectors'

const { store, createReduxHistory } = getConfiguredStore({}, history)

const { injectSaga } = getInjectors(store)

injectSaga('mainLayout', { saga })
injectSaga('sharedBlock', { saga: sharedBlockSaga })
const reduxHistory = createReduxHistory(store)

interface Props {
  children: ReactElement
}

function Providers({ children }: Props) {
  return (
    <Provider store={store}>
      <Router history={reduxHistory}>
        <ReactQueryProvider>
          <WebSocketProvider>{children}</WebSocketProvider>
        </ReactQueryProvider>
      </Router>
    </Provider>
  )
}

export default Providers
