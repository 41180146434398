import { min } from 'date-fns'

import { getServerDate } from '../../../utils/dates'
import { REFETCH_DELAY } from '../constants'

export function getThreshold(dates: Date[]) {
  const timeNow = getServerDate().getTime()

  const dateToRefresh = min(dates)

  const threshold = dateToRefresh.getTime() - timeNow + REFETCH_DELAY

  return threshold
}
