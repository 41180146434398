import { FlashMessage, FlashMessageTypes } from 'components/FlashMessages/types'
import { gameFinishError, gameFinishSuccess, sendGameFinish, toggleUserMenu } from 'containers/MainHeaderBox/actions'
import {
  toggleGenericMenuPosition,
  toggleGenericMenuSection,
  toggleLangMenu,
  toggleMobileMenu,
} from 'containers/MobileMenuBox/actions'
import { GlobalStore } from 'types/store/GlobalState'

import {
  RESET_PAGE_STATE,
  INIT_LOAD,
  INIT_LOAD_SUCCESS,
  INIT_LOAD_REDIRECT,
  INIT_LOAD_ERROR,
  INIT_RELOAD_NEEDED,
  ADD_FLASH_MESSAGE,
  CLOSE_FLASH_MESSAGE,
  CLOSE_ALL_FLASH_MESSAGES,
  WINDOW_CLICKED,
  INSERT_CAPTCHA,
  INIT_RESET,
  CLOSE_ANNOUNCEMENT,
  COOKIE_CONSENTS_CHANGE,
} from './constants'

export function reset() {
  return {
    type: RESET_PAGE_STATE,
  }
}

export function initReset() {
  return {
    type: INIT_RESET,
  }
}

export function initLoad(pathname?: string, force?: boolean) {
  return {
    type: INIT_LOAD,
    pathname,
    force,
  }
}

export function initReloadNeeded() {
  return {
    type: INIT_RELOAD_NEEDED,
  }
}

export function initLoaded(data: GlobalStore, flashMessages?: FlashMessage[]) {
  return {
    type: INIT_LOAD_SUCCESS,
    data,
    flashMessages,
  }
}

export function initLoadedRedirect() {
  return {
    type: INIT_LOAD_REDIRECT,
  }
}

export function initLoadingError(error: Error | string | boolean) {
  return {
    type: INIT_LOAD_ERROR,
    error,
  }
}

export function windowClicked() {
  return {
    type: WINDOW_CLICKED,
  }
}

export function addFlashMessage(text: string, type: FlashMessageTypes) {
  return {
    type: ADD_FLASH_MESSAGE,
    data: { text, type },
  }
}

export function closeFlashMessage(message: string) {
  return {
    type: CLOSE_FLASH_MESSAGE,
    message,
  }
}

export function closeAnnouncement(id: string) {
  return {
    type: CLOSE_ANNOUNCEMENT,
    id,
  }
}

export function closeAllFlashMessages() {
  return {
    type: CLOSE_ALL_FLASH_MESSAGES,
  }
}

export function insertCaptcha() {
  return {
    type: INSERT_CAPTCHA,
  }
}

export function cookieConsentsChange() {
  return {
    type: COOKIE_CONSENTS_CHANGE,
  }
}

export type InitLoadAction = ReturnType<typeof initLoad>
export type InitLoadedAction = ReturnType<typeof initLoaded>

export type GlobalAction =
  | ReturnType<typeof cookieConsentsChange>
  | ReturnType<typeof insertCaptcha>
  | ReturnType<typeof closeAllFlashMessages>
  | ReturnType<typeof closeAnnouncement>
  | ReturnType<typeof closeFlashMessage>
  | ReturnType<typeof addFlashMessage>
  | ReturnType<typeof windowClicked>
  | ReturnType<typeof initLoadingError>
  | ReturnType<typeof initLoadedRedirect>
  | ReturnType<typeof initLoaded>
  | ReturnType<typeof reset>
  | ReturnType<typeof initReset>
  | ReturnType<typeof initLoad>
  | ReturnType<typeof initReloadNeeded>
  | ReturnType<typeof toggleMobileMenu>
  | ReturnType<typeof toggleLangMenu>
  | ReturnType<typeof toggleGenericMenuPosition>
  | ReturnType<typeof toggleGenericMenuSection>
  | ReturnType<typeof toggleUserMenu>
  | ReturnType<typeof sendGameFinish>
  | ReturnType<typeof gameFinishSuccess>
  | ReturnType<typeof gameFinishError>
  | Record<string, never>
