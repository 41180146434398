import React from 'react'

import PropTypes from 'prop-types'

import { catchHref } from '../../components/CmsPage/ContentArticle'
import StyledCmsArticle from '../../components/CmsPage/StyledCmsArticle'
import InfoDialog from '../../components/Dialog/InfoDialog'
import { AnnouncementProp } from './props'

export default function SingleAnnouncement({ announcement, onClose, children, btnLabel }) {
  const id = announcement.get('id')
  const title = announcement.get('title')
  const description = announcement.get('description')

  return (
    <InfoDialog
      className={`announcement-${id}`}
      title={title}
      onClose={onClose}
      btnLabel={btnLabel}
    >
      <StyledCmsArticle
        onClick={(e) => catchHref(e)}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {children}
    </InfoDialog>
  )
}

SingleAnnouncement.propTypes = {
  announcement: AnnouncementProp.isRequired,
  onClose: PropTypes.func.isRequired,
  btnLabel: PropTypes.string,
  children: PropTypes.node,
}

SingleAnnouncement.defaultProps = {
  btnLabel: 'OK',
}
