import bytes from 'bytes'

import { toNum } from './numbers'

export const required = (msg) => (value) => (value ? undefined : msg)

export const number = (msg) => (value) => (value !== '' && toNum(value) === undefined ? msg : undefined)

export const minLength = (min) => (msg) => (value) => (`${value}`.length < min ? msg : undefined)

export const maxLength = (max) => (msg) => (value) => (`${value}`.length > max ? msg : undefined)

export const minValue = (min) => (msg) => (value) => (value !== '' && value < min ? msg : undefined)

export const maxValue = (max) => (msg) => (value) => (value !== '' && value > max ? msg : undefined)

export const regexpTest = (regexpStr) => {
  const reg = new RegExp(regexpStr)
  return (msg) => (value) => (value !== '' && !reg.test(value) ? msg : undefined)
}

export const emailTest = () => {
  const emailRegexp = '^.+@.+\\..{2,}$'
  return regexpTest(emailRegexp)
}

function isFileFilled(file) {
  return file && file.size && file.mimeType
}

function isFileEmpty(file) {
  return !file
}

export const maxSizeTest = (maxSize) => (msg) => (file) => {
  if (isFileEmpty(file)) {
    return undefined
  }

  const fileSize = file && file ? file.size : 0

  if (isFileFilled(file) && fileSize && fileSize <= maxSize) {
    return undefined
  }

  return msg.replace('{{ size }} {{ suffix }}', bytes(fileSize || 0))
}

export const mimeTypesTest = (mimeTypes) => (msg) => (file) => {
  if (isFileEmpty(file) || !mimeTypes) {
    return undefined
  }

  const mimeType = file && file ? file.mimeType : false

  if (isFileFilled(file) && mimeTypes.find((type) => type === mimeType)) {
    return undefined
  }

  return msg.replace('{{ type }}', mimeType || 'none')
}

export const errorsTest = () => (file) => {
  if (file && file.errors) {
    return file.errors.join('; ')
  }
  return undefined
}

export const uploadedTest = (msg) => (file) => (!file || file.uploaded ? undefined : msg)
