import { BASE_PATH, LANG } from '../App/constants'

export const IDENTITY_DOCUMENT_REGISTER_STATE_KEY = 'identity-document-register'
export const FORM_STAGE_ONE_NAME = 'stageOneForm'
export const FORM_STAGE_TWO_NAME = 'stageTwoForm'
export const SEND_STAGE_ONE_FORM_PATH = `${BASE_PATH}/${LANG}/register/identity-document`
export const SEND_STAGE_TWO_FORM_PATH = `${BASE_PATH}/${LANG}/register/identity-document/stage-two`

export const LOAD = 'Casino/StageOneIdentityDocumentRegistration/LOAD'
export const LOAD_SUCCESS = 'Casino/StageOneIdentityDocumentRegistration/LOAD_SUCCESS'
export const LOAD_ERROR = 'Casino/StageOneIdentityDocumentRegistration/LOAD_ERROR'

export const GO_TO_STAGE_TWO = 'Casino/StageOneIdentityDocumentRegistration/GO_TO_STAGE_TWO'
export const FINISH_WITHOUT_CONFIRMING = 'Casino/StageOneIdentityDocumentRegistration/FINISH_WITHOUT_CONFIRMING'
export const RELOAD_REGISTER_FORM = 'Casino/StageOneIdentityDocumentRegistration/RELOAD_REGISTER_FORM'
export const RELOAD_REGISTER_FORM_SUCCESS = 'Casino/StageOneIdentityDocumentRegistration/RELOAD_REGISTER_FORM_SUCCESS'
export const RELOAD_REGISTER_FORM_ERROR = 'Casino/StageOneIdentityDocumentRegistration/RELOAD_REGISTER_FORM_ERROR'
