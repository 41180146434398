import React from 'react'

export default function Envelope(props) {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M479.998,64H32C14.329,64,0,78.312,0,96v320c0,17.688,14.329,32,32,32h447.998C497.671,448,512,433.688,512,416V96  C512,78.312,497.671,64,479.998,64z M416,128L256,256L96,128H416z M448,384H64V160l192,160l192-160V384z" />
    </svg>
  )
}
