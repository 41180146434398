import React from 'react'

import PageContentHeaderBox from 'containers/CmsPageBox/PageContentHeaderBox'
import PropTypes from 'prop-types'

import ContentWrapper from './ContentWrapper'

function CmsPageContent({ children }) {
  return (
    <ContentWrapper>
      <PageContentHeaderBox />
      {children}
    </ContentWrapper>
  )
}

CmsPageContent.propTypes = {
  children: PropTypes.node,
}

export default CmsPageContent
