import React from 'react'

import Button from 'components/Btn'
import { useIsNewLayout } from 'containers/App/NewLayout/useIsNewLayout'
import { useLayout } from 'containers/App/NewLayout/useLayout'
import { NEW_LAYOUT_ENABLED } from 'containers/App/constants'

export function LayoutSelector() {
  const { setNewLayout, setOldLayout } = useLayout()
  const isNewLayout = useIsNewLayout()

  if (!NEW_LAYOUT_ENABLED) {
    return null
  }

  return (
    <div style={{ textAlign: 'center' }}>
      Layout:
      <Button
        link
        sm
        disabled={isNewLayout}
        onClick={setNewLayout}
      >
        new
      </Button>
      /
      <Button
        link
        sm
        disabled={!isNewLayout}
        onClick={setOldLayout}
      >
        old
      </Button>
    </div>
  )
}
