import { takeEvery, call, put, all } from 'redux-saga/effects'

import { request } from '../../utils/request'
import { BASE_PATH, LANG } from '../App/constants'
import { loadSharedBlockSuccess, loadSharedBlockError } from './actions'
import { LOAD_SHARED_BLOCK } from './constants'

function* getSharedBlock(action) {
  const url = `${BASE_PATH}/content/${LANG}/shared-block/${action.key}`

  try {
    const response = yield call(request, url, {
      headers: {
        accept: 'application/json',
      },
    })

    yield put(loadSharedBlockSuccess(action.key, response.data))
  } catch (error) {
    yield put(loadSharedBlockError(action.key, error))
  }
}

function* watchSharedBlockLoad() {
  yield takeEvery(LOAD_SHARED_BLOCK, getSharedBlock)
}

export default function* rootSagas() {
  yield all([watchSharedBlockLoad()])
}
