/*
 *
 * Games constants
 *
 */
import { BASE_PATH, LANG } from '../App/constants'

export const GAMES_LOAD_URI = `${BASE_PATH}/content/${LANG}/games`

export const GAMES_ORDER_DEFAULT = 'position'
export const GAMES_ORDER_POPULARITY = 'topRankPosition'
export const GAMES_ORDER_ASC = 'A-Z'
export const GAMES_ORDER_DESC = 'Z-A'
