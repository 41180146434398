import React from 'react'
import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import FirstLoginDialog from '../../components/FirstLoginDialog'
import { makeSelectIsLogged } from '../MainLayout/selectors'

class FirstLoginDialogBox extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return <FirstLoginDialog {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  userIsLogged: makeSelectIsLogged(),
})

export default connect(mapStateToProps)(FirstLoginDialogBox)
