import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media, mainHeaderHeightMd } from '../../utils/style-utils'

const StyledHeader = styled.header`
  position: 'relative';
  width: 100%;
  z-index: 10;

  ${media.md`
    position: fixed;
    height: ${mainHeaderHeightMd}px;
  `}
`

function Header({ children }) {
  return <StyledHeader className="casino">{children}</StyledHeader>
}

Header.propTypes = {
  children: PropTypes.node,
}

export default Header
