import Danger from '../Alert/Danger'
import Permanent from '../Alert/Permanent'
import Success from '../Alert/Success'
import Warning from '../Alert/Warning'

export const typeHandler = {
  success: Success,
  warning: Warning,
  error: Danger,
  permanent: Permanent,
}
