import styled from 'styled-components'

import { grayDarkest, grayLighter } from '../../utils/style-utils'

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 6rem;
  padding-bottom: 6rem;

  background: ${grayDarkest};
  color: ${grayLighter};
  text-align: center;
`
