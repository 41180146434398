import React from 'react'

import withCondition from 'components/withCondition'

import { Links } from './Links'
import { Profile } from './Profile'
import { Content, Hr, Overlay, Bg } from './styles'

interface Props {
  closePopover: () => void
}

function PopoverContent({ closePopover }: Props) {
  return (
    <Overlay>
      <Content>
        <Profile />
        <Hr />
        <Links onClick={closePopover} />
      </Content>
      <Bg onClick={closePopover} />
    </Overlay>
  )
}

export const PlayerPopoverContent = withCondition(PopoverContent)
