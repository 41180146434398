export const ICON_HOME = 'home' as const
export const ICON_BALL = 'ball' as const
export const ICON_CACHEBAG = 'cachebag' as const
export const ICON_CARDS = 'cards' as const
export const ICON_SLOTS = 'slots' as const
export const ICON_TOURNAMENT = 'tournament' as const
export const ICON_SEARCH = 'search' as const
export const ICON_COURT = 'court' as const
export const ICON_LOVE = 'love' as const
export const ICON_JACKPOT = 'jackpot' as const
export const ICON_STOPWATCH = 'stopwatch' as const
export const ICON_FACEBOOK = 'facebook' as const
export const ICON_INSTAGRAM = 'instagram' as const
export const ICON_TWITTER = 'twitter' as const
export const ICON_GOOGLE_PLUS = 'google+' as const
export const ICON_ALL = 'all' as const
export const ICON_NEW = 'new' as const
export const ICON_FAVORITE = 'favorite' as const
export const ICON_DICE = 'dice' as const
export const ICON_APOLLO_LOGO = 'apollo' as const
export const ICON_EGAMING_LOGO = 'egaming' as const
export const ICON_KAJOT_LOGO = 'kajot' as const
export const ICON_PRAGMATIC_LOGO = 'pragmatic' as const
export const ICON_SYNOT_LOGO = 'synot' as const
export const ICON_AMUSNET_LOGO = 'amusnet' as const
export const ICON_EGT_DIGITAL_LOGO = 'egt_digital' as const
export const ICON_ADELL_LOGO = 'adell' as const
export const ICON_TECH4BET_LOGO = 'tech4bet' as const
