import * as React from 'react'

import { IconProps } from '../types'

function Check(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Zm-1.293 15.707 8-8-1.414-1.414L10 14.586l-2.793-2.793-1.414 1.414 3.5 3.5a1 1 0 0 0 1.414 0Z"
      />
    </svg>
  )
}
export default Check
