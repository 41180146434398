import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 5rem 1fr 15rem;
  width: 100%;
  max-width: 80rem;
  align-items: center;
  padding: 1rem;
`
