import React from 'react'

import PropTypes from 'prop-types'

import { grayDarker } from '../../utils/style-utils'
import FlagIcon from '../FlagIcon'
import List from '../List'
import Button from './Button'
import Li from './Li'
import Ul from './Ul'
import Wrapper from './Wrapper'

function LanguageSwitcher(props) {
  const activeItem = props.langMenu.filter((item) => item.get('active')).first()
  const items = props.langMenu.filter((item) => !item.get('active'))
  const buttonStyles = props.open
    ? {
        background: grayDarker,
      }
    : null

  if (items.size === 0) {
    return null
  }

  return (
    <Wrapper>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          props.clickHandler()
        }}
        open={props.open}
        style={buttonStyles}
      >
        <FlagIcon flag={activeItem ? activeItem.get('lang') : null} />
        {activeItem ? activeItem.get('label') : null}
      </Button>
      <List
        items={items.valueSeq()}
        Li={Li}
        Ul={Ul}
        open={props.open}
      />
    </Wrapper>
  )
}

LanguageSwitcher.propTypes = {
  langMenu: PropTypes.object,
  clickHandler: PropTypes.func,
  open: PropTypes.bool,
}

export default LanguageSwitcher
