import React from 'react'

import styled from 'styled-components'

import { bh, dangerBg, fontWeightBold } from '../../utils/style-utils'
import EnvelopeBase from '../Icons/Envelope'

const Indicator = styled.div`
  align-items: center;
  justify-content: center;
  background: ${dangerBg};
  height: 25px;
  width: 25px;
  font-weight: ${fontWeightBold};
  border-radius: 50%;
  line-height: ${bh}px;
  font-size: 19px;
  padding: 1px;
`

const Envelope = styled(EnvelopeBase)`
  fill: white;
  height: 15px;
  width: 19px;
`

export function Warning(props) {
  return <Indicator {...props}>!</Indicator>
}

export function Message(props) {
  return (
    <Indicator {...props}>
      <Envelope />
    </Indicator>
  )
}
