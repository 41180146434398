import React from 'react'

import Translator from 'bazinga-translator'

import InfoDialog from '../../components/Dialog/InfoDialog'
import { isCookieSet, setCookie } from '../../utils/cookies'
import SharedBlock from '../SharedBlock'
import { isBrowser, isBrowserCompatible } from './utils'

export default class BrowserCompatibilityDialog extends React.Component {
  isAcquiredBrowserIncompatibilityWarning() {
    return isCookieSet('acquiredBrowserIncompatibilityWarning')
  }

  render() {
    if (!isBrowser() || isBrowserCompatible() || this.isAcquiredBrowserIncompatibilityWarning()) {
      return null
    }

    return (
      <InfoDialog
        btnLabel="OK"
        title={Translator.trans('browser_not_support', {}, 'messages')}
        onClose={() => setCookie('acquiredBrowserIncompatibilityWarning', true)}
      >
        <SharedBlock name="browserCompatibility" />
      </InfoDialog>
    )
  }
}
