import { format, isAfter, isBefore, Locale, parseISO } from 'date-fns'

import { SERVER_TIMEZONE, DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, LANG } from '../containers/App/constants'
import { getServerClientTimeDiff } from './getServerClientTimeDiff'

let locale: Locale

let serverClientTimeDiff = 0
export function setServerClientTimeDiff(serverDate: string) {
  try {
    const timeDiff = getServerClientTimeDiff(serverDate, SERVER_TIMEZONE)

    serverClientTimeDiff = timeDiff
  } catch (error) {
    console.log(error)
  }
}

export function getServerDate() {
  return new Date(Date.now() - serverClientTimeDiff)
}

export function isServerDateFuture(date: Date) {
  return isAfter(date, getServerDate())
}

export function isServerDatePast(date: Date) {
  return isBefore(date, getServerDate())
}

export function parseDate(value: string | Date) {
  if (!value) {
    throw new Error('Date or DateTime passed to utils/dates parseDate function has not been defined')
  }
  if (value instanceof Date) {
    return value
  }

  const parsed = parseISO(value)

  if (`${parsed}` === 'Invalid Date') {
    throw new Error(`Invalid Date ${value}`)
  }

  return parsed
}

export function formatDate(date: string | Date, dateFormat = DEFAULT_DATE_FORMAT) {
  return format(parseDate(date), dateFormat)
}

export function formatDateTime(date: string | Date, dateTimeFormat = DEFAULT_DATE_TIME_FORMAT) {
  return formatDate(date, dateTimeFormat)
}

export function getLocale() {
  return locale
}

export async function initCurrentLocale(newLocale?: Locale) {
  if (newLocale) {
    locale = newLocale

    return
  }

  const importLocaleMap = {
    cs: () => import(/* webpackChunkName: "date-fns-cs" */ 'date-fns/locale/cs'),
    sk: () => import(/* webpackChunkName: "date-fns-sk" */ 'date-fns/locale/sk'),
  }

  const getLocalFn = importLocaleMap[LANG as keyof typeof importLocaleMap] || importLocaleMap.cs
  locale = (await getLocalFn()).default
}
