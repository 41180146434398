import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'

const Component = lazy(() => import(/* webpackChunkName: "Tournaments" */ './index'))

export default function TournamentsLoadable() {
  return (
    <Suspense fallback={<CenteredLoader bg />}>
      <Component />
    </Suspense>
  )
}
