import React from 'react'

import { Autocomplete } from '../../../components/Form/Autocomplete'
import { Suggestion } from '../../../components/Form/Autocomplete/types'
import { FormProps } from '../../../components/Form/types'
import { CityDTO } from './types'
import { getCitySuggestions } from './useCitySuggestions'

interface Props extends FormProps {
  onCustomChange?: (params?: Suggestion<CityDTO>) => void
  isDisabled?: boolean
}

function CityAutocomplete({ onChange, onCustomChange, isDisabled, disable, ...props }: Props) {
  return (
    <Autocomplete
      queryParams={undefined}
      fetchData={getCitySuggestions}
      disable={disable || isDisabled}
      onChange={(value) => {
        onChange(value)
        onCustomChange?.(value)
      }}
      {...props}
    />
  )
}

export default CityAutocomplete
