import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import DotLoader from '../../components/Loader/DotLoader'
import StyledSharedBlock from '../../components/SharedBlock'
import { fetchSharedBlock } from './actions'
import { makeSelectBlock } from './selectors'

const runningScripts = []
function runScript(script) {
  if (!runningScripts[script]) {
    runningScripts[script] = true

    eval(script) // eslint-disable-line no-eval
  }
}

class SharedBlock extends Component {
  static propTypes = {
    block: ImmutablePropTypes.mapContains({
      value: PropTypes.string,
      isLoading: PropTypes.bool,
    }),
    name: PropTypes.string.isRequired,
    fetchSharedBlock: PropTypes.func.isRequired,
  }

  componentDidUpdate() {
    this.runScripts()
  }

  updateRef = (el) => {
    if (el) {
      this.el = el
      this.runScripts()
    }
  }

  isOkToRunScripts() {
    return this.el && this.props.name === 'extra_page_contents'
  }

  runScripts() {
    if (this.isOkToRunScripts()) {
      const scripts = this.el.getElementsByTagName('script')
      for (let index = 0; index < scripts.length; index++) {
        const script = scripts[index].innerHTML
        runScript(script)
      }
    }
  }

  render() {
    const { block, name } = this.props

    if (block === undefined) {
      this.props.fetchSharedBlock(name)

      return null
    } else if (block.get('isLoading')) {
      return <DotLoader className="loader" />
    } else if (block.get('value') === null) {
      return null
    }

    return (
      <StyledSharedBlock
        ref={this.updateRef}
        dangerouslySetInnerHTML={{ __html: block.get('value') }}
      />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  block: makeSelectBlock(),
})

const mapDispatchToProps = (dispatch) => ({
  fetchSharedBlock(name) {
    dispatch(fetchSharedBlock(name))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SharedBlock)
