/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

// The initial state of the App
const initialState = fromJS({
  loading: undefined,
  error: false,
  userDetails: {},
  threads: {},
  officesPath: undefined,
  needDirectVerification: undefined,
  needUploadVerification: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state.set('loading', true).set('error', false)

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('userDetails', fromJS(action.data.userDetails))
        .set('threads', fromJS(action.data.threads))
        .set('officesPath', fromJS(action.data.officesPath))
        .set('needDirectVerification', action.data.needDirectVerification)
        .set('needUploadVerification', action.data.needUploadVerification)

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
