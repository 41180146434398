/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { LOAD_SHARED_BLOCK, LOAD_SHARED_BLOCK_ERROR, LOAD_SHARED_BLOCK_SUCCESS } from './constants'

const initialState = fromJS({})

export default function sharedBlocksReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SHARED_BLOCK: {
      const sharedBlock = fromJS({
        isLoading: true,
        value: null,
      })

      return state.set(action.key, sharedBlock)
    }

    case LOAD_SHARED_BLOCK_SUCCESS: {
      const sharedBlock = fromJS({
        isLoading: false,
        value: action.value,
      })

      return state.set(action.key, sharedBlock)
    }

    case LOAD_SHARED_BLOCK_ERROR: {
      const sharedBlock = fromJS({
        isLoading: false,
        value: null,
      })

      return state.set(action.key, sharedBlock)
    }

    default:
      return state
  }
}
