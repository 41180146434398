import { useDispatch } from 'react-redux'

import { addFlashMessage } from '../../../containers/MainLayout/actions'
import { FlashMessageTypes } from '../types'

const useAddFlashMessages = () => {
  const dispatch = useDispatch()
  const showFlashMessage = (message: string, type: FlashMessageTypes = 'success') =>
    dispatch(addFlashMessage(message, type))

  return { showFlashMessage }
}

export default useAddFlashMessages
