/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  FORM_MOBILE_CONFIRM_NAME,
  RESEND_SMS,
  RESEND_SMS_SUCCESS,
  RESEND_SMS_ERROR,
  RESENDING_SMS_STATE_KEY,
  USER_MOBILE_STATE_KEY,
} from './constants'

const initialState = fromJS({
  loading: undefined,
  error: false,
  [FORM_MOBILE_CONFIRM_NAME]: {
    fields: [],
  },
  [RESENDING_SMS_STATE_KEY]: false,
  [USER_MOBILE_STATE_KEY]: null,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set(FORM_MOBILE_CONFIRM_NAME, initialState.get(FORM_MOBILE_CONFIRM_NAME))

    case LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(FORM_MOBILE_CONFIRM_NAME, fromJS(action.data[FORM_MOBILE_CONFIRM_NAME]))
        .set(USER_MOBILE_STATE_KEY, fromJS(action.data[USER_MOBILE_STATE_KEY]))

    case LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESEND_SMS:
      return state.set(RESENDING_SMS_STATE_KEY, true)

    case RESEND_SMS_SUCCESS:
      return state.set(RESENDING_SMS_STATE_KEY, false)

    case RESEND_SMS_ERROR:
      return state.set(RESENDING_SMS_STATE_KEY, false).set('error', action.error)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
