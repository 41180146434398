import { isCsrfError, findCsrfTokenInResponse, putTokenToValues } from './csrf'
import { getFlattenValues } from './forms'
import { request, defaultOptions, parseJSON, getFullUrl } from './request'
import { updateContentPath } from './url'

function formatVal(val) {
  if (val !== null && val.constructor === FileList) {
    if (val.length === 1) {
      return val[0]
    }
  }
  return val
}

export function flattenFromValues(values) {
  const flattenValues = getFlattenValues(values)
  const flattenArray = Object.keys(flattenValues)
    .map((key) => {
      const value = flattenValues[key]
      if (value === undefined || value === null) {
        return undefined
      }
      return [key, formatVal(value, key)]
    })
    .filter((v) => v)

  return flattenArray
}

export function getFormDataFromValues(values) {
  const formData = new FormData()
  const flattenArray = flattenFromValues(values)

  flattenArray.forEach((value) => pushValueToFormData(value, formData))

  return formData
}

function pushValueToFormData(element, formData) {
  const [key, value] = element

  if (value.constructor === Array) {
    value.forEach((val) => {
      if (val) {
        pushValueToFormData([`${key}[]`, val], formData)
      }
    })
  } else {
    formData.append(key, value)
  }
}

/**
 * Requests a formdatapost URL, returning a promise
 *
 * @param  {string} url           The URL we want to request
 * @param  {object} values        The values we want to pass to "fetch"
 * @param  {object} [options]     The options we want to pass to "fetch"
 * @param  {string} [newTokenUrl] The options we want to pass to "fetch"
 *
 * @return {promise}               The response data
 */
export default function formdata(url, values, options, newTokenUrl) {
  // console.log(url, values)
  const formData = getFormDataFromValues(values)

  const opts = {
    ...defaultOptions,
    method: 'POST',
    headers: {
      Accept: 'application/json, *.*',
    },
    body: formData,
    ...options,
  }

  return fetch(getFullUrl(url === '' ? window.location.pathname : url), opts)
    .then(parseJSON)
    .catch(
      (error) =>
        new Promise((resolve, reject) => {
          // console.log(error, isCsrfError(error))
          if (isCsrfError(error)) {
            resendWithNewCsrf(newTokenUrl, url, values).then(resolve).catch(reject)
          } else {
            reject(error)
          }
        }),
    )
}

/**
 * Getting new csrf and resenting form with new csrf
 * @param {string} newCsrfUrl url to get new csrf
 * @param {string} formAction url to resend form
 * @param {object} values form values
 *
 * @returns {promise}
 */
function resendWithNewCsrf(newCsrfUrl, formAction, values) {
  return new Promise((resolve, reject) => {
    // console.log('resendWithNewCsrf', newCsrfUrl, formAction, values)
    let url = newCsrfUrl
    if (!url) {
      url = updateContentPath(`${document.location.pathname}${document.location.search}`)
    }
    request(url)
      .then((res) => {
        const newToken = findCsrfTokenInResponse(res.data)
        if (newToken) {
          const newValues = putTokenToValues(newToken, values)

          // console.log(formAction, newValues);
          formdata(formAction, newValues).then(resolve).catch(reject)
        }
      })
      .catch(reject)
  })
}
