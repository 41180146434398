import { Warning } from 'components/Indicator'
import styled from 'styled-components'

export const StyledWarning = styled(Warning)`
  display: flex;
  position: absolute;
  left: -10px;
  top: 8px;
  cursor: pointer;
`
