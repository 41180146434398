import styled from 'styled-components'

import { grayDarker } from '../../utils/style-utils'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  z-index: 10;
  top: 0;
  width: 300px;
  height: 100vh;
  overflow: hidden;

  background: ${grayDarker};
`
