import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Bg from '../Overlay/Bg'
import HeaderAnimateIn from './HeaderAnimateIn'
import InfoAnimateIn from './InfoAnimateIn'
import Wrapper from './Wrapper'
// import styled from 'styled-components'
import mainCasinoBg from './main-header-casino-bg.jpg'

class GameDialog extends React.PureComponent {
  render() {
    const { game, onClose } = this.props
    const bgStyles = {
      backgroundImage: `url(${game.get('backgroundPath') || mainCasinoBg})`,
      opacity: 1,
      mixBlendMode: 'normal',
    }

    return (
      <div>
        <Wrapper>
          <HeaderAnimateIn onClick={onClose} />
          <InfoAnimateIn game={game} />
          <Bg style={bgStyles} />
          <Bg style={{ opacity: 0.5 }} />
        </Wrapper>
      </div>
    )
  }
}

GameDialog.propTypes = {
  game: ImmutablePropTypes.map,
  onClose: PropTypes.func,
}

export default GameDialog
