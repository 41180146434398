import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import { searchDeepForAnyActiveNode } from '../../containers/GenericMenu/menuHandlers'
import Arrow from './Arrow'
import Li from './Li'
import Link from './Link'
import List from './List'

class Element extends React.Component {
  shouldComponentUpdate(nextProps) {
    const changes = [
      nextProps.child.equals(this.props.child),
      nextProps.toggleMenuPosition === this.props.toggleMenuPosition,
      nextProps.toggleMenuSection === this.props.toggleMenuSection,
      nextProps.nestedLevel === this.props.nestedLevel,
    ]
    const isTheSame = changes.reduce((acc, current) => acc && current, true)

    return !isTheSame
  }

  onLinkClick = (id, url, hasActiveChildren) => {
    if (!hasActiveChildren && !url) {
      this.props.toggleMenuSection(id)
    }
    if (!hasActiveChildren && url) {
      this.props.toggleMenuPosition(id)
    }
  }

  onArrowClick = (event, id, hasActiveChildren, isActive) => {
    if (!hasActiveChildren && !isActive) {
      this.props.toggleMenuSection(id)
    }
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    const { child, toggleMenuPosition, nestedLevel, toggleMenuSection } = this.props
    const id = child.get('id')
    const url = child.get('uri')
    const label = child.get('label')
    const isOpen = child.get('isOpen')
    const isActive = child.get('isActive')
    const children = child.get('children')
    const hasChildren = children && children.size > 0
    const hasActiveChildren = hasChildren && searchDeepForAnyActiveNode(children).length > 0

    return (
      <Li
        className={(hasChildren && (isOpen ? 'expanded' : 'collapsed')) || ''}
        id={`element-${id}`}
      >
        <Link
          id={`link-${id}`}
          isActive={isActive}
          nestedLevel={nestedLevel}
          onClick={() => this.onLinkClick(id, url, hasActiveChildren)}
          to={url}
        >
          {label}
          <Arrow
            hasChildren={hasChildren}
            isOpen={isOpen}
            onClick={(event) => this.onArrowClick(event, id, hasActiveChildren, isActive)}
          />
        </Link>
        {hasChildren && (
          <List
            isOpen={isOpen}
            nestedLevel={nestedLevel}
            parent={id.toString()}
            structure={children}
            toggleMenuPosition={toggleMenuPosition}
            toggleMenuSection={toggleMenuSection}
          />
        )}
      </Li>
    )
  }
}

Element.propTypes = {
  child: ImmutablePropTypes.map,
  toggleMenuPosition: PropTypes.func.isRequired,
  toggleMenuSection: PropTypes.func.isRequired,
  nestedLevel: PropTypes.number,
}

export default Element
