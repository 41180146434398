import { useMutation } from '@tanstack/react-query'

import { cancelPendingWithdrawals } from './service'

interface Props {
  onSettled?: () => void
}

export function useCancelAllWithdrawals({ onSettled }: Props) {
  return useMutation<void, { data?: string }>({ mutationFn: cancelPendingWithdrawals, retry: false, onSettled })
}
