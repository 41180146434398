import styled from 'styled-components'

const Clearfix = styled.div.withConfig({
  shouldForwardProp: (prop) => !['background', 'loading'].includes(prop),
})`
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
`

export default Clearfix
