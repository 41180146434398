import styled from 'styled-components'

import { media, bigLg, lg } from '../../utils/style-utils'

const Container = styled.div`
  width: 96%;
  max-width: ${bigLg}px;
  min-width: ${lg - 40}px;
  margin: 0 auto;

  ${media.xl`
    min-width: auto;
  `}

  ${media.lg`
    width: 98%;
  `}

  ${media.md`
    width: 100%;
  `}
`

export default Container
