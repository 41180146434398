import React from 'react'
import { connect } from 'react-redux'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { isCookieSet, deleteCookie } from '../../utils/cookies'
import { bh, grayLighter } from '../../utils/style-utils'
import { makeSelectIsLogged } from '../MainLayout/selectors'
import { SHOW_LIMITS_COOKIE_NAME } from './constants'

const LimitsEditInfo = styled.div`
  color: ${grayLighter};
  margin-bottom: ${bh * 2}px;
  font-size: ${bh * 0.75}px;
  text-align: center;
`

class ChangeLimitInfoBox extends React.Component {
  static propTypes = {
    userIsLogged: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const isCookie = isCookieSet(SHOW_LIMITS_COOKIE_NAME)
    this.state = {
      show: isCookie && props.userIsLogged,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const isCookie = isCookieSet(SHOW_LIMITS_COOKIE_NAME)
    const newShown = props.userIsLogged && isCookie
    if (state.show !== newShown) {
      return { show: newShown }
    }

    return null
  }

  render() {
    if (!this.state.show) {
      return null
    }

    deleteCookie(SHOW_LIMITS_COOKIE_NAME)

    return (
      <LimitsEditInfo id="edit-limit-info">{Translator.trans('edit_limits_after_login', {}, 'users')}</LimitsEditInfo>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userIsLogged: makeSelectIsLogged(),
})

export default connect(mapStateToProps)(ChangeLimitInfoBox)
