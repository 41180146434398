import React from 'react'

import Translator from 'bazinga-translator'
import withCondition from 'components/withCondition'

import { CONFIRM_MOBILE_PATH } from '../../containers/App/constants'
import Button from '../Btn'
import ExclamationTriangle from '../Icons/components/Warning'

function MobileConfirmButtonComponent({ ...props }) {
  return (
    <Button
      link
      to={CONFIRM_MOBILE_PATH}
      danger
      md
      {...props}
    >
      <ExclamationTriangle />
      {Translator.trans('mobile_confirm', {}, 'buttons')}
    </Button>
  )
}

export const MobileConfirmButton = withCondition(MobileConfirmButtonComponent)
