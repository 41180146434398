/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR, FORM_CHANGE_PASSWORD_NAME } from './constants'

// The initial state of the App
const initialState = fromJS({
  loading: undefined,
  error: undefined,
  [FORM_CHANGE_PASSWORD_NAME]: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set(FORM_CHANGE_PASSWORD_NAME, initialState.get(FORM_CHANGE_PASSWORD_NAME))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(FORM_CHANGE_PASSWORD_NAME, fromJS(action.data[FORM_CHANGE_PASSWORD_NAME]))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
