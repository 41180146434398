import React, { lazy, Suspense } from 'react'

import CenteredLoader from 'components/Loader/CenteredLoader'
import { THEME } from 'containers/App/constants'

const HomePageBetor = lazy(() => import(/* webpackChunkName: "HomepageBetor" */ './Betor'))
const CollectPageBetor = lazy(() => import(/* webpackChunkName: "HomepageBetor" */ './Collect'))

export default function LoadableHomePage() {
  const HomePageComponent = THEME === 'collect' ? CollectPageBetor : HomePageBetor

  return (
    <Suspense fallback={<HomePageLoader />}>
      <HomePageComponent />
    </Suspense>
  )
}

function HomePageLoader() {
  return (
    <div style={{ minHeight: 500 }}>
      <CenteredLoader />
    </div>
  )
}
