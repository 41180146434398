import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'
import { isTopInViewportY, scrollToEl } from 'utils/windowHelpers'

import Hr from '../Hr'
import ContentArticle from './ContentArticle'
import Gallery from './Gallery'
import { Skeleton } from './Skeleton'

export default function CmsPageContentHeader({ loading, page }) {
  if (loading) {
    return <Skeleton />
  }

  return (
    <>
      <ContentArticle
        page={page}
        contentRef={contentRef}
      />
      <Gallery gallery={page.get('gallery')} />
      <Hr />
    </>
  )
}

function contentRef(el) {
  if (el && !isTopInViewportY(el)) {
    // IF ELEMENT AND ELEMENT IS NOT IN VIEWPORT
    scrollToEl(el)
  }
}

CmsPageContentHeader.propTypes = {
  loading: PropTypes.bool,
  page: ImmutablePropTypes.map.isRequired,
}
