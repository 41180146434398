import React, { useMemo, ReactNode } from 'react'

import { WebSocketProviderContext } from './context'
import { WebSocketEngine } from './engines/WebSocketEngine'
import { getAuthorizationData } from './service/service'

export function WebSocketProvider({ children }: { children: ReactNode }) {
  const contextValue = useMemo(
    () => ({
      getWebSocketInstance: () => WebSocketEngine.getWebSocketEngine(getAuthorizationData),
    }),
    [],
  )

  return <WebSocketProviderContext.Provider value={contextValue}>{children}</WebSocketProviderContext.Provider>
}
