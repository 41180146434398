import { LOAD_SHARED_BLOCK, LOAD_SHARED_BLOCK_ERROR, LOAD_SHARED_BLOCK_SUCCESS } from './constants'

export function fetchSharedBlock(key) {
  return {
    type: LOAD_SHARED_BLOCK,
    key,
  }
}

export function loadSharedBlockSuccess(key, value) {
  return {
    type: LOAD_SHARED_BLOCK_SUCCESS,
    key,
    value,
  }
}

export function loadSharedBlockError(key, error) {
  return {
    type: LOAD_SHARED_BLOCK_ERROR,
    key,
    error,
  }
}
