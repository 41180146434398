import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import SecondaryHeader from '../../components/SecondaryHeader'
import {
  makeSelectMobileMenuOpen,
  makeSelectLangMenu,
  makeSelectLangMenuOpen,
  makeSelectBranch,
  makeSelectSecondaryMenuItems,
} from '../MainLayout/selectors'
import { toggleLangMenu } from '../MobileMenuBox/actions'

class SecondaryHeaderBox extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    // console.log(this.props.secondaryMenuItems.toJS());
    const { langMenu, secondaryMenuItems } = this.props
    const menuItems = secondaryMenuItems
    return (
      <SecondaryHeader
        {...this.props}
        menu={menuItems}
        langMenu={langMenu}
        langMenuOpen={this.props.langMenuOpen}
        langMenuToggler={this.props.langMenuToggler}
      />
    )
  }
}

SecondaryHeaderBox.propTypes = {
  langMenu: PropTypes.object.isRequired,
  secondaryMenuItems: PropTypes.object.isRequired,
  langMenuOpen: PropTypes.bool.isRequired,
  langMenuToggler: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  branch: makeSelectBranch(),
  secondaryMenuItems: makeSelectSecondaryMenuItems(),
  secondaryMenuOpen: makeSelectMobileMenuOpen(),
  langMenu: makeSelectLangMenu(),
  langMenuOpen: makeSelectLangMenuOpen(),
})

const mapDispatchToProps = (dispatch) => ({
  langMenuToggler() {
    dispatch(toggleLangMenu(false))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryHeaderBox)
