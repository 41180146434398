import styled, { css } from 'styled-components'

import {
  bh,
  lighten,
  formFieldHeight,
  formFieldBg,
  formFieldColor,
  formFieldBorderColor,
  formFieldPlaceholderColor,
  formFieldDisableBg,
  formFieldDisableColor,
  formFieldDisableBorderColor,
  formFieldError,
  formFieldOutline,
  transition,
  defaultAnimationOpacityEase,
  hexRgba,
  formFieldAutocompleteColor,
} from '../../utils/style-utils'

export const input = css`
  display: block;
  width: 100%;
  height: ${formFieldHeight}px;
  padding: 1px ${bh / 1.5}px;
  outline: none;
  color: ${formFieldColor};
  background-color: ${formFieldBg};
  /* background-color: ${formFieldBg}; */
  border: 0 solid ${formFieldBorderColor};
  border-bottom-width: 2px;
  border-radius: 0;
  font-size: ${bh}px;

  ${transition('background-color, border-bottom-color, background-image', 0.4, defaultAnimationOpacityEase)}

  ${(p) =>
    p.xl
      ? `
    font-size: ${bh * 1.5}px;
    height: ${bh * 4}px;
    line-height: ${bh * 4}px;
  `
      : ''}

  ${(p) =>
    p.center
      ? `
    text-align: center;
  `
      : ''}

  ${(p) =>
    p.error
      ? `
    border-bottom-color: ${formFieldError};
    /* box-shadow: 0 0 8px ${formFieldError}; */
  `
      : ''}

  &:-webkit-autofill {
    -webkit-text-fill-color: ${formFieldAutocompleteColor || 'white'};
    -webkit-box-shadow: inset 0 0 0 100px ${formFieldBg};
  }

  &::placeholder {
    color: ${formFieldPlaceholderColor};
  }

  &:focus,
  &:hover {
    background-color: ${lighten(formFieldBg, 0.1)};
    ${(p) =>
      p.error
        ? `
      background-image: linear-gradient(to bottom, ${hexRgba(formFieldError, 0)} 60%, ${hexRgba(
        formFieldError,
        0.2,
      )} 100%);
      border-bottom-color: ${formFieldError};
    `
        : `
      background-image: linear-gradient(to bottom, ${hexRgba(formFieldOutline, 0)} 60%, ${hexRgba(
        formFieldOutline,
        0.2,
      )} 100%);
      border-bottom-color: ${formFieldOutline};
    `}
  }

  &:disabled {
    cursor: not-allowed;
    color: ${formFieldDisableColor};
    background-color: ${formFieldDisableBg};
    border: 1px solid ${formFieldDisableBorderColor};
  }
`

export default styled.input`
  ${input}
`
