import React from 'react'

import Translator from 'bazinga-translator'

import { DASHBOARD_PATH } from '../../containers/App/constants'
import Button from '../Btn'

export function DashboardButton({ ...props }) {
  return (
    <Button
      href={DASHBOARD_PATH}
      secondary
      md
      {...props}
    >
      {Translator.trans('Dashboard', {}, 'buttons')}
    </Button>
  )
}
