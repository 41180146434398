import React from 'react'

import { IconProps } from '../types'

function FavoritesHeart(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      viewBox="0 0 24 24"
      fillRule="nonzero"
      {...props}
    >
      <path d="M16 7a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2Z" />
      <path
        fillRule="evenodd"
        d="M20.924 4.001a6.74 6.74 0 0 1 .5 9.198.999.999 0 0 1-.17.219l-8.558 8.3a1 1 0 0 1-1.392 0l-8.557-8.3a1 1 0 0 1-.17-.218A6.74 6.74 0 0 1 3.076 4C5.508 1.643 9.262 1.371 12 3.177c2.738-1.807 6.492-1.534 8.924.824Zm-8.272 1.18c1.99-1.657 4.998-1.569 6.88.256a4.742 4.742 0 0 1 .264 6.588 1.006 1.006 0 0 0-.07.087L12 19.607l-7.727-7.495a1.005 1.005 0 0 0-.069-.087 4.742 4.742 0 0 1 .264-6.588c1.882-1.825 4.89-1.913 6.88-.257a.994.994 0 0 0 .652.232.997.997 0 0 0 .652-.232Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default FavoritesHeart
