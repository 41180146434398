import { LOCATION_CHANGE } from 'redux-first-history'
import { take, call, put, cancel, takeLatest, all } from 'redux-saga/effects'

import { request } from '../../utils/request'
import { loadSuccess, reloadRegisterFormSuccess, reloadRegisterFormError, loadError } from './actions'
import { LOAD, RELOAD_REGISTER_FORM, SEND_REGISTER_FORM_PATH } from './constants'

export function* initLoad(action) {
  const requestURL = `${action.pathname}?t=${Date.now()}`
  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(request, requestURL)
    yield put(loadSuccess(data.data, data.flashMessages))
  } catch (err) {
    yield put(loadError(err))
  }
}

export function* initData() {
  yield takeLatest(LOAD, initLoad)

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE)
  // yield cancel(watcher)
}

export function* reloadRegisterForm() {
  const requestURL = `${SEND_REGISTER_FORM_PATH}?json=1`
  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(request, requestURL)
    yield put(reloadRegisterFormSuccess(data.data))
  } catch (err) {
    yield put(reloadRegisterFormError(err))
  }
}

export function* watchReloadRegisterForm() {
  const watcher = yield takeLatest(RELOAD_REGISTER_FORM, reloadRegisterForm)

  // Suspend execution until location changes
  yield take(LOCATION_CHANGE)
  yield cancel(watcher)
}

export default function* rootSaga() {
  yield all([initData(), watchReloadRegisterForm()])
}
