import RUIANCityAutocomplete from './AddressOfResidenceFields/RUIANCityAutocomplete'
import RUIANPlaceAutocomplete from './AddressOfResidenceFields/RUIANPlaceAutocomplete'
import RUIANStreetAutocomplete from './AddressOfResidenceFields/RUIANStreetAutocomplete'

export const CITY_FIELD = 'RUIANKodObec'
export const STREET_FIELD = 'RUIANKodUlice'
export const PLACE_FIELD = 'RUIANKodAdresniMisto'
export const ZIP_FIELD = 'zip'
export const STREET_TEXT_FIELD = 'street'
export const CITY_TEXT_FIELD = 'town'
export const BIRTH_PLACE_FIELD = 'RUIANKodMistoNarozeniObec'
export const IS_DEDUPLICATION_NEEDED_FIELD = 'isUserDeduplicationNeeded'

export const FIELD_COMPONENT_MAP = {
  [CITY_FIELD]: RUIANCityAutocomplete,
  [STREET_FIELD]: RUIANStreetAutocomplete,
  [PLACE_FIELD]: RUIANPlaceAutocomplete,
}

export function getExternalRegistrationFieldName(field: string): string {
  return `registration_external[${field}]`
}
