import * as React from 'react'

import { IconProps } from '../types'

function Search(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-8 6a8 8 0 1 1 14.32 4.906l5.387 5.387-1.414 1.414-5.387-5.387A8 8 0 0 1 2 10Zm11.5 0A3.5 3.5 0 0 0 10 6.5v-2a5.5 5.5 0 0 1 5.5 5.5h-2Z"
      />
    </svg>
  )
}
export default Search
