import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import Btn from '../Btn'

function Login({ /* clickHandler, */ open, ...rest }) {
  return (
    <Btn
      primary
      {...rest}
      activeClassName="active"
    >
      {Translator.trans('Log in')}
    </Btn>
  )
}

Login.propTypes = {
  // clickHandler: PropTypes.func,
  open: PropTypes.bool,
}

export default Login
