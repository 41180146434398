import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import { mainHeaderColor } from 'utils/style-utils'

export const MainMenuBox = styled.div`
  flex: 2;

  max-width: 30rem;
  position: relative;
  display: flex;
  height: 100%;
`

export const StyledUrlLink = styled(UrlLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  flex: 1;

  > svg {
    width: 2rem;
    height: 2rem;
    fill: ${mainHeaderColor};
    display: block;
  }
`
