import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import Block from '../../containers/SharedBlock'
import Button from '../Btn'
import Dialog from '../Dialog'
import { ButtonsWrapper } from '../Dialog/ButtonsWrapper'

interface Props {
  showShort: boolean
  toggleShowMore: () => void
  agreeAll: () => void
}

export function SimpleCookieDialog({ showShort, toggleShowMore, agreeAll }: Props) {
  if (!showShort) {
    return null
  }

  return (
    <Dialog
      fullHeight={false}
      className="cookie-info-short"
      title={Translator.trans('terms_and_conditions_of_cookies_header')}
      onClose={() => toggleShowMore()}
      Footer={
        <ButtonsWrapper>
          <Button
            sm
            className="cookie-info-read-more"
            onClick={() => toggleShowMore()}
          >
            {Translator.trans('cookies_settings')}
          </Button>
          <Button
            sm
            primary
            className="cookie-info-agree"
            onClick={agreeAll}
          >
            {Translator.trans('cookies_agree')}
          </Button>
        </ButtonsWrapper>
      }
    >
      <Block name="terms_and_conditions_of_cookies_description_short_version" />
    </Dialog>
  )
}

SimpleCookieDialog.propTypes = {
  showShort: PropTypes.bool,
  toggleShowMore: PropTypes.func,
  agreeAll: PropTypes.func,
}
