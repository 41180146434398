import React from 'react'

import styled from 'styled-components'

import betorLogo from '../../../themes/img/b_round_red.svg'
import { bh } from '../../../utils/style-utils'

const Img = styled.img`
  width: ${bh * 4}px;
  height: auto;
`

function BetorLogo() {
  return (
    <Img
      src={betorLogo}
      alt="betor"
    />
  )
}

export default BetorLogo
