import { z } from 'zod'

const flashMessage = z.object({
  text: z.string(),
  type: z.string().optional(),
})

export const flashMessagesResponseSchema = z.object({
  flashMessages: z.array(flashMessage).optional(),
})
