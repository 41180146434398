import React from 'react'

export const width = 156
export const height = 43

export default function BtnBg(props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 156 43"
      {...props}
    >
      <path
        d="M685.85,835.071L836,832l-12.675,34.44L680,875Z"
        transform="translate(-680 -832)"
      />
    </svg>
  )
}
