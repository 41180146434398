import { useSelector } from 'react-redux'

import { useQuery, UseQueryOptions, keepPreviousData } from '@tanstack/react-query'

import { GamesWithCategoriesAndUserInfo } from '../../../containers/GamesBlock/types'
import { makeSelectUser } from '../../../containers/MainLayout/selectors'
import useGamesSlugsInOngoingTournaments from '../Tournaments/useGamesSlugsInTournaments'
import { GAMES_CACHE_TIME } from './constants'
import { useGamesWithCategories } from './useGamesWithCategories'
import useGamesWithCategoriesAndUserCacheKey from './useGamesWithCategoriesAndUserCacheKey'
import { hydrateGamesWithUserInformation } from './utils'

interface Props<TSelectData> {
  select?: (data: GamesWithCategoriesAndUserInfo) => TSelectData
  options?: Omit<UseQueryOptions<ReturnType<typeof hydrateGamesWithUserInformation>, unknown, TSelectData>, 'select'>
}

export function useGamesWithCategoriesAndUserInfo<TSelectData>({
  options,
  select = (games) => games as TSelectData,
}: Props<TSelectData> = {}) {
  const user = useSelector(makeSelectUser())?.toJS()
  const { data: gamesWithCategories, ...props } = useGamesWithCategories()
  const gamesSlugsInTournaments = useGamesSlugsInOngoingTournaments()
  const cacheKey = useGamesWithCategoriesAndUserCacheKey()

  const { data: transformedData, ...transformedDataProps } = useQuery({
    queryKey: cacheKey,
    queryFn: () =>
      new Promise((resolve) => {
        resolve(hydrateGamesWithUserInformation(gamesWithCategories, user, gamesSlugsInTournaments))
      }) as any,
    ...options,
    enabled: !!gamesWithCategories && (options?.enabled ?? true),
    placeholderData: keepPreviousData,
    select,
    gcTime: GAMES_CACHE_TIME,
    staleTime: GAMES_CACHE_TIME,
  })

  return { data: transformedData, ...props, ...transformedDataProps }
}
