import styled from 'styled-components'

import { media } from '../../utils/style-utils'

export default styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${media.xs`
     padding-bottom: 50px;
  `}
`
