import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import { white, media, bh } from '../../utils/style-utils'
import Buttons from './Buttons'
import { height as headerHeight } from './Header'

const H1 = styled.h1`
  margin: 0;
  color: ${white};
  text-align: center;
`

const Div = styled.div`
  position: absolute;
  top: ${headerHeight}px;
  left: 0;
  width: 100%;
  height: calc(100% - ${headerHeight}px);
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.landscapeMd`
    top: 0
    right: ${headerHeight}px;
    height: 100%;
    width: calc(100% - ${headerHeight}px);
  `}
`

const ButtonsWrapper = styled.div`
  margin: ${bh * 2}px;
`

function Info({ game, ...rest }) {
  return (
    <Div {...rest}>
      <H1>{game.get('name')}</H1>
      <ButtonsWrapper>
        <Buttons game={game} />
      </ButtonsWrapper>
    </Div>
  )
}

Info.propTypes = {
  game: ImmutablePropTypes.map,
}

export default Info
