import styled from 'styled-components'

import { cmsContentSidebar3DeepBg, cmsContentSidebarBg } from '../../utils/style-utils'

export default styled.ul`
  ${(props) => (!props.isOpen ? 'display: none' : '')};
  ${(props) =>
    props.isTopNode ? `background-color: ${cmsContentSidebarBg};` : `background-color: ${cmsContentSidebar3DeepBg};`};

  list-style-type: none;
  margin-top: 0;
  padding: 0;
`
