import styled from 'styled-components'

import { bh } from '../../utils/style-utils'

export default styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap = 0 }) => gap};

  justify-content: ${(p) => {
    if (p.left) {
      return 'left'
    }

    return 'space-between'
  }};
  align-items: ${(p) => {
    if (p.top) {
      return 'top'
    }
    if (p.bottom) {
      return 'baseline'
    }
    if (p.stretch) {
      return 'stretch'
    }

    return 'center'
  }};

  ${(p: any) =>
    p.tableStyle
      ? `
    padding: ${bh / 2}px ${bh}px;
    background: rgba(0,0,0,0.0);

    &:nth-child(even) {
      background: rgba(0,0,0,0.1);
    }

    :hover {
      background: rgba(0,0,0,0.2);

      &:nth-child(even) {
        background: rgba(0,0,0,0.3);
      }
    }
  `
      : ''}

  ${(p: any) =>
    p.keyVal
      ? `
    padding: ${bh / 4}px 0;

    &:last-child {
      border-bottom: 0;
    }
  `
      : ''}
`
