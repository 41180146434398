import React from 'react'

export default function Twitter(props) {
  return (
    <svg
      width="24.59"
      height="25"
      viewBox="0 0 24.59 25"
      {...props}
    >
      <path
        d="M1601.49,92.014c0-.126,0-0.251-0.01-0.374a5.78,5.78,0,0,0,1.36-1.483,5.157,5.157,0,0,1-1.56.434,2.809,2.809,0,0,0,1.19-1.586,5.1,5.1,0,0,1-1.72.677,2.714,2.714,0,0,0-1.99-.952,2.765,2.765,0,0,0-2.71,2.879,2.936,2.936,0,0,0,.07.667,7.62,7.62,0,0,1-5.6-3.144,3.026,3.026,0,0,0-.37,1.477,3.058,3.058,0,0,0,1.21,2.468,2.54,2.54,0,0,1-1.23-.383V92.73a2.918,2.918,0,0,0,2.18,2.906,2.532,2.532,0,0,1-.72.1,2.287,2.287,0,0,1-.51-0.056,2.777,2.777,0,0,0,2.54,2.056,5.194,5.194,0,0,1-4.03,1.207,7.29,7.29,0,0,0,4.17,1.327C1598.76,100.268,1601.49,95.849,1601.49,92.014Zm7.02,2.485a12.3,12.3,0,1,0-12.29,12.5A12.393,12.393,0,0,0,1608.51,94.5Zm-23.47,0a11.181,11.181,0,1,1,11.18,11.362A11.269,11.269,0,0,1,1585.04,94.5Z"
        transform="translate(-1583.91 -82)"
      />
    </svg>
  )
}
