import React, { CSSProperties, ReactNode } from 'react'

import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { pulse } from 'utils/style-animations'

import {
  bh,
  defaultAnimationEase,
  boxHeaderBg,
  boxHeaderColor,
  boxSubHeaderBg,
  boxSubHeaderColor,
  boxContentBg,
  boxContentColor,
  boxFooterBg,
  boxFooterColor,
  warningBg,
  warningColor,
  fontWeightBold,
} from '../../utils/style-utils'
import Clearfix from '../Clearfix'

interface Props {
  Header?: ReactNode
  SubHeader?: ReactNode
  Footer?: ReactNode
  contentPadding?: boolean
  margin?: boolean
  fullHeight?: boolean
  warning?: boolean
  children?: ReactNode
  style?: CSSProperties
  skeleton?: boolean
}

const HeaderAnimIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1, 0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
const shouldForward = {
  shouldForwardProp: (prop: any) => !['fullHeight', 'contentPadding', 'margin', 'skeleton'].includes(prop),
}

const StyledBox = styled.div.withConfig(shouldForward)<Props>`
  margin-bottom: ${(p) => (p.margin ? `${bh * 2}px` : '1px')};
  background: ${boxContentBg};
  color: ${boxContentColor};

  ${(p) =>
    p.fullHeight
      ? css`
          display: flex;
          flex-direction: column;
        `
      : ''}
  ${(p) =>
    p.skeleton
      ? css`
          animation: 2s ${pulse} infinite linear;
          min-height: 160px;
        `
      : ''}
`

const StyledHeader = styled.div.withConfig(shouldForward)<Props>`
  padding: ${bh * 0.75}px ${bh}px;
  font-weight: ${fontWeightBold};

  animation: 0.2s ${HeaderAnimIn};
  animation-timing-function: ${defaultAnimationEase};

  background: ${boxHeaderBg};
  color: ${boxHeaderColor};

  ${(p) =>
    p.warning
      ? css`
          background: ${warningColor};
          color: ${warningBg};
        `
      : ''}
`

const StyledSubHeader = styled.div`
  background: ${boxSubHeaderBg};
  color: ${boxSubHeaderColor};
  padding: 0 ${bh}px;
  line-height: ${bh * 2}px;
  animation: 0.4s ${HeaderAnimIn};
  animation-timing-function: ${defaultAnimationEase};
`

const StyledFooter = styled.div.withConfig(shouldForward)<Props>`
  background: ${boxFooterBg};
  color: ${boxFooterColor};
  padding: ${(p) => (p.contentPadding ? `${bh}px` : 0)};
`

export const StyledContent = styled(Clearfix).withConfig(shouldForward)<Props>`
  padding: ${(p) => (p.contentPadding ? `${bh}px ${bh}px` : 0)};

  ${(p) =>
    p.fullHeight
      ? css`
          flex: 1;
        `
      : ''}
`

function Box({ Header, SubHeader, Footer, children, contentPadding, fullHeight, margin, warning, ...rest }: Props) {
  return (
    <StyledBox
      margin={margin}
      fullHeight={fullHeight}
      {...rest}
    >
      {Header && <StyledHeader warning={warning}>{Header}</StyledHeader>}
      {SubHeader && <StyledSubHeader>{SubHeader}</StyledSubHeader>}

      <StyledContent
        contentPadding={contentPadding}
        fullHeight={fullHeight}
      >
        {children}
      </StyledContent>

      {Footer && <StyledFooter contentPadding={contentPadding}>{Footer}</StyledFooter>}
    </StyledBox>
  )
}

Box.propTypes = {
  Header: PropTypes.any,
  SubHeader: PropTypes.any,
  Footer: PropTypes.any,
  contentPadding: PropTypes.bool,
  margin: PropTypes.bool,
  fullHeight: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.any,
}

Box.defaultProps = {
  contentPadding: true,
  margin: true,
}

export default Box
