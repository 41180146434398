import * as React from 'react'

import { IconProps } from '../types'

function Diamond(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.887 2.838A2 2 0 0 1 7.515 2H16.485a2 2 0 0 1 1.628.838l4.242 5.939a2 2 0 0 1-.118 2.475l-8.728 10.036a2 2 0 0 1-3.018 0L1.763 11.252a2 2 0 0 1-.118-2.475l4.242-5.94ZM12.382 4h-.764l-2.5 5h5.764l-2.5-5Zm4.736 5-2.5-5h1.867l3.572 5h-2.939Zm-2.061 2H8.943L12 19.152 15.057 11ZM6.882 9l2.5-5H7.515L3.943 9h2.939Zm-.075 2 1.98 5.282L4.196 11h2.612Zm8.405 5.282L17.193 11h2.612l-4.593 5.282Z"
      />
    </svg>
  )
}
export default Diamond
