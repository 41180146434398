import { request } from '../../../utils/request'
import { getBaseRUIANURL } from '../utils'
import { cityArrayValidator } from './validator'

export async function getCitySuggestions(query: string) {
  const { data } = await request(`${getBaseRUIANURL()}?q=${query}`)

  const validatedData = await cityArrayValidator.validate(data)

  return validatedData.map((city) => ({
    id: city.id,
    label: `${city.name} (${city.group})`,
    entity: city,
  }))
}
