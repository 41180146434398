import styled from 'styled-components'

import { media } from '../../utils/style-utils'

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0;
  width: 100%;
  max-width: 1920px;
  height: 1000px;
  background-image: url(${(props) => props.img.get('big')});
  background-size: cover;

  ${media.sm`
    height: auto;
    background-position: center;
  `}
  ${media.md`
    justify-content: center;
  `}
  ${media.lg`
    justify-content: center;
  `}
`
