import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import { makeSelectIsLogged, makeSelectUser, makeSelectReloading } from '../MainLayout/selectors'

class AuthContainer extends React.PureComponent {
  render() {
    const { renderLoggedIn, renderLoggedOut, isLogged, user, reloading } = this.props

    if (isLogged) {
      return renderLoggedIn ? renderLoggedIn(user, reloading) : null
    }

    return renderLoggedOut ? renderLoggedOut(user, reloading) : null
  }
}

AuthContainer.propTypes = {
  isLogged: PropTypes.bool,
  reloading: PropTypes.bool,
  user: ImmutablePropTypes.map.isRequired,
  renderLoggedIn: PropTypes.func,
  renderLoggedOut: PropTypes.func,
}

export default connect(
  createStructuredSelector({
    isLogged: makeSelectIsLogged(),
    user: makeSelectUser(),
    reloading: makeSelectReloading(),
  }),
)(AuthContainer)
