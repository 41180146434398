import React from 'react'

export default function Amusnet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 16.933 16.933"
      {...props}
    >
      <path d="M3.437 8.486C3.45 7.067 4.81 5.241 7.132 4.243c4.084-1.757 6.425-.137 7.03 1.394.565 1.427-.552 3.933-2.485 5.8 1.38-.972 2.507-2.018 3.289-3.034 1.038-1.349 1.467-2.644 1.072-3.65-.582-1.487-2.858-1.965-5.744-1.564-2.885.4-5.101 1.098-6.882 1.988-1.98.99-3.002 2.167-3.148 3.51-.212 1.95 1.91 4.57 6.764 4.893 2.551.17 4.998.226 9.736-.906-.564.017-13.38 1.584-13.327-4.19Z" />
      <path d="M8.15 10.755c1.521 0 2.761-1.22 2.761-2.718 0-1.497-1.24-2.717-2.76-2.717-1.522 0-2.76 1.221-2.76 2.717s1.24 2.718 2.76 2.718z" />
    </svg>
  )
}
