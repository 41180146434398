import { all, call, put, takeLatest } from 'redux-saga/effects'

import { request } from './request'
import { updateContentPath } from './url'

/**
 * Function to generate standard content sagas
 * @param {string} start Action type to start loading
 * @param {string} success Action type to successfully finish loading
 * @param {string} failAction type to fail on finish loading
 * @param {?string} customUrl url to send request to
 */
export function getGeneratorsArray(start, success, fail, customUrl) {
  function* contentLoad() {
    const {
      location: { pathname, search },
    } = window
    const url = customUrl || `${pathname}${search}`
    const requestURL = updateContentPath(url)

    try {
      const { data, flashMessages } = yield call(request, requestURL)

      yield put({ type: success, data, flashMessages })
    } catch (error) {
      yield put({ type: fail, error })
    }
  }

  function* contentData() {
    yield takeLatest(start, contentLoad)
  }

  return [contentData]
}

export default function generate(start, success, fail, customUrl) {
  const generators = getGeneratorsArray(start, success, fail, customUrl)

  return function* rootSaga() {
    yield all([...generators.map((generator) => generator())])
  }
}
