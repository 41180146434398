import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import MobileMenu from '../../components/MobileMenu'
import {
  makeSelectMobileMenuOpen,
  makeSelectLangMenu,
  makeSelectLangMenuOpen,
  makeSelectBranch,
  makeSelectMobileMenuItems,
} from '../MainLayout/selectors'
import { toggleMobileMenu, toggleLangMenu, toggleGenericMenuPosition, toggleGenericMenuSection } from './actions'

class MobileMenuBox extends React.PureComponent {
  constructor() {
    super()
  }

  togglePosition = (id) => {
    this.props.toggleMenuPosition(id)
  }

  toggleSection = (id) => {
    this.props.toggleMenuSection(id)
  }

  render() {
    const { langMenu, mobileMenuItems, langMenuOpen, langMenuToggler, toggleMenu, mobileMenuOpen } = this.props

    return (
      <MobileMenu
        {...this.props}
        menuOpen={mobileMenuOpen}
        langMenu={langMenu}
        langMenuOpen={langMenuOpen}
        langMenuToggler={langMenuToggler}
        clickHandler={toggleMenu}
        toggleMenuPosition={this.togglePosition}
        structure={mobileMenuItems}
        toggleMenuSection={this.toggleSection}
      />
    )
  }
}

MobileMenuBox.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  langMenu: PropTypes.object.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  mobileMenuItems: PropTypes.object.isRequired,
  langMenuOpen: PropTypes.bool.isRequired,
  langMenuToggler: PropTypes.func.isRequired,
  toggleMenuPosition: PropTypes.func,
  toggleMenuSection: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  branch: makeSelectBranch(),
  mobileMenuItems: makeSelectMobileMenuItems(),
  mobileMenuOpen: makeSelectMobileMenuOpen(),
  langMenu: makeSelectLangMenu(),
  langMenuOpen: makeSelectLangMenuOpen(),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  toggleMenu() {
    dispatch(toggleMobileMenu(false))
  },
  langMenuToggler() {
    dispatch(toggleLangMenu(false))
  },
  toggleMenuPosition: (id) => dispatch(toggleGenericMenuPosition(id, 'mobile')),
  toggleMenuSection: (id) => dispatch(toggleGenericMenuSection(id, 'mobile')),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuBox)
