import {
  API_BASE_PATH,
  HOME_PATH,
  SUCCESS_REDIRECT,
  SUCCESS_FORMSUBMIT,
  ERROR_LOGINREQUIRED,
  COOKIE_SK_PLAYER_GAMES_VALUES,
  DEBUG,
} from '../containers/App/constants'
import { LOGIN_ADDRESS } from '../containers/LoginPage/constants'
import { isCookieSet } from './cookies'
import { getSearchParamByName, redirect, isRedirectNeeded } from './url'

/**
 * @typedef ResponseJson
 * @type {Object}
 * @property {boolean} success
 * @property {string=} type
 * @property {string=} error
 * @property {Object|string} data Date object of response
 */

/**
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {Promise<ResponseJson>}      The response data
 */

export function request(url, options) {
  return fetch(getFullUrl(url), { ...defaultOptions, ...options }).then(parseJSON)
}

/**
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {Promise<ResponseJson>}           The response data
 */
export function post(url, options) {
  return request(getFullUrl(url), { method: 'POST', ...options })
}

/**
 * @param  {object} response A response from a network request
 * @return {Promise<ResponseJson>}
 */
export function parseJSON(response) {
  return new Promise((resolve, reject) => {
    response
      .json()
      .then((responseJson) => processJson(responseJson).then(resolve).catch(reject))
      .catch(() => {
        reject(makeErrorFromResponse(response))
      })
  })
}

export const defaultOptions = {
  credentials: DEBUG ? 'include' : 'same-origin',
  headers: {
    accept: 'application/json, */*',
    'Content-Type': 'application/json, */*',
    // 'X-Requested-With': 'XMLHttpRequest'
  },
}

function isOkLoginRedirect(url) {
  const isBaseUrl = url.indexOf(window.location.origin) === 0
  const isRelative = url.indexOf('/') === 0
  return isRelative || isBaseUrl
}

function getRedirectUrl(url, currentUrl) {
  if (currentUrl.indexOf('login?redirect=') > 0) {
    const redirectUrl = getSearchParamByName('redirect', currentUrl)
    if (isOkLoginRedirect(redirectUrl)) {
      return redirectUrl
    }
  }

  return url.replace('multilotto_site_homepage', HOME_PATH)
}

function redirectWithTimeout(url) {
  setTimeout(() => {
    /**
     *  if redirect is needed and we need to display sk bet_win_dialog
     *  then redirect to homepage
     */
    if (isRedirectNeeded(url) && isCookieSet(COOKIE_SK_PLAYER_GAMES_VALUES)) {
      redirect(HOME_PATH)
    } else {
      redirect(url)
    }
  }, 200)
}

function sendSuccessSubmitResponseForm(formHtml) {
  const div = document.createElement('div')
  div.innerHTML = formHtml
  document.body.appendChild(div)
  const form = div.querySelector('form')
  form.submit()
}

/**
 * @param {ResponseJson} responseJson
 * @returns {boolean}
 */
function isSuccessResponse(responseJson) {
  return responseJson.success === true
}

/**
 * @param {ResponseJson} responseJson
 * @returns {boolean}
 */
function isSuccessRedirectResponse(responseJson) {
  return isSuccessResponse(responseJson) && responseJson.type === SUCCESS_REDIRECT
}

/**
 * @param {ResponseJson} responseJson
 * @returns {boolean}
 */
function isSuccessSubmitResponse(responseJson) {
  return isSuccessResponse(responseJson) && responseJson.type === SUCCESS_FORMSUBMIT
}

/**
 * @param {ResponseJson} responseJson
 * @returns {boolean}
 */
function isLoginRequiredResponse(responseJson) {
  return responseJson.type === ERROR_LOGINREQUIRED
}

/**
 * @param {ResponseJson} responseJson
 * @return {Promise<ResponseJson>}
 */
function processSuccessfulJson(responseJson) {
  return new Promise((resolve) => {
    if (isSuccessSubmitResponse(responseJson)) {
      sendSuccessSubmitResponseForm(responseJson.data)
      return
    }

    if (isSuccessRedirectResponse(responseJson)) {
      redirectWithTimeout(getRedirectUrl(responseJson.data, window.location.href))
    }

    resolve(responseJson)
  })
}

/**
 * @param {ResponseJson} responseJson
 * @return {Promise<ResponseJson>}
 */
function processFailedJson(responseJson) {
  return new Promise((resolve, reject) => {
    if (isLoginRequiredResponse(responseJson)) {
      redirect(`${LOGIN_ADDRESS}?redirect=${window.location.href}&msg=${responseJson.data}`)
    } else if (
      !responseJson.error &&
      responseJson.type !== 'error_message' &&
      responseJson.type !== 'form_error' &&
      responseJson.type !== 'data'
    ) {
      console.log('NO ERR in JSON', responseJson) // eslint-disable-line no-console
      reject({ error: 'Page not found', data: 'error', success: false })
    } else {
      reject(responseJson)
    }
  })
}

/**
 * @param {ResponseJson} responseJson
 * @return {Promise<ResponseJson>}
 */
function processJson(responseJson) {
  if (isSuccessResponse(responseJson)) {
    return processSuccessfulJson(responseJson)
  }

  return processFailedJson(responseJson)
}

function makeErrorFromResponse(response) {
  const error = new Error(response.statusText)
  error.response = response
  return error
}

export function getFullUrl(url) {
  return isAbsoluteUrl(url) ? url : `${API_BASE_PATH}${url}`
}

function isAbsoluteUrl(url) {
  try {
    return !!new URL(url)
  } catch (_) {
    return false
  }
}
