import React from 'react'

import PropTypes from 'prop-types'

import GenericMenu from '../../containers/GenericMenu'
import DivWrapper from '../GenericMenu/DivWrapper'
import LanguageSwitcher from '../LanguageSwitcher'
import Branch from '../SecondaryHeader/Branch'
import { withSwipe } from '../WithSwipe'
import MenuContainer from './MenuContainer'
import Overlay from './Overlay'
import Top from './Top'
import Wrapper from './Wrapper'

const SwipeableWrapper = withSwipe(Wrapper)

export default function MobileMenu({
  clickHandler,
  menuOpen,
  langMenu,
  langMenuOpen,
  langMenuToggler,
  branch,
  structure,
  toggleMenuPosition,
  toggleMenuSection,
}) {
  return (
    <SwipeableWrapper
      className="mobile-menu"
      open={menuOpen}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onLeftSwipe={() => clickHandler()}
      onRightSwipe={() => null}
    >
      <MenuContainer className="menu-container">
        <Top clickHandler={clickHandler} />
        <GenericMenu
          structure={structure}
          toggleMenuPosition={toggleMenuPosition}
          toggleMenuSection={toggleMenuSection}
          Wrap={DivWrapper}
        />
        <LanguageSwitcher
          langMenu={langMenu}
          open={langMenuOpen}
          clickHandler={langMenuToggler}
        />
        <Branch branch={branch} />
      </MenuContainer>
      <Overlay
        className="overlay"
        onClick={() => clickHandler()}
      />
    </SwipeableWrapper>
  )
}

MobileMenu.propTypes = {
  clickHandler: PropTypes.func,
  menuOpen: PropTypes.bool,
  langMenu: PropTypes.object,
  langMenuOpen: PropTypes.bool,
  branch: PropTypes.string,
  langMenuToggler: PropTypes.func,
  structure: PropTypes.object,
  toggleMenuPosition: PropTypes.func.isRequired,
  toggleMenuSection: PropTypes.func.isRequired,
}
