import React from 'react'

import Translator from 'bazinga-translator'

import Btn from '../Btn'

function Register({ ...rest }) {
  return (
    <Btn
      link
      {...rest}
    >
      {Translator.trans('Register')}
    </Btn>
  )
}

export default Register
