import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import A from './A'

const StyledLi = styled.li`
  padding: 0 10px 0 10px;
`

function Li({ item }) {
  return (
    <StyledLi>
      <A item={item} />
    </StyledLi>
  )
}

Li.propTypes = {
  item: PropTypes.node,
}

export default Li
