import * as React from 'react'

import { IconProps } from '../types'

function StopwatchStop(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.895 3H13v.91c4.795.502 8.526 4.576 8.526 9.519 0 5.28-4.26 9.571-9.526 9.571s-9.526-4.29-9.526-9.571c0-4.943 3.73-9.017 8.526-9.52V3h-.895V1h3.79v2ZM3.709 7.467 6.551 4.61 5.133 3.2 2.291 6.056l1.418 1.41Zm13.74-2.857 2.842 2.857 1.418-1.41-2.842-2.858-1.418 1.41ZM4.474 13.429c0-4.187 3.374-7.572 7.526-7.572 4.152 0 7.526 3.385 7.526 7.572C19.526 17.615 16.152 21 12 21c-4.152 0-7.526-3.385-7.526-7.571ZM10 11h4v4h-4v-4Zm-2 6V9h8v8H8Z"
      />
    </svg>
  )
}
export default StopwatchStop
