import React from 'react'

import PropTypes from 'prop-types'

function Span({ label }) {
  if (!label) {
    return null
  }

  return <span>{label}</span>
}

Span.propTypes = {
  label: PropTypes.string,
}

export default Span
