import React from 'react'

import { useUser } from 'containers/MainLayout/selectors'

import { Email, Name, ProfileBox } from './styles'

export function Profile() {
  const { fullname, email } = useUser()

  return (
    <ProfileBox>
      <Name>{fullname}</Name>
      <Email>{email}</Email>
    </ProfileBox>
  )
}
