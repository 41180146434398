import { useChangeFormValue } from '../../../../../../containers/RegisterPage/utils'
import {
  CITY_TEXT_FIELD,
  getExternalRegistrationFieldName,
  PLACE_FIELD,
  STREET_FIELD,
  STREET_TEXT_FIELD,
  ZIP_FIELD,
} from '../utils'

export function useClearFieldsOnCityChange(): () => void {
  const clearTownText = useChangeFormValue(getExternalRegistrationFieldName(CITY_TEXT_FIELD))
  const clearStreetText = useChangeFormValue(getExternalRegistrationFieldName(STREET_TEXT_FIELD))
  const clearZipText = useChangeFormValue(getExternalRegistrationFieldName(ZIP_FIELD))
  const clearStreetSelect = useChangeFormValue(getExternalRegistrationFieldName(STREET_FIELD))
  const clearPlaceSelect = useChangeFormValue(getExternalRegistrationFieldName(PLACE_FIELD))

  return () => {
    clearTownText('')
    clearStreetText('')
    clearZipText('')
    clearStreetSelect(null)
    clearPlaceSelect(null)
  }
}
