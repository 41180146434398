import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Translator from 'bazinga-translator'

import { PLAYER_VERIFICATION_STATUS_ADDRESS } from '../../containers/App/constants'
import Auth from '../../containers/Auth'
import { LOGIN_URL, REGISTER_URL } from '../../containers/LoginPage/constants'
import Btn from '../Btn'
import DoubleClaim from '../DoubleClaim'

export default function Buttons({ game }) {
  return (
    <Auth
      renderLoggedIn={(user) => loggedIn(game, user)}
      renderLoggedOut={(user) => loggedOut(game, user)}
    />
  )
}

Buttons.propTypes = {
  game: ImmutablePropTypes.map,
}

function loggedIn(game, user) {
  const isPlayForFunPermitted = user && user.get('isPlayForFunPermitted')
  const isRealPlayPermitted = user && user.get('isRealPlayPermitted')

  if (!isRealPlayPermitted) {
    return (
      <Btn
        xl
        svg
        danger
        to={PLAYER_VERIFICATION_STATUS_ADDRESS}
      >
        {Translator.trans('your_account_is_limited', {}, 'users')}
      </Btn>
    )
  }

  if (isPlayForFunPermitted) {
    return (
      <DoubleClaim
        topLabel={Translator.trans('play_for_real', {}, 'games')}
        bottomLabel={Translator.trans('play_for_fun', {}, 'games')}
        topClick={() => {
          window.location.href = game.get('realPlayUrl')
        }}
        bottomClick={() => {
          window.location.href = game.get('forFunPlayUrl')
        }}
      />
    )
  }

  return (
    <Btn
      xl
      svg
      secondary
      href={game.get('realPlayUrl')}
    >
      {Translator.trans('play_for_real', {}, 'games')}
    </Btn>
  )
}

function loggedOut(game, user) {
  if (user.get('isPlayForFunPermitted')) {
    return (
      <DoubleClaim
        topLabel={Translator.trans('login and play', {}, 'games')}
        bottomLabel={Translator.trans('play_for_fun', {}, 'games')}
        topClick={() => {
          window.location.href = LOGIN_URL
        }}
        bottomClick={() => {
          window.location.href = game.get('forFunPlayUrl')
        }}
      />
    )
  }

  return (
    <DoubleClaim
      topLabel={Translator.trans('login and play', {}, 'games')}
      bottomLabel={Translator.trans('Register')}
      topClick={() => {
        window.location.href = LOGIN_URL
      }}
      bottomClick={() => {
        window.location.href = REGISTER_URL
      }}
    />
  )
}
