/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { LOGIN_FORM_LOAD, LOGIN_FORM_LOAD_SUCCESS, LOGIN_FORM_LOAD_ERROR } from './constants'

// The initial state of the App
export const initialState = fromJS({
  loading: undefined,
  firstLoad: true,
  loginWithCollect: false,
  loginWithBankIdCz: false,
  error: false,
  form: {},
})

function LoginFormReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_FORM_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('loginWithCollect', initialState.get('loginWithCollect'))
        .set('loginWithBankIdCz', initialState.get('loginWithBankIdCz'))
    case LOGIN_FORM_LOAD_SUCCESS:
      return state
        .set('form', fromJS(action.data))
        .set('loading', false)
        .set('firstLoad', false)
        .set('loginWithCollect', action.data.login_with_collect)
        .set('loginWithBankIdCz', action.data.login_with_bank_id_cz)
        .set('error', false)
    case LOGIN_FORM_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false).set('firstLoad', false)

    default:
      return state
  }
}

export default LoginFormReducer
