import styled from 'styled-components'

import { infoBg, infoColor } from '../../utils/style-utils'
import Alert from './index'

export default styled(Alert).attrs({
  alertType: 'info',
})`
  color: ${infoColor};
  background: ${infoBg};

  .alert-icon {
    fill: ${infoColor};
  }
`
