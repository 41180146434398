import React from 'react'

import { GameDialogWithData } from './GameDialogWithData'
import useGameDialog from './hooks/useGameDialog'

export function GameDialogBox() {
  const { gameDialog, changeGameDialog } = useGameDialog()

  if (!gameDialog) {
    return null
  }

  return (
    <GameDialogWithData
      slug={gameDialog}
      onClose={() => changeGameDialog('')}
    />
  )
}
