import React from 'react'

export default function Love() {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 28"
    >
      <path
        d="M1319.35,342.75a9.643,9.643,0,0,0-13.41-.074l-0.92.891-0.92-.891a9.651,9.651,0,0,0-13.34,0,8.938,8.938,0,0,0,0,12.918l11.79,11.417a3.579,3.579,0,0,0,4.94,0l11.71-11.342A9.026,9.026,0,0,0,1319.35,342.75Zm-1.39,11.722-11.71,11.343a1.79,1.79,0,0,1-2.47,0L1292,354.4a7.28,7.28,0,0,1,0-10.526,7.85,7.85,0,0,1,10.86,0l0.92,0.892-1.41,1.366a0.829,0.829,0,0,0,0,1.2,0.9,0.9,0,0,0,1.24,0l3.56-3.454a7.859,7.859,0,0,1,10.93.061A7.348,7.348,0,0,1,1317.96,354.472Zm1-5.721a6.041,6.041,0,0,0-1.85-3.982,6.422,6.422,0,0,0-2.79-1.584,0.874,0.874,0,0,0-1.07.588,0.842,0.842,0,0,0,.6,1.042,4.7,4.7,0,0,1,2.03,1.149,4.446,4.446,0,0,1,1.34,2.891,0.861,0.861,0,0,0,.87.794l0.06,0A0.85,0.85,0,0,0,1318.96,348.751Z"
        transform="translate(-1288 -340)"
      />
    </svg>
  )
}
