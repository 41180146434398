import { detect } from 'detect-browser'

export function isBrowser() {
  return detect()
}

export function isBrowserCompatible() {
  const browser = detect()

  const { name, version: ver } = browser
  const version = Number(ver.substring(0, ver.indexOf('.')))

  return (
    (name === 'ie' && version > 100) || // no-ie
    (name === 'chrome' && version >= 30) ||
    (name === 'crios' && version >= 30) || // crios === ios chrome
    (name === 'firefox' && version >= 27) ||
    (name === 'opera' && version >= 17) ||
    (name === 'safari' && version >= 7) ||
    (name === 'ios' && version >= 5) ||
    (name === 'android' && version >= 5)
  )
}
