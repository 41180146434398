import styled from 'styled-components'

export default styled.div`
  position: fixed;
  overflow-y: auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  text-align: center;

  &:before {
    ${(p) =>
      p.noClearFix
        ? ''
        : `
      content:  '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    `}
  }
`
