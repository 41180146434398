/* eslint-disable react/style-prop-object */
import React from 'react'

export default function Kajot(props) {
  return (
    <svg
      version="1.1"
      id="Vrstva_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 614 613"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Layer_2">
        <g id="Layer_1-2">
          <radialGradient
            id="SVGID_1_"
            cx="352.6277"
            cy="-103.6443"
            r="360.1485"
            gradientTransform="matrix(1 0 0 1 0 330.8155)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" />
            <stop offset="1" />
          </radialGradient>
          <path
            className="st0"
            d="M513,95.2C448.7,35.1,359.8,4,275.5,11.1H274v0.4h-0.4v-0.7c-31.7,3-62.7,11.3-91.6,24.8
          C84.6,79.2,20.1,168.7,9.5,276.7h1.2v0.8H9.5c-5.9,60.4,6.3,121.3,35.2,174.7c30.9,56.6,80.3,100.9,139.9,125.5
          c36.8,16.6,76.8,25.2,117.2,25.2c10.3,0.1,20.5-0.5,30.8-1.6h0.4c0.4,0,0.8,0.4,1.2,0.4l0.4-0.8c19.3-2.1,38.3-6.1,56.8-12
          c59.9-18.5,113-54.2,152.7-102.8c40.8-49.8,62.7-112.3,62-176.7C607.4,229.1,574.6,152.8,513,95.2z M411.5,277.1
          c-0.8-4.4-1.6-8.8-2.8-13.6c39.7-16,77.5-36.2,112.7-60.4c15.1,33.6,22.9,70,22.7,106.8c0,6,0,12-0.4,18.4
          c-31.6,28.8-72.4,53.6-120.8,74.4C424.9,360.5,421,318.2,411.5,277.1L411.5,277.1z M529.4,401c-22.3,60-66.8,109.1-124.3,137.1
          c6.4-28.4,11.2-56,14.4-81.9C458.4,442.8,495.4,424.2,529.4,401z M495,158c-23.2,19.6-59.2,40.8-99.9,59.1
          c-18.4-51.1-48.8-107.1-90.8-166.3C379.1,56,449.9,96,495,158z M234,491c52.4-2,104.4-10.4,154.7-25.2c1.6,28.4,2,54.8,1.2,79.2
          C360,557.4,328,564,295.6,564.5C272.6,542.2,251.9,517.5,234,491z M37.7,267.1c13.2-83.2,63.1-153.5,135.2-189.9
          C139,138,119.7,205.9,116.5,275.4C90.1,274.8,63.7,272,37.7,267.1z M160,275.1c-2.4-74,9.2-150.7,31.2-205.9h0.4
          c1.2-0.4,2.4-1.2,3.6-1.6c29.9-11.8,61.8-17.7,94-17.2c23.2,50.3,46.4,115.5,65.2,183.9C291.8,257.2,226.4,271,160,275.1z
           M211.1,454.2c-21.2-37.6-36.8-76.4-44.8-112.3c-1.6-9.6-3.2-20-4-30.8c69.2-0.2,138-11.1,203.9-32.4c1.2,4,2,8.4,2.8,12.4
          c7.6,48.4,12.4,88.4,16,126c-56,20.1-114.5,32.6-173.8,37.1L211.1,454.2z M164.3,456.3c-34.8,0-66.8-3.2-92.4-10
          c-27.2-43.2-40.4-96.3-37.6-149.5c27,6.7,54.6,11,82.4,12.8c0.8,13.8,2.6,27.6,5.6,41.2c7,37.6,21.3,73.4,42.1,105.5L164.3,456.3z
           M82.3,458.7c-0.4,0.5-1,0.9-1.6,1.2c-0.8-0.9-1.4-1.8-2-2.8c2.1,0.7,2.8,1.5,3.7,1.5L82.3,458.7z M84,459.5L84,459.5
          c-0.4-0.8,0-1.6,0-2l-0.4,0.4v-1.2c0-0.4,0-0.4,0.4-0.4c0.4,0,1.2,0.8,1.6,0.8l-0.4,2.4l-0.4,0.4c-0.2,0.3-0.7-0.1-0.7-0.5
          L84,459.5z M189.7,491c22.5,27.5,48.6,51.8,77.6,72.4c-25.3-2.5-50.1-8.7-73.6-18.4l0,0c-37.3-14.1-70.6-37-97.1-66.8
          C127.1,485.8,158.3,490.1,189.7,491z"
          />
        </g>
      </g>
    </svg>
  )
}
