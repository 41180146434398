export const BASE_PATH = window.basePath || ''
export const API_BASE_PATH = window.apiBasePath || ''
export const DEBUG = window.DEBUG || false
export const LANG = window.lang || window.defaultLang || 'en'
export const CASINO_MODE = (window.APP && window.APP.casinoMode) || 'standalone'
export const CONTENT_PREFIX = (window.APP && window.APP.contentPrefix) || ''
export const ANALYTICS_CODE = (window.APP && window.APP.analyticsCode) || ''
export const META_ANALYTICS_CODE = window?.APP?.metaAnalyticsCode || ''
export const GOOGLE_SITE_VERIFICATION = window?.APP?.googleSiteVerification || ''
export const GOOGLE_MAPS_KEY = window?.APP?.googleMapsKey || 'AIzaSyAg_HMd-qAfuM_Jt_cBLXShX3cFW2vWEv4'
export const ANALYTICS_TIMEOUT = window.analyticsTimeout || 500
export const LIVE_CHAT_WIDGET_URL = window.APP?.liveChatWidgetUrl || ''
export const LIVE_CHAT_WIDGET_SELECTOR = window.APP?.liveChatWidgetSelector || 'div.b24-widget-button-wrapper'
export const LOCAL_ENV = window.APP?.localEnv || false
export const FRONTEND_DEV_GAME_FILE_NAME = window.APP?.FRONTEND_DEV_GAME_FILE_NAME || 'dev_game.html'
export const THEME = (window.APP && window.APP.theme) || 'betor'
const DEFAULT_LANG = window.defaultLang || 'en'
export const HOME_ADDRESS = `${LANG === DEFAULT_LANG ? '/' : `/${LANG}`}`
export const HOME_PATH = `${BASE_PATH}${HOME_ADDRESS}`
export const DASHBOARD_PATH = `${BASE_PATH}/admin/dashboard`
export const PLAYER_PROFILE_ADDRESS = `/${LANG}/profile/`
export const PLAYER_VERIFICATION_STATUS_ADDRESS = `/${LANG}/profile/verification`
export const PLAYER_CHANGE_PASSWORD = `/${LANG}/profile/change-password`
export const INBOX_PATH = `/${LANG}/message`
export const PLAYER_PAYMENT_METHODS_ADDRESS = `/${LANG}/payment/methods`
export const TRANSACTION_HISTORY_ADDRESS = `/${LANG}/payment`
export const PROFILE_ACCESS_HISTORY = `${PLAYER_PROFILE_ADDRESS}access_history`
export const CONFIRM_MOBILE_PATH = `${PLAYER_PROFILE_ADDRESS}mobile/confirm`
export const AML_FORM_PATH = `${PLAYER_PROFILE_ADDRESS}aml-check-form`
export const ADD_GAME_TO_FAVORITES = `/${LANG}/favorites/add`
export const REMOVE_GAME_FROM_FAVORITES = `/${LANG}/favorites/remove`

export const ACCOUNT_ACCOUNTING_SERVER = 'AccountingServerCashableAccount'
export const ACCOUNT_ONLINE = 'CustomerFinancialOnlineAccount'
export const ACCOUNT_SUM = 'AccountsSum'

export const SUCCESS_DATA = 'data'
export const SUCCESS_MESSAGE = 'message'
export const SUCCESS_FORMSUBMIT = 'form_submit'
export const SUCCESS_REDIRECT = 'redirect'
export const ERROR_MESSAGE = 'error_message'
export const ERROR_FORM = 'form_error'
export const ERROR_LOGINREQUIRED = 'login_required'

export const COOKIE_LAW_CONFIRM_KEY = 'cookieLawConfirmed'
export const SHOW_PASSWORD_CHANGE_KEY = 'showPasswordChange'
export const COOKIE_SK_PLAYER_GAMES_VALUES = 'cookieSkPlayerBetsWins'

export const DEFAULT_DATE_FORMAT = (window.APP && window.APP.dateTemplate) || 'd. M. y'
export const DEFAULT_DATE_TIME_FORMAT = (window.APP && window.APP.dateTimeTemplate) || 'd. M. y HH:mm:ss'
export const SERVER_TIMEZONE = window.APP?.timezone || 'Europe/Prague'
export const SERVER_DATE = window.APP?.serverDate

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'
export const REDUX_FORM_BLUR = '@@redux-form/BLUR'

export const JACKPOT_ENABLED = window.APP && !!window.APP.jackpot
export const LAST_WIN_WIDGET_ENABLED = window.APP && !!window.APP.last_win_widget
export const NEW_LAYOUT_ENABLED = window.APP && !!window.APP.new_layout

export const FAVORITE_GAME_CATEGORY_SLUG = 'favorites'

export const REACT_QUERY_DEV_TOOLS = !!window.APP?.REACT_QUERY_DEV_TOOLS

export const SHOW_LIMITS_COOKIE_NAME = 'showLimitsAfterLogin'
