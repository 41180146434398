import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PanicButtonBankIdLoadable from 'containers/PanicButtonBankIdPage/Loadable'
import PanicButtonLoadable from 'containers/PanicButtonPage/Loadable'

import AMLCheckFormPage from '../containers/AMLCheckFormPage/Loadable'
import AccessHistoryPage from '../containers/AccessHistoryPage/Loadable'
import BonusesPage from '../containers/BonusesPage/Loadable'
import ChangePasswordPage from '../containers/ChangePasswordPage/Loadable'
import CmsPageBox from '../containers/CmsPageBox'
import ContactUsPage from '../containers/ContactUsPage/Loadable'
import EditProfilePage from '../containers/EditProfilePage/Loadable'
import GameHistoryPage from '../containers/GameHistoryPage/Loadable'
import GamesRoomsPage from '../containers/GameRoomsPage/Loadable'
import LimitHistoryPage from '../containers/LimitHistoryPage/Loadable'
import LimitStatusPage from '../containers/LimitsStatusPage/Loadable'
import MakeWithdrawPage from '../containers/MakeWithdrawPage/Loadable'
import MessagesPage from '../containers/MessagesPage/LoadableIndex'
import SentPage from '../containers/MessagesPage/LoadableSent'
import ThreadPage from '../containers/MessagesPage/LoadableThread'
import MobileConfirmPage from '../containers/MobileConfirmPage/Loadable'
import PaymentMethodsPage from '../containers/PaymentMethodsPage/Loadable'
import PaymentTransferPage from '../containers/PaymentTransferPage/Loadable'
import PlayerBetsWinsForSkPage from '../containers/PlayerBetsWinsForSkPage/Loadable'
import PlayerDocumentsEdit from '../containers/PlayerDocumentsEdit/Loadable'
import PlayerProfilePage from '../containers/PlayerProfilePage/Loadable'
import PlayerTournaments from '../containers/PlayerTournaments/LoadablePlayerTournaments'
import ProfileVerificationMethodsPage from '../containers/ProfileVerificationMethodsPage/Loadable'
import ProfileVerificationPage from '../containers/ProfileVerificationPage/Loadable'
import ResetPasswordCheckPage from '../containers/ResetPasswordCheckPage/Loadable'
import ResetPasswordResetPage from '../containers/ResetPasswordResetPage/Loadable'
import ResponsibleGamingPage from '../containers/ResponsibleGamingPage/Loadable'
import TransactionHistoryPage from '../containers/TransactionHistoryPage/Loadable'

export default function CMSBoxLayoutSwitch() {
  return (
    <CmsPageBox>
      <Switch>
        <Route
          exact
          path="/:lang/profile"
          component={PlayerProfilePage}
        />
        <Route
          path="/:lang/profile/edit"
          component={EditProfilePage}
        />
        <Route
          path="/:lang/profile/documents"
          component={PlayerDocumentsEdit}
        />
        <Route
          path="/:lang/profile/change-password"
          component={ChangePasswordPage}
        />
        <Route
          path="/:lang/profile/verification"
          component={ProfileVerificationPage}
        />
        <Route
          path="/:lang/profile/verification-methods"
          component={ProfileVerificationMethodsPage}
        />
        <Route
          path="/:lang/profile/responsible-gambling"
          component={ResponsibleGamingPage}
        />
        <Route
          path="/:lang/profile/limits-status"
          component={LimitStatusPage}
        />
        <Route
          path="/:lang/profile/access_history"
          component={AccessHistoryPage}
        />
        <Route
          path="/:lang/profile/bets-wins-values"
          component={PlayerBetsWinsForSkPage}
        />
        <Route
          path="/:lang/profile/mobile/confirm"
          component={MobileConfirmPage}
        />
        <Route
          path="/:lang/profile/aml-check-form"
          component={AMLCheckFormPage}
        />
        <Route
          path="/:lang/profile/panic-button"
          component={PanicButtonLoadable}
        />
        <Route
          path="/:lang/profile/panic-button-bank-id"
          component={PanicButtonBankIdLoadable}
        />
        <Route
          path="/:lang/game-rooms"
          component={GamesRoomsPage}
        />
        <Route
          exact
          path="/:lang/payment/methods"
          component={PaymentMethodsPage}
        />
        <Route
          exact
          path="/:lang/payment/transfer"
          component={PaymentTransferPage}
        />
        <Route
          exact
          path="/:lang/payment/withdraw"
          component={MakeWithdrawPage}
        />
        <Route
          exact
          path="/:lang/payment"
          component={TransactionHistoryPage}
        />
        <Route
          path="/:lang/payment/bonuses"
          component={BonusesPage}
        />
        <Route
          exact
          path="/:lang/message/sent"
          component={SentPage}
        />
        <Route
          path="/:lang/message/:id"
          component={ThreadPage}
        />
        <Route
          path="/:lang/message"
          component={MessagesPage}
        />
        <Route
          path="/:lang/games-history"
          component={GameHistoryPage}
        />
        <Route
          exact
          path="/:lang/profile/limits-history"
          component={LimitHistoryPage}
        />
        <Route
          exact
          path="/:lang/profile/tournaments-history"
          component={PlayerTournaments}
        />
        <Route
          path="/:lang/contact-us"
          component={ContactUsPage}
        />
        <Route
          path="/:lang/resetting/check-email"
          component={ResetPasswordCheckPage}
        />
        <Route
          path="/:lang/resetting/reset/:hash"
          component={ResetPasswordResetPage}
        />
      </Switch>
    </CmsPageBox>
  )
}
