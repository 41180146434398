import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import X from '../Icons/X'
import HeaderEl from './HeaderEl'

export const height = 80

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${height}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(0deg, transparent 0, #000);

  ${media.landscapeMd`
    flex-direction: column;
    left: auto;
    right: 0;
    height: 100%;
    width: ${height}px;
    background: linear-gradient(90deg, transparent 0, #000);
  `}
`

const Description = styled.div`
  flex-grow: 1;

  ${media.landscapeMd`
    // display: none;
    order: 1;
  `}
`

function Header({ onClick, ...rest }) {
  return (
    <Div {...rest}>
      <Description />
      <HeaderEl onClick={onClick}>
        <X />
      </HeaderEl>
    </Div>
  )
}

Header.propTypes = {
  onClick: PropTypes.func,
}

export default Header
