export const RESPONSIBLE_GAMING_STATE_KEY = 'responsibleGaming'
export const FORM_RESPONSIBLE_GAMING = 'formResponsibleGaming'
export const FORM_INNER_NAME = 'userlimits'

export const UPDATE_USER_LIMITS = 'Casino/ResponsibleGaming/UPDATE_USER_LIMITS'

export const CONTENT_LOAD = 'Casino/ResponsibleGambling/CONTENT_LOAD'
export const CONTENT_LOAD_SUCCESS = 'Casino/ResponsibleGambling/CONTENT_LOAD_SUCCESS'
export const CONTENT_LOAD_ERROR = 'Casino/ResponsibleGambling/CONTENT_LOAD_ERROR'

export const LIMIT_GROUPS = [
  {
    groupName: 'current_limits_header',
    limitNames: ['logonBreakWholeSystemLimit', 'logonCountMonthWholeSystemLimit', 'logonDurationDayWholeSystemLimit'],
  },
  {
    groupName: 'current_sport_limits_header',
    limitNames: ['lossDaySportsLimit', 'lossMonthSportsLimit', 'stakeDaySportsLimit', 'stakeMonthSportsLimit'],
  },
  {
    groupName: 'current_casino_limits_header',
    limitNames: ['lossDayCasinoLimit', 'lossMonthCasinoLimit', 'stakeDayCasinoLimit', 'stakeMonthCasinoLimit'],
  },
  {
    groupName: 'current_live_limits_header',
    limitNames: ['lossDayLiveGamesLimit', 'lossMonthLiveGamesLimit', 'entranceCountLiveGamesLimit'],
  },
  {
    groupName: 'current_other_limits_header',
    limitNames: [
      'lossHourOnlineLimit',
      'lossHourCasinoLimit',
      'lossHourArcadeLimit',
      'mandatoryBreakLimit',
      'lossOverAllLimit',
      'stakeOverAllLimit',
      'playTimeLimit',
      'playTimeOverAllLimit',
      'lossMonthLimit',
    ],
  },
]

export const COUNTERS = [
  'lossOverAllLimit',
  'stakeOverAllLimit',
  'playTimeLimit',
  'playTimeOverAllLimit',
  'lossMonthLimit',
]
