import React from 'react'

import Span from '../Span'
import { Icon } from './Icon'
import { IconKey } from './iconMap'
import { StyledUrlLink } from './styles'

export interface IconLinkProps {
  label: string
  icon: IconKey
  url?: string
  link?: string
  isEnabled: boolean
}

function IconLink({ label, icon, url, link, isEnabled, ...rest }: IconLinkProps) {
  return (
    <StyledUrlLink
      url={url}
      link={link}
      {...rest}
    >
      <Icon
        icon={icon}
        width="64"
        height="64"
        preserveAspectRatio="xMinYMin meet"
      />
      <Span label={label} />
    </StyledUrlLink>
  )
}

export default IconLink
