import styled from 'styled-components'

import {
  bh,
  media,
  gray,
  secondaryHeaderColor,
  transition,
  grayLighter,
  fontWeightLight,
} from '../../utils/style-utils'

export default styled.button`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 10px 0 10px;
  background: none;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: ${bh * 0.75}px;
  font-weight: ${fontWeightLight};

  color: ${secondaryHeaderColor};
  background: transparent;
  ${transition('background', 0.2)};

  &:hover {
    text-decoration: underline;
  }

  svg {
    width: 20px;
    height: 10px;
    vertical-align: middle;
    margin-right: 5px;
  }

  ${media.md`
    height: 40px;
    width: 100%;
    justify-content: space-between;
    background: ${gray};
    border-bottom: 1px solid ${grayLighter};
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;

    svg {
      order: 1;
    }

    &:hover {
      text-decoration: none;
    }
  `}
`
