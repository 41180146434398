import React, { lazy, Suspense } from 'react'

import { fromJS } from 'immutable'

import CenteredLoader from '../../components/Loader/CenteredLoader'
import { useInjectReducer } from '../../utils/routing/injectReducer'
import { GAME_HISTORY_TYPES, TRANSACTION_HISTORY_PAGE_STATE } from '../TransactionHistoryPage/constants'
import reducer from '../TransactionHistoryPage/reducer'

const key = TRANSACTION_HISTORY_PAGE_STATE
const Component = lazy(() => import(/* webpackChunkName: "GameHistory" */ '../TransactionHistoryPage'))

export default function Loadable() {
  const { isLoading } = useInjectReducer({ key, reducer })

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component allowedTypes={fromJS(GAME_HISTORY_TYPES)} />
    </Suspense>
  )
}
