/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import insertCaptchaToStateForm from '../../utils/insertCaptchaToStateForm'
import { RESET_PAGE_STATE, INSERT_CAPTCHA } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

const initialState = fromJS({
  loading: undefined,
  error: false,
  formMobile: { fields: [] },
})

export default function ResetPasswordRequestReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state.set('loading', true).set('error', false).set('formMobile', initialState.get('formMobile'))

    case CONTENT_LOAD_SUCCESS:
      return state.set('loading', false).set('error', false).set('formMobile', fromJS(action.data.formMobile))

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case INSERT_CAPTCHA:
      return insertCaptchaToStateForm(state)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}
