import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import { ACCOUNT_SUM } from '../../containers/App/constants'
import { bh } from '../../utils/style-utils'
import BaseAccountRow from '../PlayerProfile/AccountRow'

const AccountRow = styled(BaseAccountRow)`
  margin: 0 -${bh}px;
`

function Balances({ userBalances }) {
  if (!userBalances) {
    return null
  }

  const balancesNodes = userBalances
    .filter((balance) => balance.get('type') !== ACCOUNT_SUM)
    .filter((balance) => balance.get('isCurrent') || balance.get('balance') > 0)
    .map((balance) => (
      <AccountRow
        key={balance.get('type')}
        label={balance.get('type')}
        value={balance.get('balance')}
        noDepositCol
        isExtendedCashField
      />
    ))
    .toList()

  return (
    <div>
      {balancesNodes}

      {balancesNodes.size > 1 && (
        // SHOW SUM only when there are more than one account
        <AccountRow
          label={userBalances.getIn([ACCOUNT_SUM, 'type'])}
          value={userBalances.getIn([ACCOUNT_SUM, 'balance'])}
          isSum
          noDepositCol
          isExtendedCashField
        />
      )}
    </div>
  )
}

Balances.propTypes = {
  userBalances: ImmutablePropTypes.map,
}

export default Balances
