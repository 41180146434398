import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Header from '../../components/GenericMenu/Header'
import List from '../../components/GenericMenu/List'

function GenericMenu({ structure, toggleMenuPosition, toggleMenuSection, header, Wrap = React.Fragment, ...rest }) {
  return (
    <Wrap {...rest}>
      {header && (
        <Header
          header={header}
          toggleMenuPosition={toggleMenuPosition}
        />
      )}
      <List
        structure={structure}
        toggleMenuPosition={toggleMenuPosition}
        toggleMenuSection={toggleMenuSection}
        {...rest}
      />
    </Wrap>
  )
}

GenericMenu.propTypes = {
  structure: ImmutablePropTypes.list,
  toggleMenuPosition: PropTypes.func.isRequired,
  toggleMenuSection: PropTypes.func.isRequired,
  header: PropTypes.object,
  Wrap: PropTypes.object,
}

export default React.memo(GenericMenu)
