import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'

import { IS_DEDUPLICATION_NEEDED_FIELD } from '../../components/Register/StandardRegistration/BetorCZ/Fields/utils'
import { getInitialValues } from '../../utils/forms'
import { REGISTER_STATE_KEY, FORM_REGISTER_NAME } from './constants'

export const selectRegisterState = (state: any) => state.get(REGISTER_STATE_KEY)
export const selectFormState = (state: any) => state.get('form')

export const makeSelectRegisterLoading = () =>
  createSelector(selectRegisterState, (substate) => substate.get('loading'))

export const makeSelectRegisterLoadingCaptcha = () =>
  createSelector(selectRegisterState, (substate) => substate.get('loadingCaptcha'))

export const makeSelectRegisterError = () => createSelector(selectRegisterState, (substate) => substate.get('error'))

export const makeSelectStep = () => createSelector(selectRegisterState, (substate) => substate.get('step'))

export const makeSelectTermsUrl = () => createSelector(selectRegisterState, (substate) => substate.get('termsUrl'))

export const makeSelectAcceptanceOfProcessingOfPersonalDataUrl = () =>
  createSelector(selectRegisterState, (substate) => substate.get('acceptanceOfProcessingOfPersonalDataUrl'))

export const makeSelectAcceptanceOfPoliticalExposedConditionsUrl = () =>
  createSelector(selectRegisterState, (substate) => substate.get('acceptanceOfPoliticalExposedConditionsUrl'))

export const makeSelectFormType = () => createSelector(selectRegisterState, (substate) => substate.get('formType'))

export const makeSelectInformationsPath = () =>
  createSelector(selectRegisterState, (substate) => substate.get('informationsPath'))

export const makeSelectRegisterFormData = () =>
  createSelector(selectRegisterState, (substate) => substate.get(FORM_REGISTER_NAME))

export const makeSelectRegisterFormInitialValues = () =>
  createSelector(makeSelectRegisterFormData(), (substate) => getInitialValues(substate))
export const selectRegisterFormValues = (store: any) =>
  store?.getIn(['form', FORM_REGISTER_NAME, 'values', 'registration_external'])
const selectIsDeduplicationNeeded = (store: any) => store.getIn([REGISTER_STATE_KEY, IS_DEDUPLICATION_NEEDED_FIELD])
export const useSelectIsDeduplicationNeeded = () => useSelector((store: any) => selectIsDeduplicationNeeded(store))

export function useWatchRegistrationField<TData>(field: string): TData {
  return useSelector((store) => selectRegisterFormValues(store).get(field))
}
