import React from 'react'

import Translator from 'bazinga-translator'
import withCondition from 'components/withCondition'

import { PLAYER_PAYMENT_METHODS_ADDRESS } from '../../containers/App/constants'
import Button from '../Btn'
import ExclamationTriangle from '../Icons/components/Warning'

function VerifyCardButtonComponent({ ...props }) {
  return (
    <Button
      link
      to={PLAYER_PAYMENT_METHODS_ADDRESS}
      danger
      md
      {...props}
    >
      <ExclamationTriangle />
      {Translator.trans('card_verification_is_needed', {}, 'payments')}
    </Button>
  )
}

export const VerifyCardButton = withCondition(VerifyCardButtonComponent)
