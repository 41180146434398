// requestAnimationFrame polyfill by Erik Möller. fixes from Paul Irish and Tino Zijdel
;(function requestAnimationFramePolifill() {
  let lastTime = 0
  const vendors = ['ms', 'moz', 'webkit', 'o']
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`]
    window.cancelAnimationFrame =
      window[`${vendors[x]}CancelAnimationFrame`] || window[`${vendors[x]}CancelRequestAnimationFrame`]
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function go(callback) {
      const currTime = new Date().getTime()
      const timeToCall = Math.max(0, 16 - (currTime - lastTime))
      const id = window.setTimeout(() => {
        callback(currTime + timeToCall)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function cancel(id) {
      clearTimeout(id)
    }
  }
})()

function inOutExpo(n) {
  if (n === 0) return 0
  if (n === 1) return 1
  if ((n *= 2) < 1) {
    // eslint-disable-line
    return 0.5 * 1024 ** (n - 1)
  }

  return 0.5 * (-(2 ** (-10 * (n - 1))) + 2)
}

/**
 * Detect if element in in viewport
 * @param {HTMLDivElement} el
 * @returns {Number}
 */
function getElOffsetY(el) {
  let top = el.offsetTop

  while (el.offsetParent) {
    el = el.offsetParent // eslint-disable-line
    top += el.offsetTop
  }

  return top
}

/**
 * Detect if element in in viewport
 * @param {HTMLDivElement} el
 * @returns {Boolean}
 */
export function isTopInViewportY(el) {
  const top = getElOffsetY(el)
  const winPageYOffset = window.pageYOffset
  // const height = el.offsetHeight

  return top < winPageYOffset + window.innerHeight && top > winPageYOffset
}

/**
 * Detect if element in in viewport
 * @param {HTMLDivElement} el
 */
export function scrollToEl(el, marginTop = 60, time = 800) {
  const top = getElOffsetY(el) - marginTop
  const currentWindowTop = window.pageYOffset
  const delta = currentWindowTop - top
  let start = null

  function step(timestamp) {
    if (!start) start = timestamp
    const progress = (timestamp - start) / time
    window.scrollTo(0, currentWindowTop - delta * inOutExpo(progress))

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}

export function scrollToTop() {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  })
}
