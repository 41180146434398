import styled from 'styled-components'

import { cmsContentBg, white } from '../../utils/style-utils'

export default styled.div`
  position: ${(p) => (p.absolute ? 'absolute' : 'fixed')};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  transform: translateZ(0px);
  backface-visibility: hidden;

  background: ${cmsContentBg};
  background-size: cover;
  background-position: center;
  mix-blend-mode: ${cmsContentBg === white ? 'screen' : 'multiply'};

  opacity: 0.7;
`
