import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Element from './Element'
import UlWrapper from './UlWrapper'

function List({ structure, parent, isOpen, toggleMenuPosition, nestedLevel = 0, toggleMenuSection }) {
  const shouldRenderChildren = structure && ((parent && isOpen) || !parent)

  if (!shouldRenderChildren) {
    return null
  }

  return (
    <UlWrapper
      id={parent && `${parent}-children`}
      className="menu-list"
      isOpen={parent ? isOpen : true}
      isTopNode={!parent}
    >
      {structure.map((child) => (
        <Element
          child={child}
          key={`key-${child.get('id')}`}
          nestedLevel={nestedLevel + 1}
          toggleMenuPosition={toggleMenuPosition}
          toggleMenuSection={toggleMenuSection}
        />
      ))}
    </UlWrapper>
  )
}

List.propTypes = {
  structure: ImmutablePropTypes.list,
  parent: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleMenuPosition: PropTypes.func.isRequired,
  toggleMenuSection: PropTypes.func.isRequired,
  nestedLevel: PropTypes.number,
}

export default React.memo(List)
