import styled from 'styled-components'

import { transition, mainHeaderColor, bh, white } from '../../utils/style-utils'

export const Span = styled.span`
  display: block;
  height: ${bh / 4}px;
  width: ${bh * 1.6}px;
  background: ${(props) => (props.open ? white : mainHeaderColor)};
  ${transition('transform', 0.2)};
  margin: ${bh / 4}px auto;
`

export const SpanTop = styled(Span)`
  ${(props) => (props.open ? 'transform: translateY(2px) translateX(-6px) rotate(-45deg) scaleX(0.6);' : null)}
`

export const SpanBottom = styled(Span)`
  ${(props) => (props.open ? 'transform: translateY(-2px) translateX(-6px) rotate(45deg) scaleX(0.6);' : null)}
`
