import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AnySchema } from 'yup'

import { getQueryFnWithYupValidation } from './utils'

interface Props<TError, TData, TSelect> {
  cacheKey: QueryKey
  queryFn: () => Promise<unknown>
  schema: AnySchema<unknown, any, TData>
  options?: Partial<UseQueryOptions<unknown, TError, TSelect>>
}

export function useQueryWithValidator<TError, TData, TSelect = TData>({
  cacheKey,
  queryFn,
  schema,
  options,
}: Props<TError, TData, TSelect>) {
  return useQuery({
    queryKey: cacheKey,
    queryFn: getQueryFnWithYupValidation(queryFn, schema),
    ...options,
  })
}
