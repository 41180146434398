import styled from 'styled-components'

import { media } from '../../utils/style-utils'

export const ContentBox = styled.div`
  width: 650px;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.75);
  margin-right: 10rem;
  padding: 5rem;

  ${media.sm`
      width: 100%;
      height: auto
      margin: 0;
      padding: 1.25rem;
    `}
  ${media.md`
      margin: 0;
      padding: 3rem;
    `}
    ${media.lg`
      margin: 0;
      padding: 3rem;
    `}
`
