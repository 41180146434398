/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { IS_DEDUPLICATION_NEEDED_FIELD } from '../../components/Register/StandardRegistration/BetorCZ/Fields/utils'
// import { RESET_PAGE_STATE } from '../MainLayout/constants'
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  RELOAD_REGISTER_FORM,
  RELOAD_REGISTER_FORM_SUCCESS,
  RELOAD_REGISTER_FORM_ERROR,
  FINISH_WITHOUT_CONFIRMING,
  FORM_REGISTER_NAME,
  DEDUPLICATION_IS_NEEDED,
} from './constants'

const initialState = fromJS({
  loading: undefined,
  loadingCaptcha: false,
  error: false,
  sendingSms: undefined,
  termsUrl: '',
  acceptanceOfProcessingOfPersonalDataUrl: '',
  acceptanceOfPoliticalExposedConditionsUrl: '',
  formType: '',
  informationsPath: '',
  step: 0,
  [FORM_REGISTER_NAME]: {
    fields: [],
  },
})

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return state.set('loading', true).set('error', false).set('form', initialState.get('form'))

    case LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('termsUrl', action.data.termsUrl)
        .set('acceptanceOfProcessingOfPersonalDataUrl', action.data.acceptanceOfProcessingOfPersonalDataUrl)
        .set('acceptanceOfPoliticalExposedConditionsUrl', action.data.acceptanceOfPoliticalExposedConditionsUrl)
        .set('formType', action.data.formType)
        .set('informationsPath', action.data.informationsPath)
        .set(FORM_REGISTER_NAME, fromJS(action.data[FORM_REGISTER_NAME]))

    case LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RELOAD_REGISTER_FORM:
      return state.set('loadingCaptcha', true)

    case RELOAD_REGISTER_FORM_SUCCESS:
      return state.set(FORM_REGISTER_NAME, fromJS(action.data[FORM_REGISTER_NAME])).set('loadingCaptcha', false)

    case RELOAD_REGISTER_FORM_ERROR:
      return state.set('error', action.error).set('loadingCaptcha', false)

    case FINISH_WITHOUT_CONFIRMING:
      return state.set('step', 2)

    case DEDUPLICATION_IS_NEEDED:
      return state.set(IS_DEDUPLICATION_NEEDED_FIELD, true)

    default:
      return state
  }
}

export default registerReducer
