import React from 'react'

import { useSelectFooterMenu } from 'containers/MainLayout/selectors'

import { MenuItem } from './MenuItem'
import { FooterMenuWrapper } from './menuStyles'

export function FooterMenu() {
  const menu = useSelectFooterMenu()

  return (
    <FooterMenuWrapper>
      {menu.map((item, id) => (
        <MenuItem
          key={id}
          item={item}
        />
      ))}
    </FooterMenuWrapper>
  )
}
