import { useEffect } from 'react'

import { getLiveChatWidget, getShowButton, shouldDisplayLiveChatShowButton, shouldDisplayLiveChatWidget } from './utils'

function LiveChat(): any {
  useEffect(() => {
    if (shouldDisplayLiveChatWidget()) {
      const script = getLiveChatWidget()

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
    if (shouldDisplayLiveChatShowButton()) {
      const showWidgetButton = getShowButton()

      document.body.appendChild(showWidgetButton)
    }

    return undefined
  }, [])

  return undefined
}

export default LiveChat
