import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'
import { useInjectReducer } from '../../utils/routing/injectReducer'
import { useInjectSaga } from '../../utils/routing/injectSaga'
import { USERNAME_GENERATOR_REGISTER_STATE_KEY, REGISTER_STATE_KEY } from './constants'
import loginGeneratorSaga from './loginGeneratorSaga'
import reducer from './reducer'
import saga from './sagas'

const key = REGISTER_STATE_KEY
const Component = lazy(() => import(/* webpackChunkName: "Register" */ './index'))

export default function Loadable() {
  const { isLoading } = useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })
  useInjectSaga({ key: USERNAME_GENERATOR_REGISTER_STATE_KEY, saga: loginGeneratorSaga })

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component />
    </Suspense>
  )
}
