import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  makeSelectIsUserFullyActivated,
  makeSelectPlayerLevel,
  makeSelectUser,
} from '../../../containers/MainLayout/selectors'
import useRefetchOnThreshold from '../../../containers/Tournaments/hooks/useRefetchOnThreshold'
import { getDatesForThreshold } from '../../../containers/Tournaments/utils'
import { mergeTournamentsAndSubscriptions } from './mergeTournamentsAndSubscriptions'
import { useActiveSubscriptions } from './useActiveSubscriptions'
import { useNearestTournaments } from './useNearestTournaments'
import { useVIPTournaments } from './useVIPTournaments'

export default function useTournaments() {
  const user = useSelector(makeSelectUser())?.toJS()
  const playerLevel = useSelector(makeSelectPlayerLevel()) as string
  const playerFullyActivated = useSelector(makeSelectIsUserFullyActivated())
  const vipTournamentsEnabled = !!playerLevel
  const { data, ...tournamentsQuery } = useNearestTournaments()
  const { data: vipTournaments, ...vipTournamentsQuery } = useVIPTournaments({
    playerLevel,
    enabled: vipTournamentsEnabled,
  })
  const activeSubscriptionsEnabled = playerFullyActivated && (data?.length > 0 || vipTournaments?.length > 0)
  const { data: userMemberships, ...membershipsQuery } = useActiveSubscriptions({
    playerId: user.id,
    enabled: activeSubscriptionsEnabled,
  })
  const { tournaments, futureTournaments, finishedTournaments } = useMemo(
    () => mergeTournamentsAndSubscriptions([...(data || []), ...(vipTournaments || [])], userMemberships),
    [data, userMemberships, vipTournaments],
  )

  useRefetchOnThreshold({
    dates: data ? getDatesForThreshold(tournaments, futureTournaments) : [],
    refetchFn: () => {
      if (vipTournamentsEnabled) {
        vipTournamentsQuery.refetch()
      }

      tournamentsQuery.refetch()
    },
    maxThreshold: 60 * 60 * 1000, // 1h
  })

  return {
    futureTournaments,
    finishedTournaments,
    tournaments,
    tournamentsQuery,
    membershipsQuery: {
      ...membershipsQuery,
      isPending: membershipsQuery.isPending && membershipsQuery.fetchStatus !== 'idle',
    },
    vipTournamentsQuery,
  }
}
