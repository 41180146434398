import styled from 'styled-components'

import { media } from '../../utils/style-utils'

interface Props {
  on: keyof typeof media
}

export const Hide = styled.div<Props>`
  ${({ on }) =>
    media[on]`
      display: none;
    `}
`
