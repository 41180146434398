import { Link } from 'react-router-dom'

import UserIcon from 'components/Icons/components/User'
import styled from 'styled-components'
import { mainHeaderColor } from 'utils/style-utils'

export const LoginLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 4rem;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${mainHeaderColor};
    display: block;
  }
`

export const StyledUserIcon = styled(UserIcon)`
  width: 1.6rem;
  height: 1.6rem;
  fill: ${mainHeaderColor};
`
