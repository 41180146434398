import styled from 'styled-components'

import { bh, cmsBannerHeight, cmsContentSidebarBg, media } from '../../utils/style-utils'

export const sidebarWidth = bh * 16

export default styled.div`
  width: ${sidebarWidth}px;
  min-width: ${sidebarWidth}px;
  margin-top: -${cmsBannerHeight - 8 * bh}px;
  background: ${cmsContentSidebarBg};
  bottom: 0;
  padding-bottom: ${bh}px;

  ${media.md`
    display: none;
  `}
`
