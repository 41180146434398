/* eslint-disable no-underscore-dangle */

/* eslint-disable no-multi-assign */

/* eslint-disable consistent-return */
import { META_ANALYTICS_CODE } from '../App/constants'

export function initMetaAnalytics(statistical: boolean) {
  if (statistical && META_ANALYTICS_CODE) {
    if ((window as any).fbq) {
      return
    }
    const fbq = ((window as any).fbq = function fbq(...args: any[]) {
      ;(fbq as any).callMethod ? (fbq as any).callMethod.apply(fbq, args) : (fbq as any).queue.push(args)
    })
    if (!(window as any)._fbq) {
      ;(window as any)._fbq = fbq
    }
    ;(fbq as any).push = fbq
    ;(fbq as any).loaded = !0
    ;(fbq as any).version = '2.0'
    ;(fbq as any).queue = []

    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/fbevents.js'
    script.async = true
    script.onload = () => {
      fbq('init', META_ANALYTICS_CODE)

      fbq('track', 'PageView')
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }

  return undefined
}
