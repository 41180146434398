import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import ContentArticle from '../CmsPage/ContentArticle'
import { Container } from './Container'
import { ContentBox } from './ContentBox'

function LandingPage({ page, background }) {
  return (
    <Container
      data-testid="landing-page"
      className="landing-page"
      img={background}
    >
      <ContentBox className="landing-page-content">
        <ContentArticle page={page} />
      </ContentBox>
    </Container>
  )
}

LandingPage.propTypes = {
  page: ImmutablePropTypes.map.isRequired,
  background: PropTypes.object.isRequired,
}

export default LandingPage
