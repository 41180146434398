import styled from 'styled-components'

import { media, transition } from '../../utils/style-utils'

export default styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  transform: translateX(${(props) => (props.open ? '0' : '-100%')});
  ${transition('transform', 0.5, 'cubic-bezier(0.19, 1, 0.22, 1)')};

  ${media.md`
    display: flex;
  `}
`
