import * as React from 'react'

import { IconProps } from '../types'

function Card(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V7Zm3-1a1 1 0 0 0-1 1v1h18V7a1 1 0 0 0-1-1H4ZM3 17v-7h18v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm1-1h4v-2H4v2Z"
      />
    </svg>
  )
}
export default Card
