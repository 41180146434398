import React from 'react'

import Block from '../../containers/SharedBlock'
import LawInfo from './LawInfo'
import { LayoutSelector } from './LayoutSelector'
import { Logo } from './Logo'
import { Footer } from './Styled'
import { FooterMenu } from './menu/FooterMenu'
import { SecondaryMenu } from './menu/SecondaryMenu'

export function MainFooter() {
  return (
    <Footer>
      <FooterMenu />
      <SecondaryMenu />
      <Block name="footerBlock" />
      <Logo />
      <LawInfo />
      <LayoutSelector />
      <Block name="extra_page_contents" />
    </Footer>
  )
}
