/* eslint-disable default-param-last */
import Translator from 'bazinga-translator'
import { fromJS } from 'immutable'

import insertCaptchaToStateForm from '../../utils/insertCaptchaToStateForm'
import { RESET_PAGE_STATE, INSERT_CAPTCHA } from '../MainLayout/constants'
import {
  CONTENT_LOAD,
  CONTENT_LOAD_SUCCESS,
  CONTENT_LOAD_ERROR,
  DELETE_PAYMENT_METHOD_START,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_START,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  TOGGLE_FORM,
  SHOW_FORM,
  HIDE_FORM,
  SELECT_BONUS,
  SELECT_PAYMENT_METHOD,
  BANK_ACCOUNT_ADDED,
  CREDIT_CARD_ADDED,
  ITEM_VERIFIED,
  WITHDRAW_SUCCESS,
} from './constants'

// The initial state of the App
export const initialState = fromJS({
  loading: undefined,
  error: undefined,
  formPromoCode: undefined,
  leftToDeposit: undefined,
  methods: undefined,
  setDefaultUrl: undefined,
  payuConfig: undefined,
  isDepositPossible: undefined,
  isWithdrawPossible: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state.set('loading', true).set('error', false)

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('formPromoCode', fromJS(action.data.formPromoCode))
        .set('leftToDeposit', action.data.leftToDeposit === false ? undefined : Number(action.data.leftToDeposit))
        .set('methods', fromJS(action.data.methods))
        .set('payuConfig', fromJS(action.data.payuConfig))
        .set('setDefaultUrl', action.data.setDefaultUrl)
        .set('isDepositPossible', action.data.isDepositPossible)
        .set('isWithdrawPossible', action.data.isWithdrawPossible)

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case DELETE_PAYMENT_METHOD_START:
      return state.set(
        'methods',
        state.get('methods').map((method) =>
          method.set(
            'items',
            method.get('items').map((item) => {
              if (item.get('text') === action.data.text) {
                return item.set('deleting', true)
              }
              return item
            }),
          ),
        ),
      )

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return state
        .set('loading', undefined)
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'items',
              method
                .get('items')
                .map((item) => {
                  if (action.data.paymentMethod && item.get('text') === action.data.paymentMethod.text) {
                    return fromJS(action.data.paymentMethod)
                  }
                  return item
                })
                .filter((item) => !item.get('deleted')),
            ),
          ),
        )
        .set('error', false)

    case DELETE_PAYMENT_METHOD_ERROR:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'items',
              method.get('items').map((item) => item.set('deleting', false)),
            ),
          ),
        )
        .set(
          'deleteError',
          (action.data && (action.data.error || action.data.data._form)) ||
            Translator.trans('unknown_error', {}, 'errors'), // eslint-disable-line no-underscore-dangle
        )

    case SET_DEFAULT_PAYMENT_METHOD_START:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'items',
              method.get('items').map((item) => {
                if (item.get('text') === action.data.text) {
                  return item.set('settingDefault', true)
                }
                return item
              }),
            ),
          ),
        )
        .set('error', false)

    case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'items',
              method.get('items').map((item) => {
                if (action.data.paymentMethod) {
                  if (item.get('text') === action.data.paymentMethod.text) {
                    return fromJS(action.data.paymentMethod)
                  }
                  if (item.get('default')) {
                    return item.set('default', false)
                  }
                }
                if (item.get('settingDefault')) {
                  return item.set('settingDefault', false)
                }
                return item
              }),
            ),
          ),
        )
        .set('error', false)

    case SET_DEFAULT_PAYMENT_METHOD_ERROR:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'items',
              method
                .get('items')
                .map((item) => (item.get('settingDefault') ? item.set('settingDefault', false) : item)),
            ),
          ),
        )
        .set('error', (action.data && action.data.error) || Translator.trans('unknown_error', {}, 'errors'))

    case TOGGLE_FORM:
      return state
    // .set('methods', state.get('methods').map(method =>
    //   method.set('forms', method.get('forms').map(form => {
    //     if (form && form.get('shown')) {
    //       return form.set('shown', false)
    //     }
    //     if (form && form.get('name') === action.data) {
    //       return form.set('shown', true)
    //     }
    //     return form
    //   }))
    // ))
    // .set('error', false)

    case SHOW_FORM:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'forms',
              method.get('forms').map((form) => {
                if (form && form.get('name') === action.data) {
                  return form.set('shown', true)
                }
                return form
              }),
            ),
          ),
        )
        .set('error', false)

    case HIDE_FORM:
      return state
        .set(
          'methods',
          state.get('methods').map((method) =>
            method.set(
              'forms',
              method.get('forms').map((form) => {
                if (form && form.get('shown')) {
                  return form.set('shown', false)
                }
                return form
              }),
            ),
          ),
        )
        .set('error', false)

    case SELECT_PAYMENT_METHOD:
      return state
        .set(
          'methods',
          state.get('methods').map(
            (method) =>
              method.set(
                'items',
                method.get('items').map((item) => {
                  if (item.get('selected')) {
                    return item.set('selected', false)
                  }
                  if (item.get('text') === action.data.get('text')) {
                    return item.set('selected', true)
                  }
                  // console.log(action.data.toJS(), item.toJS())
                  return item
                }),
              ),
            // .set('forms', method.get('forms').map(form => {
            //   if (form.get('name') === action.data) {
            //     return form.set('shown', !form.get('shown'))
            //   }
            //   return form
            // }))
          ),
        )
        .set('error', false)
    /*
    .setIn(['formNewBankAccount', 'shown'], false)
    .setIn(['formNewCreditCard', 'shown'], false)
    .set('paymentMethods', state.get('paymentMethods').map(method => {
      if (method.get('selected')) {
        return method.set('selected', false)
      }
      if (method.get('text') === action.data.get('text')) {
        return method.set('selected', true)
      }
      return method
    }))
    .set('bonusTypes', state.get('bonusTypes').map(bonus => {
      if (bonus.get('selected')) {
        return bonus.set('selected', false)
      }
      return bonus
    }))
  */

    case SELECT_BONUS:
      return state.set(
        'bonusTypes',
        state.get('bonusTypes').map((bonus) => {
          if (bonus.get('code') === action.data.get('code')) {
            return bonus.set('selected', !bonus.get('selected'))
          }
          if (bonus.get('selected')) {
            return bonus.set('selected', false)
          }
          return bonus
        }),
      )

    case BANK_ACCOUNT_ADDED:
      return state.set('bankAccountAlreadyAdded', true)

    case CREDIT_CARD_ADDED:
      return state.set('creditCardAlreadyAdded', true)

    case ITEM_VERIFIED:
      return state.set('loading', undefined).set('error', false)

    case WITHDRAW_SUCCESS:
      return initialState

    case INSERT_CAPTCHA:
      return insertCaptchaToStateForm(state, 'formPromoCode')

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
