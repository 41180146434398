/* eslint-disable no-promise-executor-return */
import { select, call, put, takeLatest, all } from 'redux-saga/effects'

import { getGeneratorsArray } from '../../utils/contentSagasGenerator'
import formdata from '../../utils/requestFormdata'
import { initReloadNeeded } from '../MainLayout/actions'
import {
  deletePaymentMethodSuccess,
  deletePaymentMethodError,
  setDefaultPaymentMethodSuccess,
  setDefaultPaymentMethodError,
  showForm,
  hideForm,
} from './actions'
import {
  CONTENT_LOAD,
  CONTENT_LOAD_SUCCESS,
  CONTENT_LOAD_ERROR,
  DELETE_PAYMENT_METHOD_START,
  SET_DEFAULT_PAYMENT_METHOD_START,
  TOGGLE_FORM,
  PAYMENT_METHODS_STATE_KEY,
} from './constants'

export function* deletePaymentMethodLoad(action) {
  try {
    const data = yield call(formdata, action.data.url, action.data.values)
    yield put(deletePaymentMethodSuccess(data.data, data.flashMessages))
    yield put(initReloadNeeded())
  } catch (err) {
    yield put(deletePaymentMethodError((err && err.date) || err))
  }
}

export function* deletePaymentMethodData() {
  yield takeLatest(DELETE_PAYMENT_METHOD_START, deletePaymentMethodLoad)
}

export function* setDefaultPaymentMethodLoad(action) {
  try {
    const data = yield call(formdata, action.data.url, action.data.values)
    yield put(setDefaultPaymentMethodSuccess(data.data, data.flashMessages))
  } catch (err) {
    yield put(setDefaultPaymentMethodError(err))
  }
}

export function* setDefaultPaymentMethodData() {
  yield takeLatest(SET_DEFAULT_PAYMENT_METHOD_START, setDefaultPaymentMethodLoad)
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

function* handleToggleForm(action) {
  const state = yield select()

  yield put(hideForm(action.data))
  const methods = state.getIn([PAYMENT_METHODS_STATE_KEY, 'methods'])
  const shownAddMethod = methods.find((m) => m.getIn(['forms', 'add', 'shown']))
  const delayed = !!shownAddMethod
  const show = !shownAddMethod || shownAddMethod.getIn(['forms', 'add', 'name']) !== action.data

  if (delayed) {
    yield call(delay, 50)
  }

  if (show) {
    yield put(showForm(action.data))
  }
}

function* toggleForm() {
  yield takeLatest(TOGGLE_FORM, handleToggleForm)
}

const generators = getGeneratorsArray(CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR)

export default function* rootSaga() {
  yield all([
    ...generators.map((generator) => generator()),
    deletePaymentMethodData(),
    setDefaultPaymentMethodData(),
    toggleForm(),
  ])
}
