import clone from './clone'

/* eslint-disable no-underscore-dangle */

/**
 * Check if form error is csrf error
 * @param {object} error form response error
 *
 * @returns {boolean} if error a CSRF error
 */
export function isCsrfError(error) {
  if (error && error.data) {
    const msg = error.data._form || error.data
    return msg.indexOf && msg.indexOf('CSRF') >= 0
  }

  return false
}

/**
 * Getting token from response data obj
 * @param {object} data response data object with formdata
 *
 * @returns {string} token
 */
export function findCsrfTokenInResponse(data) {
  if (data && data.login_token) {
    return data.login_token
  }

  let token
  const keys = Object.keys(data)
  keys.forEach((key) => {
    const val = data[key]
    if (val.fields && val.fields.forEach) {
      val.fields.forEach((field) => {
        if (field.name.indexOf('_token') > -1 || field.name.indexOf('_token2') > -1) {
          token = field.value
        }
      })
    }
  })

  return token
}

/**
 * Getting token from response data obj
 * @param {object} data response data object with formdata
 *
 * @returns {object} new values
 */
export function putTokenToValues(token, values) {
  const newValues = clone(values)

  if (newValues._csrf_token) {
    newValues._csrf_token = token
  } else {
    const keys = Object.keys(newValues)
    keys.forEach((key) => {
      const val = newValues[key]

      if (val && val._token) {
        newValues[key]._token = token
      }

      if (val && val._token2) {
        newValues[key]._token2 = token
      }
    })
  }

  return newValues
}
