import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media, transition } from '../../utils/style-utils'

const StyledUl = styled.ul`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  ${transition('opacity', 0.2)};
  position: absolute;
  list-style: none;
  height: 40px;
  padding: 0;
  margin: 0;

  ${media.md`
    position: relative;
    height: ${(props) => (props.open ? '40px' : '0')};
  `}
`

function Ul(props) {
  return <StyledUl open={props.open}>{props.children}</StyledUl>
}

Ul.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
}

export default Ul
