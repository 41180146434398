import * as React from 'react'

import { IconProps } from '../types'

function User(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM12 1a6.5 6.5 0 0 0-3.911 11.692c-3.427 1.31-5.407 4.242-6.37 6.674C.945 21.316 2.581 23 4.36 23h15.404c1.713 0 3.34-1.57 2.675-3.497-.866-2.514-2.823-5.546-6.475-6.852A6.5 6.5 0 0 0 12 1ZM3.578 20.103C4.703 17.263 7.24 14 12 14c5.137 0 7.581 3.344 8.55 6.155a.535.535 0 0 1-.098.545.887.887 0 0 1-.687.3H4.361a.887.887 0 0 1-.703-.318.556.556 0 0 1-.08-.579Z"
      />
    </svg>
  )
}
export default User
