import { LOAD, LOAD_SUCCESS, LOAD_ERROR, RESEND_SMS, RESEND_SMS_SUCCESS, RESEND_SMS_ERROR } from './constants'

export function load(pathname) {
  return {
    type: LOAD,
    pathname,
  }
}

export function loadSuccess(data, flashMessages) {
  return {
    type: LOAD_SUCCESS,
    data,
    flashMessages,
  }
}

export function loadError(error) {
  return {
    type: LOAD_ERROR,
    error,
  }
}

export function resendSms(pathname, postData = null) {
  return {
    type: RESEND_SMS,
    pathname,
    postData,
  }
}

export function resendSmsSuccess(data) {
  return {
    type: RESEND_SMS_SUCCESS,
    flashMessages: data.flashMessages,
  }
}

export function resendSmsError(error) {
  return {
    type: RESEND_SMS_ERROR,
    error,
  }
}
