import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import MainHeader from '../../components/MainHeader'
import { makeSelectMainMenu, makeSelectMobileMenuOpen, makeSelectUserMenuOpen } from '../MainLayout/selectors'
import { toggleMobileMenu } from '../MobileMenuBox/actions'
import { toggleUserMenu } from './actions'

class MainHeaderBox extends React.Component {
  static propTypes = {
    secondaryMenuOpen: PropTypes.bool,
    userMenuOpen: PropTypes.bool,
    menu: ImmutablePropTypes.list,
    user: ImmutablePropTypes.map,
  }

  shouldComponentUpdate(nextProps) {
    const secondaryMenuOpenEq = nextProps.secondaryMenuOpen === this.props.secondaryMenuOpen
    const userMenuOpenEq = nextProps.userMenuOpen === this.props.userMenuOpen
    const menuEq =
      (nextProps.menu && nextProps.menu.equals && nextProps.menu.equals(this.props.menu)) ||
      nextProps.menu === this.props.menu
    const userEq =
      (nextProps.user && nextProps.user.equals && nextProps.user.equals(this.props.user)) ||
      nextProps.user === this.props.user

    return !secondaryMenuOpenEq || !userMenuOpenEq || !menuEq || !userEq
  }

  render() {
    return <MainHeader {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  secondaryMenuOpen: makeSelectMobileMenuOpen(),
  userMenuOpen: makeSelectUserMenuOpen(),
  menu: makeSelectMainMenu(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    toggleMobileMenu() {
      dispatch(toggleMobileMenu())
    },
    toggleUserMenu() {
      dispatch(toggleUserMenu())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHeaderBox)
