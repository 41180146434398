import styled from 'styled-components'

import { media } from '../../utils/style-utils'

interface Props {
  on: keyof typeof media
}

export const Show = styled.div<Props>`
  display: none;
  ${({ on }) =>
    media[on]`
      display: inherit;
    `}
`
