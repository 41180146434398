import styled, { css } from 'styled-components'

import { bh, fontWeightMedium, media } from '../../utils/style-utils'
import H from './index'

export const styles = css`
  ${H}
  font-size: ${bh * 1.5}px;
  font-weight: ${fontWeightMedium};

  ${media.touch`
    font-size: 1.4rem;
  `}
`

export default styled.h4`
  ${styles}
`
