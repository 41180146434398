import React from 'react'

import Translator from 'bazinga-translator'
import withCondition from 'components/withCondition'

import { PLAYER_VERIFICATION_STATUS_ADDRESS } from '../../containers/App/constants'
import Btn from '../Btn'
import ExclamationTriangle from '../Icons/components/Warning'

function FinishRegistrationButtonComponent({ ...props }) {
  return (
    <Btn
      link
      to={PLAYER_VERIFICATION_STATUS_ADDRESS}
      md
      danger
      {...props}
    >
      <ExclamationTriangle />
      {Translator.trans('your_account_is_limited', {}, 'users')}
    </Btn>
  )
}

export const FinishRegistrationButton = withCondition(FinishRegistrationButtonComponent)
