/* eslint-disable @typescript-eslint/await-thenable */
import { request, post } from '../../utils/request'
import {
  GET_ACTIVE_SUBSCRIPTIONS_TOURNAMENT_URL,
  GET_SUBSCRIBE_TOURNAMENT_URL,
  GET_TOURNAMENTS_SHOWCASE_URL,
  GET_VIP_TOURNAMENTS_SHOWCASE_URL,
} from './constants'
import { Tournament } from './types/Tournament'
import { UserMembership } from './types/UserMembership'

export async function getTournaments(): Promise<Tournament[]> {
  const { data } = await request(GET_TOURNAMENTS_SHOWCASE_URL())

  return data as Tournament[]
}

export async function getVIPTournaments(playerLevel: string): Promise<Tournament[]> {
  const { data } = await request(GET_VIP_TOURNAMENTS_SHOWCASE_URL(playerLevel))

  return data as Tournament[]
}

export async function subscribe(tournamentId: number): Promise<string> {
  const { data } = await post(GET_SUBSCRIBE_TOURNAMENT_URL(tournamentId))

  return data as string
}

export async function getActiveSubscriptions(): Promise<UserMembership[]> {
  const { data } = await request(GET_ACTIVE_SUBSCRIPTIONS_TOURNAMENT_URL())

  return data as UserMembership[]
}
