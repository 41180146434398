import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import MenuLink from './MenuLink'
import Ul from './MenuUl'

const Li = styled.li`
  flex-grow: 1;
  max-width: 130px;
  white-space: nowrap;
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  ${media.md`
    font-size: .857em;
    border: none;

    &:last-child {
      border: none;
    }
  `}
`

function Menu({ menu }) {
  const menuItems = menu.map((item, id) => (
    <Li key={id}>
      <MenuLink
        link={item.get('uri')}
        label={item.get('label')}
        icon={item.get('icon')}
        rel={item.get('rel')}
      />
    </Li>
  ))

  return <Ul>{menuItems}</Ul>
}

Menu.propTypes = {
  menu: PropTypes.node,
}

export default Menu
