import React, { ComponentType } from 'react'

import { isTouchCached } from 'utils/device'

export function renderOnMobile<T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
  return function RenderOnMobile(props: T) {
    if (isTouchCached()) {
      return <Component {...props} />
    }

    return null
  }
}
