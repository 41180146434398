import { QueryValue } from '../../routing/routeReducerWithQuery'
import useChangeQueryParameter from './useChangeQueryParameter'
import { useURLSearchParams } from './useURLSearchParams'

export default function useQueryParameters<TParameter extends QueryValue = QueryValue>(
  key: string,
): [TParameter, (newValue?: TParameter) => void] {
  const value = useURLSearchParams<TParameter>((query) => query?.[key] as TParameter)
  const onChange = useChangeQueryParameter<TParameter>(key)

  return [value, onChange]
}
