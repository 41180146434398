import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import Col from '../FlexBoxGrid/Col'
import Money from '../Money'
import CancelAllWithdrawalsButton from './CancelAllWithdrawalsButton'
import { AccountRowWrapper } from './styles/AccountRowWrapper'

function AccountRow({ label, value, isSum, isOnline, type, isExtendedCashField, ...rest }) {
  const showCancelAllWithdrawals = type === 'withdraw' && label === 'CustomerWithdrawalAccount'

  return (
    <AccountRowWrapper
      isSum={isSum}
      tableStyle
      {...rest}
    >
      <Col
        col={4}
        xs={12}
      >
        {Translator.trans(label, {}, 'payments')}
      </Col>

      <Col
        col={4}
        xs={12}
        center
      >
        <CancelAllWithdrawalsButton condition={showCancelAllWithdrawals} />
      </Col>

      <Col
        col={isExtendedCashField ? 6 : 4}
        xs={12}
      >
        {value === '0 EUR' ? (
          <b>0 €</b>
        ) : (
          <b>
            <Money
              className={`user-balance-status-${label}`}
              money={value}
            />
          </b>
        )}
      </Col>
    </AccountRowWrapper>
  )
}

AccountRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDeposit: PropTypes.bool,
  isSum: PropTypes.bool,
  isOnline: PropTypes.bool,
  isExtendedCashField: PropTypes.bool,
  type: PropTypes.string,
}

export default AccountRow
