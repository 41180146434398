import styled from 'styled-components'

import { darken } from '../../utils/style-fns'
import { cmsBannerHeight, media, bannerImg, brandSecondaryDark } from '../../utils/style-utils'

export default styled.div`
  background: url('${bannerImg}');
  background-size: 120% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: ${cmsBannerHeight}px;

  ${media.md`
    background: ${darken(brandSecondaryDark, 0.6)};
    height: 1px;
  `}
`
