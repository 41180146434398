import { BASE_PATH, LANG } from '../App/constants'

export const LOGIN_STATE_KEY = 'loginPage'
export const LOGIN_FORM_NAME = 'loginForm'

export const LOGIN_ADDRESS = `/${LANG}/login`
export const LOGIN_WITH_COLLECT = '/oauth/connect/collect'
export const LOGIN_WITH_BANK_ID_CZ = '/oauth/connect/bank_id_cz'
export const LOGIN_WITH_BANK_ID_CZ_LOCKING = '/oauth/connect/bank_id_cz?locking=1'
export const LOGIN_URL = `${BASE_PATH}${LOGIN_ADDRESS}`
export const LOGOUT_ADDRESS = `/${LANG}/logout`
export const REGISTER_ADDRESS = `/${LANG}/register`
export const REGISTER_CHOICE_ADDRESS = `/${LANG}/register/choice`
export const REGISTER_URL = `${BASE_PATH}${REGISTER_ADDRESS}`
export const FORGOT_PASSWORD_ADDRESS = `/${LANG}/resetting/request`
export const CONTINUE_VLT_REGISTRATION_ADDRESS = `/${LANG}/continue-vlt-registration`
export const LANDBASE_REGISTRATION_ADDRESS = `/${LANG}/game-rooms`
export const NETERA_REGISTRATION_ADDRESS = `/${LANG}/netera/register`
export const IDENTITY_DOCUMENT_REGISTRATION_ADDRESS = `/${LANG}/register/identity-document`

export const LOGIN_FORM_LOAD_URI = `${BASE_PATH}/content/${LANG}/login-form`
export const LOGIN_FORM_SEND_URI = `${BASE_PATH}/${LANG}/login_check`

export const LOGIN_FORM_LOAD = 'Casino/Forms/LOGIN_FORM_LOAD'
export const LOGIN_FORM_LOAD_SUCCESS = 'Casino/Forms/LOGIN_FORM_LOAD_SUCCESS'
export const LOGIN_FORM_LOAD_ERROR = 'Casino/Forms/INIT_LOAD_ERROR'

export const SEND_LOGIN_FORM = 'Casino/Forms/SEND_LOGIN_FORM'
