import React from 'react'

export default function Tech4Bet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 255.12 255.12"
      width="64"
      height="64"
      {...props}
    >
      <path
        transform="translate(-86.58 -92.205) scale(1.68177)"
        d="m121.84 169.89-.01-113.25H98.04v101.32z"
      />
      <path
        transform="translate(-86.58 -92.205) scale(1.68177)"
        d="m135.84 127.02.01 77.94h23.79v-66.01z"
      />
    </svg>
  )
}
