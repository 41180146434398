/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { ITEM_VERIFIED } from '../PaymentMethodsPage/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

const initialState = fromJS({
  loading: undefined,
  error: undefined,
  payment: undefined,
  formVerifyCreditCard: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('payment', initialState.get('payment'))
        .set('formVerifyCreditCard', initialState.get('formVerifyCreditCard'))
        .set('formVerifyCreditCard', initialState.get('formVerifyCreditCard'))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('payment', fromJS(action.data.payment))
        .set('formVerifyCreditCard', fromJS(action.data.formVerifyCreditCard))
        .set('moneyCashable', action.data.moneyCashable)

    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case ITEM_VERIFIED:
      return state.set('loading', undefined).set('error', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
