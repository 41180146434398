import styled from 'styled-components'

import { bh, fontWeightLight, media } from '../../utils/style-utils'
import H from './index'

export default styled.h5`
  ${H}
  font-size: ${bh * 1.25}px;
  font-weight: ${fontWeightLight};

  ${media.touch`
  font-size: 1.2rem;
  `}
`
