import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ACCOUNT_ONLINE, ACCOUNT_ACCOUNTING_SERVER } from '../../containers/App/constants'
import { media, bh, mainHeaderColor } from '../../utils/style-utils'
import User from '../Icons/components/User'
import { Message, Warning } from '../Indicator'
import DotLoader from '../Loader/DotLoader'
import BalanceStatus from './BalanceStatus'

const StyledButton = styled(Link)`
  position: relative;
  text-align: center;
  padding-left: ${bh}px;

  top: ${bh / 3}px;

  ${media.md`
    top: 2px;
  `}
`.withComponent('span')

const UserIcon = styled(User)`
  width: 25px;
  height: 25x;
  fill: ${mainHeaderColor};
  cursor: pointer;
`

const StyledWarning = styled(Warning)`
  display: none;
  position: absolute;
  left: -10px;
  top: 8px;
  cursor: pointer;

  ${media.md`
    display: flex;
  `}
`

export const StyledMessage = styled(Message)`
  display: flex;
  position: absolute;
  left: auto;
  top: -12px;
  right: -10px;
  cursor: pointer;
`

function UserToggle({ toggleUserMenu, user, ...props }) {
  const newMessages = user.get('newMessages') > 0
  const warnings = !(user.get('isMobileConfirmed') && user.get('fullyActivated'))
  const currentAccount = user.get('currentAccount') || ACCOUNT_ONLINE
  const money = user.get('money') || user.getIn(['balances', currentAccount, 'balance'])
  const moneyInGame = user.get('money') || user.getIn(['balances', ACCOUNT_ACCOUNTING_SERVER, 'balance'])
  const moneySum = money + (moneyInGame || 0)
  const reInitialize = !!user.get('reInitialize')

  return (
    <StyledButton
      title={user.get('fullname')}
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        toggleUserMenu()
      }}
    >
      {warnings && <StyledWarning />}
      {newMessages && <StyledMessage />}

      <UserIcon />

      <br />

      {reInitialize ? <DotLoader /> : <BalanceStatus money={moneySum} />}
    </StyledButton>
  )
}

UserToggle.propTypes = {
  toggleUserMenu: PropTypes.func,
  user: ImmutablePropTypes.map,
}

export default UserToggle
