import React from 'react'

import { Hide } from 'components/Media/Hide'
import { MobileConfirmButton } from 'components/MobileConfirm/MobileConfirmButton'
import { AmlFormButton } from 'components/UserHeader/AmlFormButton'
import { FinishRegistrationButton } from 'components/UserHeader/FinishRegistrationButton'
import { MakeDepositButton } from 'components/UserHeader/MakeDepositButton'
import { VerifyCardButton } from 'components/UserHeader/VerifyCardButton'
import { useUser } from 'containers/MainLayout/selectors'

export function ImportantButtons() {
  const { mobile, isMobileConfirmed, isCardToVerify, fullyActivated, isAMLFillRequired, isOnlineDepositPossible } =
    useUser()

  const showMobileConfirm = !!mobile && !isMobileConfirmed
  const showVerifyCard = !showMobileConfirm && isCardToVerify
  const showFinishRegistration = !showVerifyCard && !fullyActivated
  const showAmlForm = !showFinishRegistration && isAMLFillRequired
  const showMakeDeposit = !showAmlForm && isOnlineDepositPossible

  return (
    <Hide on="md">
      <AmlFormButton condition={showAmlForm} />
      <FinishRegistrationButton condition={showFinishRegistration} />
      <VerifyCardButton condition={showVerifyCard} />
      <MobileConfirmButton condition={showMobileConfirm} />
      <MakeDepositButton condition={showMakeDeposit} />
    </Hide>
  )
}
