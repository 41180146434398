/* eslint-disable no-unused-expressions */
// import { isKioskCached, isTouchCached } from './device'
import {
  bg,
  color,
  baseFontSize,
  baseFontFamily,
  brandPrimary,
  grayDark,
  grayLightest,
  bh,
  fontWeightLight,
  brandSecondary,
  gray,
  grayLighter,
  bgAccent,
} from './style-utils'

// ${
// isKioskCached() && isTouchCached() ? `
//   html, html * {
//     /*cursor: none !important;*/
//   }` : ''
// }
export default `
  * * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .no-touch {
    *::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    *::-webkit-scrollbar-track {
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${gray};
      border-radius: 8px;
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: ${grayLighter};
    }
  }

  html {
    overflow-y: scroll;
  }

  .scroll-lock {
    overflow: hidden;
  }

  body, html {
    min-height: 100%;
    font-family: Verdana, Geneva, sans-serif;
    font-size: ${baseFontSize}px;
    letter-spacing: -1px;
    color: ${color};
    background-image: radial-gradient(
      circle at 40% 0,
      ${bgAccent} 0%,
      ${bg} 1600px
    );
  }

  body.fontLoaded {
    font-size: ${baseFontSize}px;
    font-family: ${baseFontFamily};
    letter-spacing: 0;
  }

  a {
    color: ${brandSecondary};
    text-decoration: none;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }


  input,
  textarea,
  select {
    width: 100%;
    outline-color: ${brandSecondary};
    background: ${grayDark};
  }

  html {
  font-family: sans-serif; // 1
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  }

  body {
    margin: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  a {
    background-color: transparent;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .betor-calendar select {
    background: none;
    padding: ${bh / 8}px ${bh / 4}px;
    margin: ${bh / 4}px ${bh / 8}px;
    font-weight: ${fontWeightLight};
  }

  .betor-calendar .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background: ${grayLightest};
  }

  .betor-calendar .react-datepicker__day--selected,
  .betor-calendar .react-datepicker__day--keyboard-selected {
    background: ${brandSecondary};
  }

  .betor-calendar .react-datepicker__day--in-range {
    background: ${brandSecondary};
  }

  .betor-calendar .react-datepicker__day--in-range:hover {
    background: ${brandSecondary};
  }

  .betor-calendar {
    margin-top: ${-bh}px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-popper {
    min-width: 250px;
    z-index: 2 !important;
  }

  /* ondreys games descriptions */
  .fbox__item--desc {
    border-left: 0 !important;
    box-shadow: none !important;
  }
  .t-grey .fbox__item--desc {
    color: black;
  }

  .xtras .fbox__item .h4, .xtras .fbox__item h4 {
    color: inherit !important;
  }

  /** BRICK CHAT STYLES */
  .b24-widget-button-openline_livechat,
  .b24-widget-button-callback,
  .b24-widget-button-crmform {
    background-color: ${brandPrimary} !important;
  }
  .b24-widget-button-inner-mask {
    background: ${brandPrimary} !important;
  }
  .b24-widget-button-inner-block {
    background: ${brandPrimary} !important;
  }
  .b24-widget-button-pulse {
    border-color: ${brandPrimary} !important;
    display: none !important;
  }
`
