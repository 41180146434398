import styled from 'styled-components'

import { bh, brandSecondary, lighten, fontWeightMedium, white } from '../../utils/style-utils'
import { btnPrimary, btn } from '../Btn'
import { styles as H2 } from '../H/H2'
import { styles as H3 } from '../H/H3'
import { styles as H4 } from '../H/H4'

export default styled.div`
  h2 {
    ${H2}
    color: ${white};
  }

  h3 {
    ${H3}
    color: ${white};
  }

  h4 {
    ${H4}
    color: ${white};
  }

  ul,
  ol {
    padding: 0 0 0 ${bh * 2}px;
    margin: 0 0 ${bh * 2}px 0;

    li {
      padding: ${bh / 2}px 0 ${bh / 2}px ${bh}px;
    }
  }

  li:last-child {
    > ul,
    > ol {
      margin-bottom: 0;
    }
  }

  table {
    padding: 0;
    border-spacing: 0;
    width: 100%;
    font-size: ${bh}px;

    th,
    td {
      padding: ${bh / 2}px ${bh * 2}px;
      text-align: left;
    }

    th {
      font-weight: ${fontWeightMedium};
      background: rgba(0, 0, 0, 0.4);
    }

    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }

  a {
    color: ${brandSecondary};
    border-bottom: 1px dotted ${brandSecondary};

    :hover {
      color: ${lighten(brandSecondary, 0.3)};
    }
  }

  a.btn {
    ${btn}
    ${btnPrimary}
  }
`
