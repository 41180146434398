import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import YouTube from 'react-youtube'

import styled from 'styled-components'

import { bh, media } from '../../../utils/style-utils'
import H5 from '../../H/H5'

const Wrapper = styled.div`
  margin-bottom: ${bh * 2}px;
`

const PlayerWrapper = styled.div`
  width: 100%;
  max-width: ${1600 / 2}px;
  height: ${900 / 2}px;

  & > div {
    width: 100%;
    height: 100%;
  }

  ${media.md`
    max-width: ${1600 / 4}px;
    height: ${900 / 4}px;
  `}
`

function Youtube(props) {
  const item = props.media
  const opts = {
    // https://developers.google.com/youtube/player_parameters
    width: '100%',
    height: '100%',
  }

  return (
    <Wrapper>
      <H5 primary>{item.get('title')}</H5>

      <p>{item.get('description')}</p>

      <PlayerWrapper>
        <YouTube
          opts={opts}
          videoId={item.get('videoId')}
        />
      </PlayerWrapper>
    </Wrapper>
  )
}

Youtube.propTypes = {
  media: ImmutablePropTypes.map.isRequired,
}

export default Youtube
