/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  RELOAD_REGISTER_FORM,
  RELOAD_REGISTER_FORM_SUCCESS,
  RELOAD_REGISTER_FORM_ERROR,
  GO_TO_STAGE_TWO,
  FINISH_WITHOUT_CONFIRMING,
  FORM_STAGE_ONE_NAME,
  FORM_STAGE_TWO_NAME,
} from './constants'

const initialState = fromJS({
  loading: undefined,
  loadingCaptcha: false,
  error: false,
  sendingSms: undefined,
  termsUrl: '',
  acceptanceOfProcessingOfPersonalDataUrl: '',
  formType: '',
  informationsPath: '',
  step: 0,
  [FORM_STAGE_ONE_NAME]: {
    fields: [],
  },
  [FORM_STAGE_TWO_NAME]: {
    fields: [],
  },
})

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return state.set('loading', true).set('error', false)

    case LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('formType', action.data.formType)
        .set('informationsPath', action.data.informationsPath)
        .set(FORM_STAGE_ONE_NAME, fromJS(action.data[FORM_STAGE_ONE_NAME]))

    case LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RELOAD_REGISTER_FORM:
      return state.set('loadingCaptcha', true)

    case RELOAD_REGISTER_FORM_SUCCESS:
      return state.set(FORM_STAGE_TWO_NAME, fromJS(action.data[FORM_STAGE_TWO_NAME])).set('loadingCaptcha', false)

    case RELOAD_REGISTER_FORM_ERROR:
      return state.set('error', action.error).set('loadingCaptcha', false)

    case GO_TO_STAGE_TWO:
      return state
        .set('step', 1)
        .set('formType', action.data.formType)
        .set('termsUrl', action.data.termsUrl)
        .set('acceptanceOfProcessingOfPersonalDataUrl', action.data.acceptanceOfProcessingOfPersonalDataUrl)
        .set(FORM_STAGE_TWO_NAME, fromJS(action.data[FORM_STAGE_TWO_NAME]))

    case FINISH_WITHOUT_CONFIRMING:
      return state.set('step', 2)

    default:
      return state
  }
}

export default registerReducer
