/* eslint-disable default-param-last */
import { startOfMonth } from 'date-fns'
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import {
  SET_FILTERED,
  SET_FOCUSED,
  GAME_HISTORY_TYPES,
  LIMIT_HISTORY_TYPES,
  PAYMENT_HISTORY_TYPES,
  SESSION_HISTORY_TYPES,
} from './constants'

const initialState = fromJS({
  filtered: [
    { id: 'date', value: { startDate: startOfMonth(new Date()), endDate: new Date() } },
    { id: 'description', value: false },
  ],
  dateRangePicker: {
    focusedInput: null,
  },
  transactionTypes: [...PAYMENT_HISTORY_TYPES, ...GAME_HISTORY_TYPES, ...SESSION_HISTORY_TYPES, ...LIMIT_HISTORY_TYPES],
})

function transactionHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERED: {
      const key = state.get('filtered').findKey((e) => e.get('id') === action.filtered.id)
      return state.setIn(['filtered', key, 'value'], fromJS(action.filtered.value))
    }
    case SET_FOCUSED:
      return state.setIn(['dateRangePicker', 'focusedInput'], fromJS(action.focused))

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default transactionHistoryReducer
