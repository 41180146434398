import React from 'react'
import { connect } from 'react-redux'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'

import InfoDialog from '../../components/Dialog/InfoDialog'
import { isCookieSet, deleteCookie } from '../../utils/cookies'
import { makeSelectIsLogged } from '../MainLayout/selectors'
import PlayerBetsWinsForSkBoxLazy from '../PlayerBetsWinsForSkPage/Loadable'
import { COOKIE_SK_PLAYER_GAMES_VALUES } from './constants'

const dialogActiveTime = 5.5 * 1000 // 5.5s

class PlayerBetsWinsForSkDialog extends React.Component {
  static propTypes = {
    userIsLogged: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const isCookie = isCookieSet(COOKIE_SK_PLAYER_GAMES_VALUES)
    this.state = {
      show: isCookie && props.userIsLogged,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newShow = isCookieSet(COOKIE_SK_PLAYER_GAMES_VALUES) && props.userIsLogged
    if (state.show !== newShow) {
      return { show: newShow }
    }

    return null
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }

  startTimeout() {
    clearTimeout(this.timeoutId)
    if (this.state.show) {
      this.timeoutId = setTimeout(() => this.close(), dialogActiveTime)
    }
  }

  close() {
    clearTimeout(this.timeoutId)
    deleteCookie(COOKIE_SK_PLAYER_GAMES_VALUES)
    this.setState({ show: false })
  }

  render() {
    if (!this.state.show) {
      return null
    }

    return (
      <InfoDialog
        zIndex={20}
        id="player-games-values-info-dialog"
        title={Translator.trans('sk_player_games_values_header', {}, 'users')}
        onClose={() => this.close()}
      >
        <PlayerBetsWinsForSkBoxLazy startTimeout={() => this.startTimeout()} />
      </InfoDialog>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userIsLogged: makeSelectIsLogged(),
})

export default connect(mapStateToProps)(PlayerBetsWinsForSkDialog)
