import React from 'react'

import Translator from 'bazinga-translator'
import Button from 'components/Btn'
import { PLAYER_PAYMENT_METHODS_ADDRESS } from 'containers/App/constants'

import { Menu } from '../../../Menu'
import { MainMenu } from './MainMenu'
import { DepositButtonBox } from './styles'

export function Player() {
  return (
    <>
      <Menu />
      <MainMenu />

      <DepositButtonBox>
        <Button
          sm
          outlined
          to={PLAYER_PAYMENT_METHODS_ADDRESS}
        >
          {Translator.trans('Make deposit', {}, 'payments')}
        </Button>
      </DepositButtonBox>
    </>
  )
}
