import React from 'react'

import styled, { keyframes } from 'styled-components'

import { bh, grayLightest } from '../../utils/style-utils'

const Wrapper = styled.div`
  display: inline-block;
`

const dotAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1);
  }
`

const Dot = styled.div`
  animation: 0.6s ${dotAnimation} infinite ease-in-out;
  display: inline-block;
  height: ${bh / 4}px;
  width: ${bh / 4}px;
  margin-right: ${bh / 4}px;
  border-radius: 50%;
  background: ${grayLightest};

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }
`

function DotLoader(props) {
  return (
    <Wrapper
      data-testid="dot-loader"
      {...props}
    >
      <Dot />
      <Dot />
      <Dot />
    </Wrapper>
  )
}
export default DotLoader
