import React from 'react'

import UserIcon from 'components/Icons/components/User'
import Money from 'components/Money'
import { useUserAccountSum } from 'containers/MainLayout/selectors'

import { ProfileBox } from '../DesktopAppBar/User/Player/styles'

interface Props {
  onClick: () => void
}

export function Trigger({ onClick }: Props) {
  const accountSum = useUserAccountSum()

  return (
    <ProfileBox onClick={onClick}>
      <UserIcon />
      <Money money={accountSum} />
    </ProfileBox>
  )
}
