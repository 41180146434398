import styled from 'styled-components'

import { formFieldBg, formFieldBorderColor, formFieldColor, formFieldHeight } from '../../../../utils/style-utils'
import { input } from '../../Input'

export const StyledWrapper = styled.div`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    ${input}
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: ${formFieldHeight}px;
    width: 100%;
    border: 1px solid ${formFieldBorderColor};
    background-color: ${formFieldBg};
    color: ${formFieldColor};
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid ${formFieldBorderColor};
  }

  .react-autosuggest__suggestion--focused {
    background-color: ${formFieldBg};
    color: ${formFieldColor};
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }
`
