import React from 'react'

import { useUser } from 'containers/MainLayout/selectors'

import { StyledWarning } from './styles'

export function Warning() {
  const { isMobileConfirmed, fullyActivated, isCardToVerify, isAMLFillRequired } = useUser()

  const warnings = !isMobileConfirmed || !fullyActivated || isCardToVerify || isAMLFillRequired

  if (!warnings) {
    return null
  }

  return <StyledWarning />
}
