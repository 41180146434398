import styled from 'styled-components'

import { media } from '../../utils/style-utils'
import { up } from './Up'

const Wrapper = styled.div`
  width: ${up.width}px;
  transform-origin: top;

  ${media.sm`
    transform: scale(0.6)
  `}
`

export default Wrapper
