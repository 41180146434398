import { useEffect, useRef } from 'react'

export function useClickOutside(callback: () => void, conditionFn: (node: Element) => boolean = () => true) {
  const ref = useRef(null)

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent | TouchEvent) => {
      if (ref.current && !(ref.current as HTMLElement).contains(target as Node) && conditionFn(target as Element)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClick, true)
    document.addEventListener('touchstart', handleClick, true)

    return () => {
      document.removeEventListener('mousedown', handleClick, true)
      document.removeEventListener('touchstart', handleClick, true)
    }
  }, [callback, conditionFn])

  return ref
}
