import * as React from 'react'

import { IconProps } from '../types'

function Facebook(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12a9 9 0 1 1 10 8.945v-6.014h2.472l.422-3.193H13v-1.673c0-.815.021-1.674.972-2.026.57-.22 2.028-.11 2.028-.11V5.264s-1.648-.352-2.915-.242c-1.268.132-3.36 1.167-3.36 4.008v2.708H7v3.193h2.725v5.78A9.004 9.004 0 0 1 3 12Zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Z"
      />
    </svg>
  )
}
export default Facebook
