import styled from 'styled-components'

import {
  media,
  bh,
  white,
  cmsBannerHeight,
  cmsContentBg,
  cmsContentColor,
  baseFontFamily,
  fontWeightBold,
} from '../../utils/style-utils'
import { sidebarWidth } from '../GenericMenu/SidebarMenuWrapper'

const ContentWrapper = styled.div`
  flex: 1;
  max-width: calc(100% - ${sidebarWidth}px);
  font-size: ${bh}px;
  margin-top: -${cmsBannerHeight - 6 * bh}px;
  min-height: ${bh * 20}px;
  padding: ${bh * 3}px;
  position: relative;
  background: ${cmsContentBg};
  color: ${cmsContentColor};

  .game-header {
    display: none;
  }

  .rules-section {
    max-width: 100%;
    font-family: ${baseFontFamily};
    color: ${white};
  }

  .rules-h1,
  .rules-h2 {
    font-family: ${baseFontFamily};
    font-weight: ${fontWeightBold};
    color: ${white};
  }

  ${media.md`
    margin-left: 0;
    margin-top: 0;
    padding: ${bh * 2}px ${bh}px ${bh}px;
    max-width: 100%;
  `}

  ${media.sm`
    padding: ${bh}px;
  `}
`

export default ContentWrapper
