import { numbers } from '@glt-npm/numbers'

let inited = false
let moneyOptions = window.APP?.gamePlayMoneyFormat

function init() {
  setFormat(
    window.APP.moneyTemplate,
    window.APP.moneyTemplateNr,
    window.APP.denomination,
    window.APP.gamePlayMoneyFormat,
  )
}

/**
 * @param  {number|string} numOrStr
 * @return {number}
 */
export function toNum(numOrStr) {
  return numbers.toNum(numOrStr)
}

export function setFormat(tplStr, tplNr, denomin, moneyOpts) {
  const currency = tplStr.replace(tplNr, '').trim()
  moneyOptions = moneyOpts
  inited = true

  return numbers.setFormat(tplStr, tplNr, currency, denomin, moneyOpts)
}

export function money(num, useDenomination, options = moneyOptions) {
  if (!inited) {
    init()
  }
  return numbers.money(num, useDenomination, options)
}

export function unMoney(moneyLocal, useDenomination) {
  if (!inited) {
    init()
  }
  return numbers.unMoney(moneyLocal, useDenomination)
}

export function creditsToMoney(credits, options = moneyOptions) {
  if (!inited) {
    init()
  }
  return numbers.creditsToMoney(credits, options)
}

export function currencyToMoney(credits, options = moneyOptions) {
  if (!inited) {
    init()
  }
  return numbers.currencyToMoney(credits, options)
}

export function creditsToNumberFormat(credits, options = moneyOptions) {
  if (!inited) {
    init()
  }
  return numbers.creditsToNumberFormat(credits, options)
}

export function formatPercent(num, useDenomination) {
  if (!inited) {
    init()
  }
  return numbers.formatPercent(num, useDenomination)
}

export function timeToString(timeLocal) {
  return numbers.timeToString(timeLocal)
}

/**
 * Converts timestamp to Hours:Minutes:Secondes
 * @param {number} milliseconds
 * @returns {string} example -> 12:22:33
 */
export function millisecondsToHMS(milliseconds) {
  return numbers.millisecondsToHMS(milliseconds)
}

export function getCurrency() {
  return numbers.getCurrency()
}

export function pad(value, length) {
  return numbers.pad(value, length)
}

export function isNumeric(string) {
  return numbers.isNumeric(string)
}
