import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import H1 from '../../components/H/H1'
import H3 from '../../components/H/H3'
import Logo from '../../components/MainHeader/Logo'
import Nav from '../../components/MainHeader/Nav'
import { bh, brandPrimaryDarker } from '../../utils/style-utils'
import { HOME_PATH, DEBUG } from '../App/constants'

const Wrapper = styled.div`
  text-align: center;
`

const NavStyled = styled(Nav)`
  justify-content: center;
`

const H1Styled = styled(H1)`
  margin-bottom: ${bh}px;
`

const H3Styled = styled(H3)`
  margin-top: ${bh}px;
`

const Hr = styled.hr`
  border: 0;
  border-top: 1px solid ${brandPrimaryDarker};
  margin: ${bh}px;
`

function ErrorPage({ error }) {
  let status
  let text

  status = error ? error.code : 404
  text = Translator.trans('unknown_error', {}, 'errors')

  if (error && DEBUG) {
    text = Translator.trans(error.message)
  }

  if (!error || !error.code || error.code === 404) {
    status = 404
    text = Translator.trans('Page not found')
  }

  return (
    <Wrapper>
      <NavStyled>
        <Logo />
      </NavStyled>

      <H1Styled>
        {Translator.trans('Error')} {status || 404}
      </H1Styled>

      <H3Styled>{text}</H3Styled>

      <Hr />

      <a href={HOME_PATH}>{Translator.trans('Back to casino')}</a>
    </Wrapper>
  )
}

ErrorPage.propTypes = {
  error: PropTypes.object,
}

export default ErrorPage
