import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const LogoBox = styled.div`
  height: 100%;
`

export const LogoLink = styled(Link)`
  height: 100%;
  padding: 0 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 2rem;
    display: block;
  }
`
