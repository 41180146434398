import styled from 'styled-components'

import { red, white } from '../../../utils/style-utils'

export const ButtonWrapper = styled.div`
  grid-column: span 3;
  text-align: center;
  color: ${red};
  margin: 1rem;

  a {
    padding: 1rem 2rem;
    color: ${white};
    background-color: ${red};
    text-decoration: none;
  }
`
