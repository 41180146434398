import React, { ReactNode, useEffect } from 'react'

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import superjson from 'superjson'

import useAddFlashMessages from '../../components/FlashMessages/hooks/useAddFlashMessages'
import { REACT_QUERY_DEV_TOOLS } from '../App/constants'
import LoadableReactQueryDevtools from './LoadableReactQueryDevtools'
import { queryClient } from './queryClient'
import { getQueryBusterKey, handleOnSuccess } from './utils'

interface Props {
  children: ReactNode
}

export default function ReactQueryProvider({ children }: Props) {
  const { showFlashMessage } = useAddFlashMessages()
  const buster = getQueryBusterKey()
  const persister = createSyncStoragePersister({
    storage: window.localStorage,
    serialize: (client) => superjson.stringify(client),
    deserialize: (cachedString) => superjson.parse(cachedString),
  })

  useEffect(() => {
    queryClient.getQueryCache().config.onSuccess = handleOnSuccess(showFlashMessage)
  }, [])

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        buster,
      }}
    >
      {REACT_QUERY_DEV_TOOLS && <LoadableReactQueryDevtools />}
      {children}
    </PersistQueryClientProvider>
  )
}
