import React from 'react'
import SignOutIcon from 'react-icons/lib/fa/sign-out'

import Translator from 'bazinga-translator'
import CreditCardIcon from 'components/Icons/components/Card'
import DiamondICon from 'components/Icons/components/Diamond'
import UserIcon from 'components/Icons/components/User'
import { BASE_PATH, PLAYER_PAYMENT_METHODS_ADDRESS, PLAYER_PROFILE_ADDRESS } from 'containers/App/constants'
import { BONUSES_URL } from 'containers/BonusesPage/constants'
import { LOGOUT_ADDRESS } from 'containers/LoginPage/constants'

import { Link } from './types'

export const linksMap: Link[] = [
  {
    label: Translator.trans('Show profile', {}, 'users'),
    link: PLAYER_PROFILE_ADDRESS,
    Icon: UserIcon,
  },
  {
    label: Translator.trans('Make deposit', {}, 'payments'),
    link: PLAYER_PAYMENT_METHODS_ADDRESS,
    Icon: CreditCardIcon,
  },
  {
    label: Translator.trans('bonuses', {}, 'bonuses'),
    link: BONUSES_URL,
    Icon: DiamondICon,
    splitAfter: true,
  },
  {
    label: Translator.trans('logout'),
    url: BASE_PATH + LOGOUT_ADDRESS,
    Icon: () => <SignOutIcon />,
  },
]
