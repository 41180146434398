import { css, CSSObject, FlattenInterpolation, SimpleInterpolation, ThemedStyledProps } from 'styled-components'

import { THEME } from '../containers/App/constants'
import admiralTheme from '../themes/admiralTheme'
import betorRedPlusTheme from '../themes/betorRedPlusTheme'
import betorRedTheme from '../themes/betorRedTheme'
import betorTheme from '../themes/betorTheme'
import collectTheme from '../themes/collectTheme'
import doxxbetTheme from '../themes/doxxbetTheme'
import eurogoldTheme from '../themes/eurogoldTheme'
import internetKasinoSkTheme from '../themes/internetKasinoSkTheme'
import slovmaticCasinoTheme from '../themes/slovmaticCasinoTheme'

export { getScaleFromWidths, styleTransform, hexToRgb, darken, lighten, hexRgba } from './style-fns'

const themes = {
  betor: betorTheme,
  betorRed: betorRedTheme,
  betorRedPlus: betorRedPlusTheme,
  admiral: admiralTheme,
  'slovmatic-casino': slovmaticCasinoTheme,
  doxxbet: doxxbetTheme,
  eurogold: eurogoldTheme,
  collect: collectTheme,
  internetkasinosk: internetKasinoSkTheme,
}

const theme = themes[THEME as keyof typeof themes] || betorTheme

/* eslint-disable prefer-destructuring */
export const logo = theme.logo
export const logoMono = theme.logoMono
export const logoHeight = theme.logoHeight
export const footerLogo = theme.footerLogo
export const bannerImg = theme.bannerImg
export const bannerBigImg = theme.bannerBigImg
export const bg = theme.bg
export const bgAccent = theme.bgAccent
export const color = theme.color

export const bh = theme.bh

export const extraFontFamily = theme.extraFontFamily
export const baseFontFamily = theme.baseFontFamily
export const fontWeightLight = theme.fontWeightLight
export const fontWeightMedium = theme.fontWeightMedium
export const fontWeightBold = theme.fontWeightBold
export const baseFontSize = theme.bh

export const bigLg = 1800
const medLg = 1600
const lowLg = 1400

export const xxs = 370
export const xs = 480
export const xsp = 600
export const sm = 768
export const md = 992
export const lg = 1200

const heightXs = 480
export const cmsBannerHeight = bh * 14

/* eslint-disable no-multi-spaces */

export const brandPrimary = theme.brandPrimary
export const brandPrimaryDark = theme.brandPrimaryDark
export const brandPrimaryDarker = theme.brandPrimaryDarker
export const brandSecondary = theme.brandSecondary
export const brandSecondaryDark = theme.brandSecondaryDark

export const warningBg = theme.warningBg
export const dangerBg = theme.dangerBg
export const infoBg = theme.infoBg
export const successBg = theme.successBg

export const warningColor = theme.warningColor
export const dangerColor = theme.dangerColor
export const infoColor = theme.infoColor
export const successColor = theme.successColor

export const white = theme.white
export const grayLightest = theme.grayLightest
export const grayLighter = theme.grayLighter
export const grayLight = theme.grayLight
export const gray = theme.gray
export const grayDark = theme.grayDark
export const grayDarker = theme.grayDarker
export const grayDarkest = theme.grayDarkest
export const black = theme.black

export const red = theme.red

/** FORM */
export const formFieldHeight = theme.formFieldHeight
export const formFieldBg = theme.formFieldBg
export const formFieldColor = theme.formFieldColor
export const formFieldLabelColor = theme.formFieldLabelColor
export const formFieldBorderColor = theme.formFieldBorderColor
export const formFieldPlaceholderColor = theme.formFieldPlaceholderColor
export const formFieldAutocompleteColor = theme.formFieldAutocompleteColor

export const formFieldDisableBg = theme.formFieldDisableBg
export const formFieldDisableColor = theme.formFieldDisableColor
export const formFieldDisableBorderColor = theme.formFieldDisableBorderColor

export const formFieldOutline = theme.formFieldOutline
export const formFieldError = theme.formFieldError
export const formFieldErrorColor = theme.formFieldErrorColor

export const formCheckboxBg = theme.formCheckboxBg
export const formCheckboxCheckedBg = theme.formCheckboxCheckedBg
export const formCheckboxCheckedColor = theme.formCheckboxCheckedColor

/** HEADER */
export const mainHeaderBg = theme.mainHeaderBg
export const mainHeaderHeight = 75
export const mainHeaderHeightMd = 50
export const mainHeaderColor = theme.mainHeaderColor
export const secondaryHeaderHeight = 35
export const secondaryHeaderBg = theme.secondaryHeaderBg
export const secondaryHeaderColor = theme.secondaryHeaderColor

/** CONTENT */
export const cmsContentBg = theme.cmsContentBg
export const cmsContentColor = theme.cmsContentColor
export const cmsContentSidebarBg = theme.cmsContentSidebarBg
export const cmsContentSidebarColor = theme.cmsContentSidebarColor
export const cmsContentSidebarActiveBg = theme.cmsContentSidebarActiveBg
export const cmsContentSidebarActiveColor = theme.cmsContentSidebarActiveColor
export const cmsContentSidebar3DeepBg = theme.cmsContentSidebar3DeepBg
export const cmsContentBorder = theme.cmsContentBorder
export const hrColor = theme.hrColor

/** BOX */
export const boxHeaderBg = theme.boxHeaderBg
export const boxHeaderColor = theme.boxHeaderColor
export const boxSubHeaderBg = theme.boxSubHeaderBg
export const boxSubHeaderColor = theme.boxSubHeaderColor
export const boxContentBg = theme.boxContentBg
export const boxContentColor = theme.boxContentColor
export const boxFooterBg = theme.boxFooterBg
export const boxFooterColor = theme.boxFooterColor
export const attachmentColor = theme.attachmentColor

/** Table */
export const tableHeaderBg = theme.tableHeaderBg
export const tableHeaderColor = theme.tableHeaderColor
export const tableBg = theme.tableBg
export const tableColor = theme.tableColor

/** Alert */
export const alertBoxShadow = theme.alertBoxShadow

/** BTN */
export const btnDefaultBg = theme.btnDefaultBg
export const btnDefaultColor = theme.btnDefaultColor
export const btnDefaultHoverBg = theme.btnDefaultHoverBg
export const btnDefaultHoverColor = theme.btnDefaultHoverColor
export const btnPrimaryBg = theme.btnPrimaryBg
export const btnPrimaryColor = theme.btnPrimaryColor
export const btnPrimaryHoverBg = theme.btnPrimaryHoverBg
export const btnPrimaryHoverColor = theme.btnPrimaryHoverColor
export const btnSecondaryBg = theme.btnSecondaryBg
export const btnSecondaryColor = theme.btnSecondaryColor
export const btnSecondaryHoverBg = theme.btnSecondaryHoverBg
export const btnSecondaryHoverColor = theme.btnSecondaryHoverColor
export const btnDangerBg = theme.btnDangerBg
export const btnDangerColor = theme.btnDangerColor
export const btnDangerHoverBg = theme.btnDangerHoverBg
export const btnDangerHoverColor = theme.btnDangerHoverColor
export const btnDisabledBg = theme.btnDisabledBg
export const btnDisabledColor = theme.btnDisabledColor
export const btnPadding = theme.btnPadding

/** SELECT BOX */
export const selectBoxBg = theme.selectBoxBg
export const selectBoxColor = theme.selectBoxColor
export const selectBoxIconColor = theme.selectBoxIconColor
export const selectBoxActiveBg = theme.selectBoxActiveBg
export const selectBoxActiveColor = theme.selectBoxActiveColor
export const selectBoxActiveIconColor = theme.selectBoxActiveIconColor
export const selectBoxSelectedBg = theme.selectBoxSelectedBg
export const selectBoxSelectedColor = theme.selectBoxSelectedColor
export const selectBoxSelectedIconColor = theme.selectBoxSelectedIconColor
export const selectBoxSelectedBorderColor = theme.selectBoxSelectedBorderColor
export const selectBoxWarningBg = theme.selectBoxWarningBg
export const selectBoxWarningColor = theme.selectBoxWarningColor
export const selectBoxWarningIconColor = theme.selectBoxWarningIconColor

export const loaderColor = theme.loaderColor

/* eslint-enable no-multi-spaces */

export const defaultAnimationProp = 'all'
export const defaultAnimationTime = 0.2
export const defaultAnimationEase = 'cubic-bezier(0.1, 0.6, 0.1, 1)'
export const hardcoreAnimationEase = 'cubic-bezier(0, 1, 0, 1)'
export const animationBouncingEase = 'cubic-bezier(0.39, 1.65, 0.55, 0.99)'
export const defaultAnimationOpacityEase = 'cubic-bezier(0.15, 0.5, 0.75, 1)'

/**
 * Generate CSS transition from props
 * @param {string} prop one or SPACE separated prop to transition
 * @param {number} [time] time of transition
 * @param {string} [ease] transition-timing-function
 * @return {string} css with transition and will-change
 */
export function transition(prop = defaultAnimationProp, time = defaultAnimationTime, ease = defaultAnimationEase) {
  const transitionFn = ease === defaultAnimationEase && prop === 'opacity' ? defaultAnimationOpacityEase : ease
  if (prop.indexOf(' ') > -1) {
    // console.log(prop, time, ease);
    return `
      transition-property: ${prop};
      transition-duration: ${time}s;
      transition-timing-function: ${transitionFn};
      will-change: ${prop};
    `
  }
  return `
    transition: ${prop} ${time}s ${transitionFn};
    will-change: ${prop};
  `
}

export type First = TemplateStringsArray | CSSObject
export type Args = (
  | SimpleInterpolation
  | ((props: any) => string)
  | FlattenInterpolation<ThemedStyledProps<any, any>>
)[]

export const media = {
  /** SMALLER THEN */
  xxs: (first: First, ...args: Args) => css`
    @media (max-width: ${xxs}px) {
      ${css(first, ...args)}
    }
  `,

  xs: (first: First, ...args: Args) => css`
    @media (max-width: ${xs}px) {
      ${css(first, ...args)}
    }
  `,

  xsp: (first: First, ...args: Args) => css`
    @media (max-width: ${xsp}px) {
      ${css(first, ...args)}
    }
  `,

  sm: (first: First, ...args: Args) => css`
    @media (max-width: ${sm}px) {
      ${css(first, ...args)}
    }
  `,

  md: (first: First, ...args: Args) => css`
    @media (max-width: ${md}px) {
      ${css(first, ...args)}
    }
  `,

  lg: (first: First, ...args: Args) => css`
    @media (max-width: ${lg}px) {
      ${css(first, ...args)}
    }
  `,

  xl: (first: First, ...args: Args) => css`
    @media (max-width: ${lowLg}px) {
      ${css(first, ...args)}
    }
  `,

  xxl: (first: First, ...args: Args) => css`
    @media (max-width: ${medLg}px) {
      ${css(first, ...args)}
    }
  `,

  landscape: (first: First, ...args: Args) => css`
    @media (orientation: landscape) {
      ${css(first, ...args)}
    }
  `,

  landscapeMd: (first: First, ...args: Args) => css`
    @media (orientation: landscape) and (max-width: ${md}px) {
      ${css(first, ...args)}
    }
  `,

  /** HEIGHTS */
  heightXs: (first: First, ...args: Args) => css`
    @media (max-height: ${heightXs}px) {
      ${css(first, ...args)}
    }
  `,

  /** DESKTOP HOVER ONLY */
  desktopHoverOnly: (first: First, ...args: Args) => css`
    @media (hover: hover) and (pointer: fine) {
      ${css(first, ...args)}
    }
  `,

  /** TOUCH DEVICE */
  touch: (first: First, ...args: Args) => css`
    .touch & {
      ${css(first, ...args)}
    }
  `,
}
