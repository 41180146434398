import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { transition } from '../../utils/style-utils'
import FullCenteredLoader from './FullCenteredLoader'

const animationTime = 0.2
const delayTime = 0.1

const Animated = styled(FullCenteredLoader)`
  opacity: ${(state) => (state.shown ? '1' : '0')};
  ${transition('opacity', animationTime)}
`

class AnimatedLoader extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    shown: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      shown: props.shown,
      hidden: !props.shown,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-line camelcase
    if (this.props.shown && !nextProps.shown && !this.state.hidden) {
      /** DELAYED HIDING */
      this.to1 = setTimeout(() => {
        this.setState({
          shown: false,
        })
      }, delayTime * 1000)

      this.to2 = setTimeout(
        () => {
          this.setState({
            hidden: true,
          })
        },
        (delayTime + animationTime + 0.1) * 1000,
      )
    } else if (nextProps.shown) {
      this.to1 = this.setState({
        shown: nextProps.shown,
        hidden: !nextProps.shown,
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.to1)
    clearTimeout(this.to2)
  }

  render() {
    const { shown, hidden } = this.state
    return hidden ? null : <Animated shown={shown} />
  }
}

export default AnimatedLoader
