import styled from 'styled-components'

import { white, grayDark } from '../../utils/style-utils'
import Claim from './Claim'

const down = {
  width: 474,
  height: 101,
}

const Down = styled(Claim)`
  width: ${down.width}px;
  height: ${down.height}px;
  top: -32px;
  margin: 0 auto;

  svg {
    fill: ${white};
  }

  span {
    color: ${grayDark};
  }
`

export default Down
