import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'

const PlayerTournaments = lazy(
  () =>
    import(
      /* webpackChunkName: "PlayerTournaments" */ '../../components/Tournaments/PlayerTournaments/PlayerTournaments'
    ),
)

export default function LoadablePlayerTournaments() {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <PlayerTournaments />
    </Suspense>
  )
}
