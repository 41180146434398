import React from 'react'

import { LinkElement } from './LinkElement'
import { linksMap } from './utils'

interface Props {
  onClick: () => void
}

export function Links({ onClick }: Props) {
  return (
    <>
      {linksMap.map((props, index) => (
        <LinkElement
          {...props}
          key={index}
          onClick={onClick}
        />
      ))}
    </>
  )
}
