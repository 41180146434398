import React from 'react'

import Translator from 'bazinga-translator'
import PropTypes from 'prop-types'

import { SHOW_PASSWORD_CHANGE_KEY, PLAYER_CHANGE_PASSWORD } from '../../containers/App/constants'
import { isCookieSet, deleteCookie } from '../../utils/cookies'
import Btn from '../Btn'
import InfoDialog from '../Dialog/InfoDialog'

class FirstLoginDialog extends React.Component {
  static propTypes = {
    userIsLogged: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      show: isCookieSet(SHOW_PASSWORD_CHANGE_KEY) && props.userIsLogged,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newShown = props.userIsLogged && isCookieSet(SHOW_PASSWORD_CHANGE_KEY)
    if (state.show !== newShown) {
      return { show: newShown }
    }

    return null
  }

  confirm() {
    deleteCookie(SHOW_PASSWORD_CHANGE_KEY)
    this.setState({ show: false })
  }

  render() {
    if (!this.state.show) {
      return null
    }

    return (
      <InfoDialog
        zIndex={19}
        title={Translator.trans('Message')}
      >
        {Translator.trans('change_password_after_first_login', {}, 'users')}

        <br />
        <br />

        <Btn
          className="cpafl-change"
          primary
          to={PLAYER_CHANGE_PASSWORD}
          onClick={() => this.confirm()}
        >
          {Translator.trans('change_password', {}, 'buttons')}
        </Btn>

        <Btn
          className="cpafl-leave"
          onClick={() => this.confirm()}
        >
          {Translator.trans('leave_as_is')}
        </Btn>
      </InfoDialog>
    )
  }
}

export default FirstLoginDialog
