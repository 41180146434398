import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'

import PropTypes from 'prop-types'

import Auth from '../../containers/Auth'
import Container from '../Container'
import MenuButton from '../MenuButton'
import UserLogged from '../UserHeader/UserLogged'
import UserUnlogged from '../UserHeader/UserUnlogged'
import Logo from './Logo'
import Menu from './Menu'
import Nav from './Nav'

function MainHeader({ menu, toggleMobileMenu, secondaryMenuOpen, toggleUserMenu, userMenuOpen }) {
  return (
    <Nav>
      <Container>
        <MenuButton
          clickHandler={toggleMobileMenu}
          open={secondaryMenuOpen}
        />
        <Logo />
        {menu && <Menu menu={menu} />}

        <Auth
          renderLoggedIn={(user, reloading) => (
            <UserLogged
              user={user}
              toggleUserMenu={toggleUserMenu}
              userMenuOpen={userMenuOpen}
              reloading={reloading}
            />
          )}
          renderLoggedOut={(user, reloading) => <UserUnlogged reloading={reloading} />}
        />
      </Container>
    </Nav>
  )
}

MainHeader.propTypes = {
  menu: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  toggleMobileMenu: PropTypes.func,
  toggleUserMenu: PropTypes.func,
  secondaryMenuOpen: PropTypes.bool,
  userMenuOpen: PropTypes.bool,
}

export default MainHeader
