import styled from 'styled-components'

import { media } from '../../utils/style-utils'

export default styled.div`
  position: relative;
  height: 100%;

  ${media.md`
    order: 1;
  `}
`
