import { COOKIE_LAW_CONFIRM_KEY } from '../../containers/App/constants'
import { setCookie, getCookie } from '../../utils/cookies'

export interface Consents {
  statistical: boolean
  functional: boolean
  marketing: boolean
  unclassified: boolean
}

export function setConsentsCookie(props: Consents): void {
  const cookieValue = encodeConsentsCookie(props)

  setCookie(COOKIE_LAW_CONFIRM_KEY, cookieValue)
}

export function getConsentsCookie(): Consents {
  const cookieValue = getCookie(COOKIE_LAW_CONFIRM_KEY) || ''

  return decodeConsentsCookie(cookieValue)
}

function encodeConsentsCookie({ statistical, functional, marketing, unclassified }: Consents): string {
  const valuesMap = [statistical, functional, marketing, unclassified].map((value) => (value ? 1 : 0))

  return valuesMap.join('')
}

function decodeConsentsCookie(cookieValue: string): Consents {
  const [statistical, functional, marketing, unclassified] = cookieValue.split('').map((value) => value === '1')

  return {
    statistical: statistical || false,
    functional: functional || false,
    marketing: marketing || false,
    unclassified: unclassified || false,
  }
}
