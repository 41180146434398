import { PlaceDTO } from '../../../../../../containers/Autocompletes/PlaceAutocomplete/types'
import { useChangeFormValue } from '../../../../../../containers/RegisterPage/utils'
import { CITY_TEXT_FIELD, getExternalRegistrationFieldName, STREET_TEXT_FIELD, ZIP_FIELD } from '../utils'

export function useSetTextValuesOnPlaceSelection(): (entity: PlaceDTO) => void {
  const setTownTextValue = useChangeFormValue(getExternalRegistrationFieldName(CITY_TEXT_FIELD))
  const setStreetTextValue = useChangeFormValue(getExternalRegistrationFieldName(STREET_TEXT_FIELD))
  const setZipTextValue = useChangeFormValue(getExternalRegistrationFieldName(ZIP_FIELD))

  return (entity: PlaceDTO) => {
    setTownTextValue(entity.town)
    setStreetTextValue(entity.street)
    setZipTextValue(entity.zip)
  }
}
