import React from 'react'

import { Logo } from './Logo'
import { User } from './User'
import { TopAppBarBox } from './styles'

export function TopAppBar() {
  return (
    <TopAppBarBox>
      <Logo />
      <User />
    </TopAppBarBox>
  )
}
