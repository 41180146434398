import styled from 'styled-components'

import { bh, gray, white } from '../../utils/style-utils'

const Btn = styled.div`
  display: block;
  padding: ${bh * 1.5}px 12px;

  &:first-child {
    padding-left: ${bh * 1.5}px;
  }

  &:last-child {
    padding-right: 20px;
  }

  svg {
    display: block;
    fill: ${gray};
  }

  &:hover svg {
    fill: ${white};
  }
`

export default Btn
