import styled from 'styled-components'
import { mainHeaderColor } from 'utils/style-utils'

export const ProfileBox = styled.div`
  position: relative;
  z-index: 10;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;

  font-size: 0.875rem;
  color: ${mainHeaderColor};
  font-weight: 800;

  cursor: pointer;
  user-select: none;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${mainHeaderColor};
  }
`
