import * as React from 'react'

import { IconProps } from '../types'

function Warning(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.862 5.438a1 1 0 0 0-1.724 0L4.635 16.493A1 1 0 0 0 5.497 18h13.006a1 1 0 0 0 .862-1.507L12.862 5.438ZM9.414 4.424c1.16-1.972 4.012-1.972 5.172 0l6.503 11.055c1.176 2-.266 4.521-2.586 4.521H5.497c-2.32 0-3.762-2.521-2.586-4.521L9.414 4.424ZM11 13V7h2v6h-2Zm0 2v1.5h2V15h-2Z"
      />
    </svg>
  )
}
export default Warning
