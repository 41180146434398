/*
 *
 * MainLayout actions
 *
 */
import { LOGIN_FORM_LOAD, LOGIN_FORM_LOAD_SUCCESS, LOGIN_FORM_LOAD_ERROR, SEND_LOGIN_FORM } from './constants'

export function loginFormLoad() {
  return {
    type: LOGIN_FORM_LOAD,
  }
}

/**
 * Dispatched when the login form is loaded by the request saga
 * *
 * @return {object}      An action object with a type of LOGIN_FORM_LOAD_SUCCESS passing the repos
 */
export function loginFormLoaded(data, flashMessages) {
  return {
    type: LOGIN_FORM_LOAD_SUCCESS,
    data,
    flashMessages,
  }
}

/**
 * Dispatched when loading the login form isls
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOGIN_FORM_LOAD_SUCCESS passing the error
 */
export function loginFormLoadingError(error) {
  return {
    type: LOGIN_FORM_LOAD_ERROR,
    error,
  }
}

export function sendLoginForm(values, resolve, reject) {
  return {
    type: SEND_LOGIN_FORM,
    resolve,
    reject,
    values,
  }
}
