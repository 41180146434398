import { request } from '../../../utils/request'
import { getBaseRUIANURL } from '../utils'
import { PlaceSuggestionsQueryParams } from './types'
import { placeArrayValidator } from './validator'

export async function getPlaceSuggestions(query: string, { id, type }: PlaceSuggestionsQueryParams) {
  const { data } = await request(`${getBaseRUIANURL()}/${type}/${id}?q=${query}`)

  const validatedData = await placeArrayValidator.validate(data)

  return validatedData.map((place) => ({
    id: place.id,
    label: place.name,
    entity: place,
  }))
}
