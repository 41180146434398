import React from 'react'

import { Hr, StyledLink } from './styles'
import { Link } from './types'

type Props = Link & {
  onClick: () => void
}

export function LinkElement({ label, onClick, link, Icon, url, splitAfter }: Props) {
  return (
    <>
      <StyledLink
        onClick={onClick}
        link={link}
        url={url}
      >
        <Icon />
        <span>{label}</span>
      </StyledLink>

      {splitAfter && <Hr />}
    </>
  )
}
