import { useCallback, useState } from 'react'

import { useClickOutside } from './useClickOutside'

export function usePopover() {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(
    () => {
      closePopover()
    },
    () => isOpen,
  )

  const togglePopover = useCallback(() => {
    setIsOpen((prevOpen) => !prevOpen)
  }, [])

  const closePopover = useCallback(() => {
    setIsOpen(false)
  }, [])

  return { ref, isOpen, togglePopover, closePopover }
}
