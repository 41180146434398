/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import {
  CONTENT_LOAD,
  CONTENT_LOAD_SUCCESS,
  CONTENT_LOAD_ERROR,
  TRANSFER_DATA_KEY,
  CONFIRMATION_FORM_KEY,
} from './constants'

export const initialState = fromJS({
  loading: undefined,
  error: undefined,
  [TRANSFER_DATA_KEY]: undefined,
  [CONFIRMATION_FORM_KEY]: undefined,
})

function appReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set(TRANSFER_DATA_KEY, initialState.get(TRANSFER_DATA_KEY))
        .set(CONFIRMATION_FORM_KEY, initialState.get(TRANSFER_DATA_KEY))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set(TRANSFER_DATA_KEY, fromJS(action.data[TRANSFER_DATA_KEY]))
        .set(CONFIRMATION_FORM_KEY, fromJS(action.data[CONFIRMATION_FORM_KEY]))

    case CONTENT_LOAD_ERROR:
      return state.set('error', fromJS(action.error)).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}

export default appReducer
