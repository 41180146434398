import styled from 'styled-components'

import { media, bh, grayDarkest } from '../../utils/style-utils'
import X from '../Icons/X'

const StyledX = styled(X)`
  display: block;
  width: ${bh}px;
  height: ${bh}px;
  margin-left: ${bh}px;
  fill: ${grayDarkest};
  pointer-events: none;
`

const XWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${-bh * 0.75}px;

  width: ${bh * 3}px;
  height: ${bh * 3}px;
  cursor: pointer;

  ${media.xs`
    width: ${bh * 3}px;
    height: ${bh * 3}px;
  `}
`

const TextWrapper = styled.div`
  margin-right: ${bh}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export { StyledX, XWrapper, TextWrapper }
