import { FlashMessage } from 'components/FlashMessages/types'
import { LOCATION_CHANGE } from 'redux-first-history'
import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects'
import { Immutable } from 'types/Immutable'
import { User } from 'types/User'
import { GlobalStore } from 'types/store/GlobalState'

import { isGameCategoryPath } from '../../routes/isGameCategoryPath'
import { setServerClientTimeDiff } from '../../utils/dates'
import { request, defaultOptions } from '../../utils/request'
import { hrefToTo } from '../../utils/url'
import { makeSelectCurrentRoute, makeSelectPreviousRoute } from '../App/selectors'
import { LOGIN_ADDRESS } from '../LoginPage/constants'
import { gameFinishSuccess, gameFinishError, SendGameFinishAction } from '../MainHeaderBox/actions'
import {
  initLoaded,
  initLoadingError,
  initLoadedRedirect,
  initReloadNeeded,
  initLoad as initLoadAction,
  addFlashMessage,
  InitLoadAction,
  InitLoadedAction,
} from './actions'
import {
  INIT_LOAD_URI,
  INIT_LOAD,
  SEND_GAME_FINISH,
  INIT_RESET,
  INIT_RELOAD_NEEDED,
  INIT_LOAD_SUCCESS,
} from './constants'
import { makeSelectIsLogged, makeSelectReInitialize, makeSelectUser } from './selectors'

let cachedData: Record<string, any> = {}

function* loggedChange(newData: GlobalStore) {
  // checking if previews user data isLogged is different then new one
  // and if is not first time we check (isUserEmpty)
  // then removing cache and force to reload init data
  const user = (yield select(makeSelectUser())) as Immutable<User>
  const isLoggedBefore = !!user.get('isLogged')
  const isLoggedAfter = newData && !!newData.user.isLogged

  if (isLoggedBefore !== isLoggedAfter) {
    clearCache()
    yield put(initReloadNeeded())
  }
}

function* putLoaded(data: GlobalStore, flashMessages?: FlashMessage[]) {
  yield loggedChange(data)
  yield put(initLoaded(data, flashMessages))
}

interface InitResponse {
  data: GlobalStore
  flashMessages?: FlashMessage[]
  type: string
}

function* initLoad(action: InitLoadAction) {
  const referer = action.pathname
  const isUserLogged = (yield select(makeSelectIsLogged())) as boolean
  const force = action.force || (referer || '').indexOf(LOGIN_ADDRESS) > -1
  if (!force && referer && !isUserLogged && cachedData[referer]) {
    yield putLoaded(cachedData[referer])
  } else {
    const requestURL = `${INIT_LOAD_URI}?t=${Date.now()}`
    let response: InitResponse

    try {
      response = yield call(request, requestURL, {
        headers: { ...defaultOptions.headers, 'X-Referer': window.location.pathname },
      })

      if (response.type === 'redirect') {
        return
      }

      if (!response) {
        yield put(initLoadedRedirect())
        return
      }

      const { data, flashMessages } = response as { data: GlobalStore; flashMessages?: FlashMessage[] }
      if (data) {
        if (!isUserLogged && referer) {
          cachedData[referer] = { ...data, announcements: [] }
        }

        yield putLoaded(data, flashMessages)
      }
    } catch (err) {
      yield put(initLoadingError(err as any))
    }
  }
}

function* initData() {
  yield takeLatest(INIT_LOAD, initLoad)
}

function* locationChangeWatch() {
  yield takeLatest(LOCATION_CHANGE, locationChangeInit)
}

function* reloadNeededWatch() {
  yield takeLatest(INIT_RELOAD_NEEDED, reloadNeededInit)
}

function* reinitializeNeededWatch() {
  yield takeLatest(INIT_LOAD_SUCCESS, reinitializedInit)
}

function* reinitializedInit() {
  const isReinitializeNeeded = (yield select(makeSelectReInitialize())) as ReturnType<
    ReturnType<typeof makeSelectReInitialize>
  >

  if (isReinitializeNeeded) {
    yield delay(200)
    yield put(initLoadAction(hrefToTo(window.location.pathname), true))
  }
}

function* reloadNeededInit() {
  yield put(initLoadAction(hrefToTo(window.location.pathname), true))
}

type RouteLocation = {
  location?: {
    pathname: string
    search: string
    hash: string
  }
}

function* locationChangeInit() {
  const currentRouteObject = (yield select(makeSelectCurrentRoute())) as RouteLocation
  const previousRouteObject = (yield select(makeSelectPreviousRoute())) as RouteLocation
  const currentPathname = currentRouteObject?.location?.pathname
  const previousPathname = previousRouteObject?.location?.pathname
  const isPathnameChanged = currentPathname !== previousPathname
  const isPathnameDeeplyChanged = previousPathname !== null || currentPathname !== previousPathname
  const isHomeOrCategoryRouteChange = isGameCategoryPath(currentPathname) && isGameCategoryPath(previousPathname)

  if (!isHomeOrCategoryRouteChange && isPathnameChanged && isPathnameDeeplyChanged) {
    yield put(initLoadAction(hrefToTo(window.location.pathname)))
  }
}

function clearCache() {
  cachedData = {}
}

function* clearData() {
  yield takeLatest(INIT_RESET, clearCache)
}

function* gameFinishSend(action: SendGameFinishAction) {
  const requestURL = action.url
  try {
    const data = (yield call(request, requestURL)) as { data: string }
    yield put(gameFinishSuccess(data.data))
    yield put(initReloadNeeded())
    yield put(addFlashMessage(data.data, 'success'))
  } catch (err) {
    yield put(gameFinishError(err as any))
    yield put(addFlashMessage((err as any).data, 'error'))
  }
}

function* gameFinishData() {
  yield takeLatest(SEND_GAME_FINISH, gameFinishSend)
}

function setServerTimeDiff(action: InitLoadedAction) {
  setServerClientTimeDiff(action.data.serverDate)
}

function* initializeServerTime() {
  yield takeLatest(INIT_LOAD_SUCCESS, setServerTimeDiff)
}

export default function* rootSaga() {
  yield all([
    initData(),
    locationChangeWatch(),
    reloadNeededWatch(),
    reinitializeNeededWatch(),
    initializeServerTime(),
    clearData(),
    gameFinishData(),
  ])
}
