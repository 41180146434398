import styled from 'styled-components'

import { transition, brandPrimary, lighten } from '../../utils/style-utils'
import Claim from './Claim'

export const up = {
  width: 635,
  height: 88,
}

const Up = styled(Claim)`
  width: ${up.width}px;
  height: ${up.height}px;
  z-index: 1;
  font-size: 48px;

  svg {
    fill: ${brandPrimary};
  }

  ${(p) =>
    p.interactive
      ? `
    svg {
      ${transition('fill')}
    }
    &:hover {
      svg {
        fill: ${lighten(brandPrimary, 0.3)};
      }
    }
  `
      : ''}

  ${(p) =>
    p.len > 22
      ? `
    font-size: 40px;
  `
      : ''}

  span {
    top: -3px;
    left: -20px;
  }
`

export default Up
