/* eslint-disable default-param-last */
import { fromJS } from 'immutable'

import { RESET_PAGE_STATE } from '../MainLayout/constants'
import { CONTENT_LOAD, CONTENT_LOAD_SUCCESS, CONTENT_LOAD_ERROR } from './constants'

export const initialState = fromJS({
  loading: undefined,
  error: false,
  loginWithCollect: false,
  loginWithBankIdCz: false,
  finishVltRegistration: false,
  onlineRegistration: false,
  landbaseRegistration: false,
  neteraRegistration: false,
  identityDocumentRegistration: false,
  loginWithCollectDescription: '',
  loginWithBankIdCzDescription: '',
  finishVltRegistrationDescription: '',
  onlineRegistrationDescription: '',
  landbaseRegistrationDescription: '',
  neteraRegistrationDescription: '',
  identityDocumentRegistrationDescription: '',
})

export default function ResetPasswordRequestReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('loginWithCollect', initialState.get('loginWithCollect'))
        .set('loginWithBankIdCz', initialState.get('loginWithBankIdCz'))
        .set('finishVltRegistration', initialState.get('finishVltRegistration'))
        .set('onlineRegistration', initialState.get('onlineRegistration'))
        .set('landbaseRegistration', initialState.get('landbaseRegistration'))
        .set('neteraRegistration', initialState.get('neteraRegistration'))
        .set('loginWithCollectDescription', initialState.get('loginWithCollectDescription'))
        .set('loginWithBankIdCzDescription', initialState.get('loginWithBankIdCzDescription'))
        .set('finishVltRegistrationDescription', initialState.get('finishVltRegistrationDescription'))
        .set('onlineRegistrationDescription', initialState.get('onlineRegistrationDescription'))
        .set('landbaseRegistrationDescription', initialState.get('landbaseRegistrationDescription'))
        .set('neteraRegistrationDescription', initialState.get('neteraRegistrationDescription'))

    case CONTENT_LOAD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('loginWithCollect', action.data.loginWithCollect)
        .set('loginWithBankIdCz', action.data.loginWithBankIdCz)
        .set('finishVltRegistration', action.data.finishVltRegistration)
        .set('onlineRegistration', action.data.onlineRegistration)
        .set('landbaseRegistration', action.data.landbaseRegistration)
        .set('neteraRegistration', action.data.neteraRegistration)
        .set('identityDocumentRegistration', action.data.identityDocumentRegistration)
        .set('loginWithCollectDescription', action.data.loginWithCollectDescription)
        .set('loginWithBankIdCzDescription', action.data.loginWithBankIdCzDescription)
        .set('finishVltRegistrationDescription', action.data.finishVltRegistrationDescription)
        .set('onlineRegistrationDescription', action.data.onlineRegistrationDescription)
        .set('landbaseRegistrationDescription', action.data.landbaseRegistrationDescription)
        .set('neteraRegistrationDescription', action.data.neteraRegistrationDescription)
        .set('identityDocumentRegistrationDescription', action.data.identityDocumentRegistrationDescription)
    case CONTENT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false)

    case RESET_PAGE_STATE:
      return initialState

    default:
      return state
  }
}
