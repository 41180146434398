import React from 'react'

export default function CacheBag(props) {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 23"
      {...props}
    >
      <path
        id="promo"
        d="M1141.98,34.891c-0.02-.067-1.68-6.716-1.91-8.931a8.68,8.68,0,0,0-4.92-7.176l1.06-2.429V16.186A2.208,2.208,0,0,0,1133.99,14h-4.21a2.211,2.211,0,0,0-2.23,2.186v0.169l1.11,2.517a8.711,8.711,0,0,0-4.73,7.088c-0.24,2.215-1.89,8.865-1.91,8.931a0.82,0.82,0,0,0,.6.988A40.975,40.975,0,0,0,1132,37a41.872,41.872,0,0,0,9.38-1.12A0.819,0.819,0,0,0,1141.98,34.891Zm-12.2-19.251h4.21a0.556,0.556,0,0,1,.54.414l-0.98,2.218a7.783,7.783,0,0,0-1.55-.155,7.7,7.7,0,0,0-1.76.2l-1-2.265A0.556,0.556,0,0,1,1129.78,15.64ZM1132,35.36a40.791,40.791,0,0,1-8.16-.883c0.46-1.874,1.55-6.477,1.75-8.348a6.876,6.876,0,0,1,4.37-6.023l-1.57,1.9,1.3,1.032,2.22-2.693,2.22,2.693,1.3-1.032-1.63-1.987a6.843,6.843,0,0,1,4.61,6.109c0.2,1.87,1.29,6.47,1.75,8.346A41.246,41.246,0,0,1,1132,35.36Zm0.13-8.03c-0.81-.094-1.43-0.2-1.43-0.684,0-.667.96-0.739,1.37-0.739a1.9,1.9,0,0,1,1.49.642l0.06,0.105,1.27-.576-0.06-.125a2.516,2.516,0,0,0-1.97-1.332V23.789h-1.48v0.828c-1.38.2-2.2,0.953-2.2,2.03,0,1.756,1.62,1.935,2.81,2.064,1.05,0.123,1.55.379,1.55,0.806,0,0.816-1.15.879-1.51,0.879a1.95,1.95,0,0,1-1.76-.895l-0.05-.126-1.38.573,0.06,0.126a2.88,2.88,0,0,0,2.48,1.663v0.9h1.49V31.691a2.27,2.27,0,0,0,2.25-2.174C1135.11,27.693,1133.39,27.483,1132.13,27.33Z"
        transform="translate(-1122 -14)"
      />
    </svg>
  )
}
