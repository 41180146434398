import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  bh,
  hexRgba,
  formFieldColor,
  formFieldPlaceholderColor,
  formFieldDisableColor,
  formCheckboxBg,
  formCheckboxCheckedBg,
  formCheckboxCheckedColor,
  formFieldErrorColor,
} from '../../utils/style-utils'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
`

const symbolSize = bh * 1.5

const Input = styled.input`
  /* HIDE INPUT */
  display: none;
  ~ a,
  + label a {
    font-style: italic;
    color: ${formFieldColor};
    border-bottom: 1px dotted ${formFieldPlaceholderColor};
  }
  + label {
    /* INPUT FAKER STANDARD STYLES */
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: ${bh / 3}px;
    color: ${formFieldColor};
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      height: ${symbolSize}px;
      width: ${symbolSize}px;
      min-width: ${symbolSize}px;
      margin-right: 12px;
      background: transparent;
      border: 1px solid ${formCheckboxBg};
      border-radius: ${(p) => (p.type === 'radio' ? `${bh}px` : 0)};
    }

    &:hover {
      &:before {
        background-color: ${hexRgba(formCheckboxBg, 0.3)};
      }
    }
  }

  &:checked {
    + label {
      color: ${formCheckboxCheckedColor};
      /* CHECKED STYLES */
      &:after {
        content: '';
        opacity: 1;
        position: absolute;
        width: ${symbolSize - 6}px;
        height: ${symbolSize - 6}px;
        max-height: ${symbolSize - 6}px;
        background: ${formCheckboxCheckedBg};
        border-radius: ${(p) => (p.type === 'radio' ? `${bh}px` : 0)};
        left: 4px;
      }
    }
  }

  ${(p) =>
    p.disabled
      ? `
    & + label,
    &:checked + label {
      cursor: not-allowed;
      pointer-events: none;
      color: ${formFieldDisableColor};
      &:before {
        border-color: ${formFieldDisableColor};
      }
      &:after {
        background: ${formFieldDisableColor};
      }
    }
  `
      : ''}

  ${(p) =>
    p.error
      ? `
    & + label,
    &:checked + label {
      color: ${formFieldErrorColor};
      &:before {
        border-color: ${formFieldErrorColor}
      }
      &:after {
        background: ${formFieldErrorColor};
      }
    }
  `
      : ''}
`

function Checkbox(props) {
  const { type, label, id, inLabelComponent, inline, disabled, isFieldRequired, style } = props
  const wrapperStyle = inline ? { display: 'inline-block' } : null

  return (
    <Wrapper style={style || wrapperStyle}>
      <Input
        type={type}
        disabled={disabled}
        {...props}
      />
      <label htmlFor={id}>
        <span>
          {label} {inLabelComponent} {isFieldRequired && <b>*</b>}
        </span>
      </label>
    </Wrapper>
  )
}

Checkbox.propTypes = {
  inLabelComponent: PropTypes.object,
  style: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.any,
  isFieldRequired: PropTypes.bool,
  inline: PropTypes.bool,
}

Checkbox.defaultProps = {
  type: 'checkbox',
}

export default Checkbox
