import React, { Suspense } from 'react'

import MidCenteredLoader from '../Loader/MidCenteredLoader'

export default function withLazyLoad(Component, Loader = MidCenteredLoader) {
  return function WithLazyLoad(props) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
