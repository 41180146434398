import styled from 'styled-components'

import { transition, fontWeightBold } from '../../utils/style-utils'

const Claim = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 40px;
  font-weight: ${fontWeightBold};
  text-align: center;
  text-transform: uppercase;
  transform: scale(0.9);

  ${(p) =>
    p.interactive
      ? `
    cursor: pointer;
    ${transition('transform')}

    &:hover {
      transform: scale(1);
    }
  `
      : ''}

  ${(p) =>
    p.len > 22
      ? `
    font-size: 34px;
  `
      : ''}

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    display: block;
    position: relative;
    z-index: 2;
  }
`

export default Claim
