import { useCallback } from 'react'

import { QueryValue } from '../../routing/routeReducerWithQuery'
import useMutateURLSearchParams from './useMutateURLSearchParams'

export default function useChangeQueryParameter<TParameter extends QueryValue = QueryValue>(
  key: string,
): (newValue?: TParameter) => void {
  const mutateURLParams = useMutateURLSearchParams()
  const onChange = useCallback((newValue?: TParameter) => mutateURLParams({ [key]: newValue }), [key])

  return onChange
}
