import * as React from 'react'

import { IconProps } from '../types'

function Home(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m4 11.044-.856.721-1.288-1.53 8.212-6.915a3 3 0 0 1 3.864 0l8.212 6.915-1.288 1.53-.856-.72V19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-7.956Zm7.35-6.169a1 1 0 0 1 1.3 0l5 4.286a1 1 0 0 1 .35.759V19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9.92a1 1 0 0 1 .35-.76l5-4.285Z"
      />
    </svg>
  )
}
export default Home
