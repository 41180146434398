import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { HOME_ADDRESS, THEME } from '../../containers/App/constants'
import { bh, media, logo, logoHeight, mainHeaderHeight, mainHeaderHeightMd } from '../../utils/style-utils'

const StyledLink = styled(Link)`
  width: 200px;
  height: ${mainHeaderHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.md`
    max-width: unset;
    flex: 1;
    padding: 0;
    padding-left: ${bh}px;
    height: ${mainHeaderHeightMd}px;
    width: auto;
    justify-content: left;
  `}
`

const Img = styled.img`
  height: ${logoHeight};
`

function Logo({ noLink }) {
  const to = noLink ? null : HOME_ADDRESS

  return (
    <StyledLink to={to}>
      <Img
        src={logo}
        alt={THEME}
      />
    </StyledLink>
  )
}

Logo.propTypes = {
  noLink: PropTypes.bool,
}

export default Logo
