import React from 'react'

import { DashboardButton } from 'components/UserHeader/DashboardButton'

import { Links } from '../styles'

export function Staff() {
  return (
    <Links>
      <DashboardButton
        link
        outlined
        md
      />
    </Links>
  )
}
