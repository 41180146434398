import {
  TOGGLE_MOBILE_MENU,
  TOGGLE_LANG_MENU,
  TOGGLE_GENERIC_MENU_SECTION,
  TOGGLE_GENERIC_MENU_POSITION,
} from '../MainLayout/constants'

export function toggleMobileMenu(open?: boolean) {
  return {
    type: TOGGLE_MOBILE_MENU,
    open,
  }
}

export function toggleLangMenu() {
  return {
    type: TOGGLE_LANG_MENU,
  }
}

type MenuType = 'mobile' | 'desktop'

export function toggleGenericMenuPosition(id: number, menuType: MenuType) {
  return {
    type: TOGGLE_GENERIC_MENU_POSITION,
    id,
    menuType,
  }
}

export function toggleGenericMenuSection(id: number, menuType: MenuType) {
  return {
    type: TOGGLE_GENERIC_MENU_SECTION,
    id,
    menuType,
  }
}
