import React from 'react'

export default function CacheBag(props) {
  return (
    <svg
      width="33"
      height="25"
      viewBox="0 0 33 20"
      {...props}
    >
      <path
        id="football"
        d="M823.343,18H791.657a0.657,0.657,0,0,0-.657.657V37.343a0.657,0.657,0,0,0,.657.657h31.686A0.657,0.657,0,0,0,824,37.343V18.657A0.657,0.657,0,0,0,823.343,18Zm-15.186,7.66a2.429,2.429,0,0,1,0,4.679V25.661Zm-15.842-.833h2.8v6.345h-2.8V24.827Zm0,7.659h3.454a0.657,0.657,0,0,0,.657-0.657V24.171a0.657,0.657,0,0,0-.657-0.657h-3.454V22.5h5.193V33.5h-5.193V32.486Zm30.37-1.314h-2.8V24.827h2.8v6.345Zm0-7.659h-3.454a0.657,0.657,0,0,0-.657.657V31.83a0.657,0.657,0,0,0,.657.657h3.454V33.5h-5.193V22.5h5.193v1.017Zm0-2.331h-5.85a0.658,0.658,0,0,0-.658.657V34.161a0.658,0.658,0,0,0,.658.657h5.85v1.868h-30.37V34.818h5.85a0.658,0.658,0,0,0,.658-0.657V21.839a0.658,0.658,0,0,0-.658-0.657h-5.85V19.314h14.528v5a3.743,3.743,0,0,0,0,7.371v1.438a0.657,0.657,0,0,0,1.314,0V31.686a3.743,3.743,0,0,0,0-7.371v-5h14.528v1.869h0Zm-15.842,4.478V30.34A2.429,2.429,0,0,1,806.843,25.661Zm0.657,8.777a0.657,0.657,0,0,0-.657.657v0.131a0.657,0.657,0,0,0,1.314,0V35.095A0.657,0.657,0,0,0,807.5,34.438Z"
        transform="translate(-791 -18)"
      />
    </svg>
  )
}
