import React, { lazy, Suspense } from 'react'

import { fromJS } from 'immutable'

import CenteredLoader from '../../components/Loader/CenteredLoader'
import { useInjectReducer } from '../../utils/routing/injectReducer'
import { TRANSACTION_HISTORY_PAGE_STATE, LIMIT_HISTORY_TYPES } from '../TransactionHistoryPage/constants'
import reducer from '../TransactionHistoryPage/reducer'

const key = TRANSACTION_HISTORY_PAGE_STATE
const Component = lazy(
  () => import(/* webpackChunkName: "ProfileVerification/TransactionHistoryPage" */ '../TransactionHistoryPage'),
)

export default function Loadable() {
  const { isLoading } = useInjectReducer({ key, reducer })

  if (isLoading) {
    return <CenteredLoader />
  }

  return (
    <Suspense fallback={<CenteredLoader />}>
      <Component allowedTypes={fromJS(LIMIT_HISTORY_TYPES)} />
    </Suspense>
  )
}
