import React from 'react'

import Checkbox from '../Form/Checkbox'
import CheckboxWrapper from '../Form/Wrapper'

interface Props {
  description: string
  label: string
  name: string
  value: boolean
  disabled?: boolean
  onChange?: () => void
}

export function CookieOption({ label, description, name, value, onChange, disabled }: Props) {
  return (
    <>
      <CheckboxWrapper>
        <Checkbox
          label={label}
          id={name}
          checked={value}
          disabled={disabled}
          name={'fieldId'}
          onChange={onChange}
        />
      </CheckboxWrapper>

      <p>{description}</p>
    </>
  )
}
