import styled from 'styled-components'

import { cmsContentColor } from '../../utils/style-utils'

export default styled.div`
  color: ${cmsContentColor};

  a {
    color: ${cmsContentColor};
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    :first-child {
      margin-top: 0;
    }
  }

  img {
    max-width: 100%;
  }
`
