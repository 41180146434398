import { getTournaments } from '../../../containers/Tournaments/service'
import { tournamentsListSchema } from '../../../containers/Tournaments/validator'
import { useQueryWithValidator } from '../../useQueryWithValidator'
import { CACHE_TIME_NEAREST_TOURNAMENTS } from './constants'
import { getTournamentsCacheKey } from './utils'

export function useNearestTournaments() {
  return useQueryWithValidator({
    cacheKey: getTournamentsCacheKey(),
    queryFn: getTournaments,
    schema: tournamentsListSchema,
    options: {
      gcTime: CACHE_TIME_NEAREST_TOURNAMENTS,
      staleTime: CACHE_TIME_NEAREST_TOURNAMENTS,
    },
  })
}
