import styled from 'styled-components'

import { mainHeaderHeightMd, secondaryHeaderBg } from '../../utils/style-utils'

const StyledDiv = styled.div`
  display: block;
  height: ${mainHeaderHeightMd}px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${secondaryHeaderBg};
`

export default StyledDiv
