import React from 'react'

import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { media, bh, black, alertBoxShadow, fontWeightMedium, fontWeightLight } from '../../utils/style-utils'
import CheckRound from '../Icons/components/Check'
import InfoRound from '../Icons/components/Info'
import WarningSign from '../Icons/components/Warning'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const Text = styled.div`
  flex: 1;
  display: flex;
  & > *:first-child {
    flex: 1;
  }
`

const StyledAlert = styled.div`
  display: ${(p) => (p.noFlex ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: center;
  padding: ${bh}px ${bh * 1.5}px;
  margin-bottom: ${bh}px;

  color: ${black};
  font-weight: ${fontWeightMedium};
  font-size: ${bh * 1.25}px;

  animation: 0.3s ${fadeIn} cubic-bezier(0.26, 1.43, 0.65, 1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${black};
  }

  .alert-icon {
    width: ${bh * 2}px;
    margin-right: ${bh}px;
  }

  ${(p) =>
    !p.noShadow
      ? `
    box-shadow: ${alertBoxShadow};
  `
      : ''}
  ${(p) =>
    p.noMargin
      ? `
    margin: 0;
  `
      : ''}

  ${(p) =>
    p.sm
      ? `
    font-size: ${bh}px;
    font-weight: ${fontWeightLight};
    padding: ${bh * 0.75}px;
    .alert-icon {
      width: ${bh * 1.5}px;
      height: ${bh * 1.5}px;
    }
  `
      : ''}

  ${(p) =>
    p.xs
      ? `
    font-size: ${bh * 0.75}px;
    padding: ${bh / 2}px ${bh / 3}px;
    margin-bottom: ${bh / 2}px;

    .alert-icon {
      width: ${bh}px;
      height: ${bh}px;
      margin-right: ${bh / 2}px;
    }
  `
      : ''}

  ${media.sm`
    font-size: ${bh}px;
  `}

  ${media.xs`
    font-size: ${bh * 0.875}px;
    font-weight: ${fontWeightMedium};
    .alert-icon {
      display: none;
    }
  `}

  ${(p) =>
    p.transparent
      ? `
    background: transparent !important;
  `
      : ''}
`

function Alert({ children, alertType, className, ...rest }) {
  let Icon = null
  switch (alertType) {
    case 'success':
      Icon = CheckRound
      break
    case 'info':
      Icon = InfoRound
      break
    case 'warning':
      Icon = WarningSign
      break
    case 'danger':
      Icon = InfoRound
      break
    case 'permanent':
      Icon = InfoRound
      break

    default:
      break
  }

  return (
    <StyledAlert
      className={`alert-${alertType} ${className}`}
      {...rest}
    >
      {Icon && <Icon className="alert-icon" />}
      <Text>{children}</Text>
    </StyledAlert>
  )
}

Alert.propTypes = {
  alertType: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  children: PropTypes.any,
  transparent: PropTypes.bool,
}

export default Alert
