import { request } from '../../../utils/request'
import { getBaseRUIANURL } from '../utils'
import { StreetSuggestionsQueryParams } from './types'
import { streetArrayValidator } from './validator'

export async function getStreetSuggestions(query: string, { id, type }: StreetSuggestionsQueryParams) {
  const { data } = await request(`${getBaseRUIANURL()}/${type}/${id}?q=${query}`)

  const validatedData = await streetArrayValidator.validate(data)

  return validatedData.map((street) => ({
    id: street.id,
    label: street.name,
    entity: street,
  }))
}
