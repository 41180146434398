import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getFullUrl } from '../../utils/request'
import { isRedirectNeeded } from '../../utils/url'

function UrlLink(props) {
  const { url, link, children, activeClassName, ...linkProps } = props
  const to = getTo(link)
  const href = to ? undefined : link || url

  if (to) {
    return (
      <NavLink
        activeClassName={activeClassName}
        to={to}
        exact
        {...linkProps}
      >
        {children}
      </NavLink>
    )
  }

  if (href) {
    return (
      <a
        href={getFullUrl(href)}
        {...linkProps}
      >
        {children}
      </a>
    )
  }

  return <a {...linkProps}>{children}</a>
}

function getTo(to) {
  if (to && !startsWithHttp(to) && !isRedirectNeeded(to)) {
    return to
  }

  return undefined
}

function startsWithHttp(to) {
  return to.indexOf('http') === 0
}

UrlLink.propTypes = {
  url: PropTypes.string,
  link: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.any,
}

UrlLink.defaultProps = {
  activeClassName: 'active',
}

export default UrlLink
