/**
 * GET window size - width, height
 * @return {{width: number, height: number}}
 */
export function getSize(): { width: number; height: number } {
  const width = window.innerWidth
  const height = window.innerHeight

  return { width, height }
}

/**
 * * @returns {Boolean}
 */
export function isTouch(): boolean {
  const mobiles = /(iP(hone|ad|od)|Android|webOS|BlackBerry|Windows Phone)/i
  const isTouchEnabled: boolean =
    (!!window.navigator.userAgent.match(mobiles) || 'ontouchstart' in document.documentElement) &&
    window.navigator.userAgent.indexOf('PhantomJS') === -1 // phantomJS has touch events

  // console.info(isTouchEnabled ? 'TOUCH DEVICE' : 'DESKTOP') // eslint-disable-line no-console

  return isTouchEnabled
}

/**
 * * @returns {Boolean}
 */
export const isTouchCached: () => boolean = (() => {
  let isTouchValue: boolean | undefined

  return () => {
    if (isTouchValue === undefined) {
      isTouchValue = isTouch()
    }

    return isTouchValue
  }
})()

/**
 * * @returns {Boolean}
 */
function isKiosk(): boolean {
  const kiosks = /(BetorKiosk)/i

  return !!window.navigator.userAgent.match(kiosks)
}

/**
 * * @returns {Boolean}
 */
export const isKioskCached: () => boolean = (() => {
  let isKioskValue: boolean | undefined

  return () => {
    if (isKioskValue === undefined) {
      isKioskValue = isKiosk()
    }

    return isKioskValue
  }
})()
