import React from 'react'

import { Hamburger } from './Hamburger'
import { MenuBox } from './styles'

export function Menu() {
  return (
    <MenuBox>
      <Hamburger />
    </MenuBox>
  )
}
