import React from 'react'

import PropTypes from 'prop-types'

function Ul({ children }) {
  return <ul>{children}</ul>
}

Ul.propTypes = {
  children: PropTypes.object,
}

export default Ul
