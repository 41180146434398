import React from 'react'

import PropTypes from 'prop-types'

import SimpleSharedBlock from './simpleSharedBlock'

export default function SharedBlock({ name }) {
  return <SimpleSharedBlock name={name} />
}

SharedBlock.propTypes = {
  name: PropTypes.string,
}
