/* eslint-disable react/style-prop-object */
import React from 'react'

export default function Apollo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="200mm"
      height="200mm"
      version="1.1"
      // style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 20000 20000"
      {...props}
    >
      <defs>
        <style type="text/css" />
      </defs>
      <g id="Warstwa_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M10182 5751c87,1336 206,2641 364,3250 406,-295 967,-767 1526,-1259l2124 -1938 -1938 2124c-492,559 -964,1120 -1259,1526 609,158 1914,277 3250,364l3985 182 -3985 182c-1336,87 -2641,206 -3250,364 295,406 767,967 1259,1526l1938 2124 -2124 -1938c-559,-492 -1120,-964 -1526,-1259 -158,609 -277,1914 -364,3250l-182 3985 -182 -3985c-87,-1336 -206,-2641 -364,-3250 -406,295 -967,767 -1526,1259l-2124 1938 1938 -2124c492,-559 964,-1120 1259,-1526 -609,-158 -1914,-277 -3250,-364l-3985 -182 3985 -182c1336,-87 2641,-206 3250,-364 -295,-406 -767,-967 -1259,-1526l-1938 -2124 2124 1938c559,492 1120,964 1526,1259 158,-609 277,-1914 364,-3250l182 -3985 182 3985z"
        />
      </g>
    </svg>
  )
}
